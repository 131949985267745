<template lang="pug">
div.row
  .col-12
  
    .d-flex.justify-content-end.mb-3
      matador-search#sos-search-box(placeholder='Search Drivers' autocomplete='off')

    keep-alive
      .table-responsive
        bs-table(
          ref="emergencyTable"
          :columns="table.columns",
          :options="table.options",
          :data="list",
          @on-post-body="vueFormatterPostBody"
        )
     
</template>

<script>

// TODO 
// @tiduronline faker is needed

import tableMixin from '@/mixins/table'
import { TableOption } from './table'
import Api from '@/utils/api'
import entity from '../../entity'

export default {
  mixins: [tableMixin],
  
  components: {},

  data() {
    return {
      loading: false,
      statusLoading: false,
      list: [],
      table: TableOption(this),
    }
  },
  
  methods: {

    fetchTableData(params) {
      if (this.loading) return false
      this.loading = true
      Api.driver.emergency_list()
        .then(response => {
          const data = response.data.result.data
          this.list = data.map(item => entity.EmergencyListEntity(item))
          params.success(true)
        })
        .catch(e => {
          let error = e.response ? e.response.data : e
          let message = error.message || 'Failed to collect log history'
          this.$toast.error(message)

          params.error(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    

    // TODO @tiduronline
    // find another elegant method for loading
    onChangeStatus(data, status) {

      if (this.statusLoading) 
        return false
      
      data.sos_id = data.id
      data.status = status
      return Api.driver.change_emergency_status(data)
        .then(response => {
          if (response.status !== 200)
            throw new Error('Failed to change status')
          
          let message = response.data.result.message || 'Successfully change status'
          this.$toast.success(message)
        })
        .catch(e => {
          let error = e.response ? e.response.data : e
          let message = error.message
          this.$toast.error(message)
        })
        .finally(() => {
          this.statusLoading = false
        })

    }
  },

  mounted() {
    
  }

}
</script>

<style lang="scss" scoped>
  .filter-label {
    color: #000;
    font-weight: bold;
  }
</style>
