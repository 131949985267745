<template lang="pug">
div
  card.register.mx-auto.justify-content-between(id="registration" title='Your Information')
    span Setting up your profile to finish your registration process.
      div
        .form-group.mt-3        
         label Full Name
         input.form-control(v-model="username" placeholder="Your Full Name")
        .form-group
         label Address
         input.form-control(v-model="address" placeholder="Your Address")
        .form-group
         label Country
         select.custom-select.form-control(type='text' placeholder='Choose Country' v-model="country"
                  :class="{ 'matador-placeholder':country === ''}")
            option(value="" selected disabled) Choose Country
            option(v-for="item in countryList", :value="item.country_name") {{item.country_name}}            
        .form-group
         label Phone No.
         input.form-control(v-model="phone" type="tel" placeholder="Your Phone Number")
        .form-group
         label Position
         .row
          .col
           select.custom-select.form-control(type='text' placeholder='Choose your position' v-model="position"
            :class="{ 'matador-placeholder': position === ''}")
            option(value="" selected disabled) Choose your position
            option(v-for="item in positionList" :value="item") {{item}}
          .col-auto.pl-0
            matador-button(iconOnly, size="sm", @click="showPositionModal=true")
             .h3.m-0
              plus(:size="64") 

        .form-group
         label Organization
         .row
            .col
             select.custom-select.form-control(type='text' placeholder='Choose organization' v-model="organization_id"
             :class="{ 'matador-placeholder':organization_id === ''}")
              option(value="" selected disabled) Choose organization
              option(v-for="item in organizationList", :value="item.organization_id") {{item.name}}
            .col-auto.pl-0
                matador-button(iconOnly, size="sm", @click="showOrganizationModal=true")
                 .h3.m-0
                  plus(:size="64")   
        .form-group
         matador-button(:full="true" size="lg" @click="onSetupProfile" :disabled="!(organization_id&&username&&country&&position&&phone)")
          h5 Save and Continue                             
  organization-modal(v-if="showOrganizationModal" @close="showOrganizationModal = false" @success="onAddOrganization")
  position-modal(v-if="showPositionModal" @close="showPositionModal = false" @success="AddingPosition")                                                        
                
</template>
<script>
import * as yup from 'yup';
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import OrganizationModal from "../Users/OrganizationModal.vue"
import PositionModal from "../Users/PositionModal.vue"
import { 
    getOrganizationList,
    getJobList,
    } from '@/utils/api';
import { postEmailVerification,postProfile } from '../../../utils/api';
export default {
  data(){
    return {
        country:this.$store.state.auth.country,
        countryList:[
          {
            country_name:"Indonesia"
          },
          {
            country_name:"Myanmar"
          },
        ],  
        user_id:'',
        verification_code:'',
        username:'',
        address:'',
        phone:'',
        position: '',
        organization_id: null,
        positionList: [],
        organizationList: [],
        showOrganizationModal: false,
        showPositionModal: false,
    }
  },
    components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    OrganizationModal,
    PositionModal,
  },
  methods:
  {
    toggleBackToEtalaseModal(){
      this.$store.commit("setShowBackToEtalaseModal", !this.$store.state.showBackToEtalaseModal);
    },    
    onAddOrganization(newVal) {
      this.organizationList = [ { name: newVal }, ...this.organizationList  ];
      this.organization_id = newVal;
    },
    AddingPosition(newVal) {
      this.positionList = [ newVal, ...this.positionList  ];
      this.position = newVal;
    },
    async onAddPosition(newVal) {
      try {
        let response=await getJobList();
        this.positionList =await response.data.result;
        for (let index = 0; index < this.positionList.length; index++) {
          if(newVal === this.positionList[index].position){
            this.position=this.positionList[index].position_id
          }
        }
      } catch (error) {
        console.log("error :",error)
      }
    },
    async onSetupProfile(){
      try {
        if(this.$route.query.verification_code&&this.$route.query.user_id){
          postEmailVerification({user_id:this.$route.query.user_id,email_verification_code:this.$route.query.verification_code}).then(({
            data: { code, result: userData, messgae }})=> {
            if(code == 200) { }
          })
        }
        let user_id = this.$route.query.user_id?this.$route.query.user_id:this.$cookies.get('user_id')
        let username=this.username,address=this.address,phone=this.phone,position =this.position,organization_id=this.organization_id,country=this.country
        postProfile({name:username,phone:phone,address:address,country:country,user_id:user_id,position:position,organization_id:organization_id})
        .then(({ data: { code, result: userData, messgae,status } }) => {
          if(code==200){
          this.$store.commit("setShowBackToEtalaseModal", true);
          localStorage.setItem("user_id", userData.user_id);
          this.$store.commit('setAuth', userData);
          if(userData.access){
            this.$store.commit('setSidebar', userData.access);
          }
          this.$cookies.set("keepLogin", this.keepLogin ? "yes" : "no", "1y");
          this.$cookies.set("token", userData.token, this.keepLogin ? "1y" : "1h");
          this.$cookies.set("user_id", userData.user_id, this.keepLogin ? "1y" : "1h");
          this.$cookies.set("role", 'user', this.keepLogin ? "1y" : "1h");
          this.$toast.open('You Have Successfully Setup Profile');
          }
        }
      );}catch (error) {
        this.$toast.error('Failed to Setup Profile');
        console.log("error :",error)
      }
      this.$emit('success', this.name);
      this.$emit('close');
    },
    verifiedUser(){
            postEmailVerification({user_id:this.$route.query.user_id,email_verification_code:this.$route.query.verification_code}).then(({
                data: { code, result: userData, messgae }})=> {
                if(code == 200) { }
                        })
    },
    
    fetchPositionList() {
      getJobList().then(({ data: { code, result, message } }) => {
        this.positionList = result;
      });
    },
    fetchOrganizationList() {
      getOrganizationList().then(({ data: { code, result, message } }) => {
        this.organizationList = result;
        // console.log(this.currentUser);
        
      });
    },
  },
  mounted(){
    this.fetchPositionList();
    this.fetchOrganizationList();
    

  }
}
</script>

<style lang="scss" scope>
    .register{
        width:400px !important
    }
</style>