<template lang="pug">
div#Superboard
  .container
    .row.justify-content-between
      .col-8
        .matador-h2.matador-text-02 Superboard
      //- .col-4
      //-   .row
      //-     .col-lg-4.col-md-12.mt-2(style="padding-right:0px !important")
      //-       .matador-text-02 Show data :
      //-     .col-lg-8.col-md-12
      //-       select.custom-select.form-control(type='text', v-model="duration"
      //-         :class="{ 'matador-placeholder': duration === ''}" style="opacity:60%")
      //-           option(value="" selected disabled) Choose time display
      //-           option(v-for="item in optionsDuration" :value="item.value") {{item.text}}
    .row.mt-4
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Vehicle 
              span.matador-text-danger Off
            div(style="margin-top:-10px")
              img.status-icon(src="@/assets/img/truck-off.png")
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.vehicle_off}}
          .row.mt-4.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Vehicle 
              span.matador-text-yellow Idle
            div(style="margin-top:-10px")
              img.status-icon(src="@/assets/img/truck-idle.png")
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.vehicle_idle}}
          .row.mt-4.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Vehicle 
              span.matador-text-green On
            div(style="margin-top:-10px")
              img.status-icon(src="@/assets/img/truck-on.png")
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.vehicle_on}}
          .row.mt-4.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Total Vehicle 
            div(style="margin-top:-10px")
              img.status-icon(src="@/assets/img/truck_available.png")
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.vehicle_available}}
          .row.mt-4.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
    .row.mt-3
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Total Visitor 
            div(style="margin-top:-17px")
              span.mdi.mdi-36px.mdi-google-circles-group
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.total_visitor}}
          .row.mt-4.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Total User 
            div(style="margin-top:-17px")
              span.mdi.mdi-36px.mdi-account-box-outline
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.total_user}}
          .row.mt-4.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Total Driver
            div(style="margin-top:-17px")
              span.matador-text-light-blue.mdi.mdi-36px.mdi-lifebuoy
          .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.total_driver}}
          .row.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
        //- card(style="padding:0px !important")
        //-   .d-flex.justify-content-between
        //-     .matador-h4.matador-text-02 Total Organization
        //-     div(style="margin-top:-17px")
        //-       span.mdi.mdi-36px.mdi-account-multiple
        //-   .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.total_organization}}
        //-   .row.mt-4.pl-3.pr-3.justify-content-between
        //-     div
        //-     .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
      .col-lg-3.col-md-6.col-sm-6.col-xs-12
        card(style="padding:0px !important")
          .d-flex.justify-content-between
            .matador-h4.matador-text-02 Total Device
            div(style="margin-top:-17px")
              span.matador-text-subtitle-yellow.mdi.mdi-36px.mdi-cellphone-wireless
          .matador-h3-bold.matador-text-subtitle-yellow {{dataSuperboard.total_device}}
          .row.pl-3.pr-3.justify-content-between
            //- div
            //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
        //- card(style="padding:0px !important")
        //-   .d-flex.justify-content-between
        //-     .matador-h4.matador-text-02 Total Role
        //-     div(style="margin-top:-17px")
        //-       span.mdi.mdi-36px.mdi-account-star
        //-   .matador-h3-bold.matador-text-02.mt-2 {{dataSuperboard.total_role}}
        //-   .row.mt-4.pl-3.pr-3.justify-content-between
        //-     div
        //-     .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
    .row.mt-2
      .col-lg-8.col-md-12.col-sm-12.col-xs-12
        card(style="padding:0px !important")
          .d-flex
            .matador-h4.matador-text-02 Revenue
          line-chart(:chart-data="lineChart" ref="lineChartRevenue"  :stacked="true" :height="200" :width="null" :gradients="this.gradient")
      .col-lg-4.col-md-12.col-sm-12.col-xs-12
        .row
          .col-lg-12.col-md-6
            card(style="padding:0px !important")
              .d-flex.justify-content-between
                .matador-h4.matador-text-02 Order Done
                div(style="margin-top:-17px")
                  span(style="color:green !important").mdi.mdi-36px.mdi-alarm-check
              .matador-h3-bold.matador-text-green {{dataSuperboard.order_done}}
              .row.pl-3.pr-3.justify-content-between
                //- div
                //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
        .row
          .col-lg-12.col-md-6
            card(style="padding:0px !important")
              .d-flex.justify-content-between
                .matador-h4.matador-text-02 Order Cancel
                div(style="margin-top:-17px")
                  span.matador-text-danger.mdi.mdi-36px.mdi-cancel
              .matador-h3-bold.matador-text-danger {{dataSuperboard.order_cancel}}
              .row.pl-3.pr-3.justify-content-between
                //- div
                //- .matador-caption-2.matador-text-03 This {{duration=='weekly'?'week':'month'}}
    .row.mt-2
      .col-lg-4.col-md-12
        card()
          .d-flex.justify-content-between
            .matador-h4.matador-text-02.mr-2 Role  
            div(style="margin-top:-20px")
              span.mdi.mdi-36px.mdi-account-star
            
          .table-responsive.pt-4
            bs-table(
              ref="table2"
              :columns="fieldsRole",
              :options="tableCountOptions",
              :data="positionRole",
              @on-post-body="vueFormatterPostBody"
              )
      .col-lg-8.col-md-12
        card()
          .d-flex.justify-content-between
            .matador-h4.matador-text-02.mr-2 Organization 
            div(style="margin-top:-20px")
              span.mdi.mdi-36px.mdi-account-multiple
          matador-search#search-box( v-model="search" placeholder='Search Name' autocomplete='off' style='width:100%')
          .table-responsive.pt-4
            bs-table(
              ref="table3"
              :columns="fieldsOrganization",
              :options="tableCountOptionsOrganization",
              :data="organization",
              @on-post-body="vueFormatterPostBody"
              @on-search=""
              ) 
</template>

<script>
import {getSuperboard} from "@/utils/api"
import {mapGetters} from 'vuex'
import tableMixin from "@/mixins/table";
import LineChart from "@/components/Chartjs/LineChart";
import dayjs from 'dayjs';
import { 
    getOrganizationList,
    getJobList,
    postSubdomain,
    checkSubdomain,
    getPosition
    } from '@/utils/api';
export default {
  components:{LineChart},
   mixins: [tableMixin],
  data() {
    return {
      search:'',
      duration:"weekly",
      dataSuperboard:[],
      gradient:['rgba(238, 201, 69, 1)', 'rgba(241, 246, 255, 1)'],
      optionsDuration:[
        {
          text:"Weekly",
          value:"weekly",
        },
        {
          text:"Monthly",
          value:"monthly",
        },
      ],
      fieldsRole: [
          { widthUnit: 'em',
            width: '6',
            title: 'Roles',
            field :'position',
            sortable: true
          },
          // { widthUnit: '%',
          //   width: '70',
          //   title: 'Permission',
          //   field:'permission',
          //   sortable: true  
          // },
          { widthUnit: 'em',
            width: '6',
            title: 'Member',
            label: 'Member',
            field:'total',
            sortable: true,
          }
        ],
      fieldsOrganization: [
          { widthUnit: 'em',
            width: '7',
            title: 'Name',
            field :'name',
            sortable: true,
            searchable:true
          },
          // { widthUnit: '%',
          //   width: '70',
          //   title: 'Permission',
          //   field:'permission',
          //   sortable: true  
          // },
          { widthUnit: 'em',
            width: '7',
            title: 'Organization ID',
            field:'organization_id',
            sortable: true,
            searchable:true
          },
          { widthUnit: 'em',
            width: '7',
            title: 'Total Member',
            field:'totalMember',
            sortable: true,
            searchable:true
          }
        ],
      positionRole: [],
      organization:[],
    }
  },
  watch:{
    duration:{
      handler:function(newVal){
        this.getDataSuperBoard();
        this.$refs.lineChartRevenue.create();
        this.gradient=['rgba(238, 201, 69, 1)', 'rgba(241, 246, 255, 1)'];
      }
    }
  },
  computed:{
    tableCountOptions() {
          return {
            data: this.fetchPosition(),
            pagination: true,
            pageSize: this.perPage,
            sortable: true,
            paginationHAlign: "center",
            paginationDetailHAlign: "right",
            paginationParts: [ "pageInfo", "pageList"],
            classes: ["table", "table-hover"],
        }
      },  
    tableCountOptionsOrganization() {
          return {
            data: this.getDataSuperBoard(),
            pagination: true,
            pageSize: this.perPage,
            sortable: true,
            search:true,
            showSearchButton: true,
            searchSelector: "#search-box",
            paginationHAlign: "center",
            paginationDetailHAlign: "right",
            paginationParts: [ "pageInfo", "pageList"],
            classes: ["table", "table-hover"],
        }
      },  
    ...mapGetters({
      auth:'gettersAuth'
    }),
    lineChart(){
      if(this.dataSuperboard.length !=0){
        let label={};
        let total={};
        label=this.dataSuperboard.revenue.length !=0 ? this.dataSuperboard.revenue.map((item)=>{
          return this.formatDate(item.label);
        }) :null;
        total=this.dataSuperboard.revenue.length !=0 ? this.dataSuperboard.revenue.map((item)=>{
          return item.total;
        }):null;
        let dataCollection={
          labels: label,
          datasets:[
            {
              label:"revenue",
              data : total,
              backgroundColor:"rgba(238, 201, 69, 1)"
            }
          ],
        }
        console.log(dataCollection,"ini data collection")
        return dataCollection;
      }else{
        console.log("data Line Card Kosong")
      }
        
    },
  },
  methods: {
    fetchPosition(){
      getPosition().then(({data:{code,result,message}})=>{
        if(code ==200){
        result.forEach((access,index) => {
          result[index].position =  result[index].position.charAt(0).toUpperCase() + result[index].position.slice(1)
          result[index].permission = result[index].access.join()          
        });
        this.positionRole = result
        console.log(this.positionRole)
        // console.log(result)
        }
      })
    },
    async getDataSuperBoard(){
      let response= await getSuperboard({user_id:this.auth.user_id,duration:this.duration})
      this.dataSuperboard=response.data.result;
      this.organization=response.data.result.oragnization;
      console.log(this.dataSuperboard)
    },
    formatDate(params){
      var date=dayjs(params).format('DD/MMM')
      return date
    },
    
  },
  mounted(){
    this.getDataSuperBoard();
    
  }
}
</script>
<style>

  
</style>