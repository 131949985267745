<template lang="pug">
div#etalase
  card(title='Catalogue')
    .d-flex.mb-4
      div.w-100.pr-1
        matador-search#search-button(@input="onSearch" placeholder='Search Vehicle' autocomplete='off')
      .p-0
        //- matador-button.py-2(size="lg") Show : All Statuses
      
    loader(:loading="loading")
      p.text-center(v-if="etalase.length == 0") No data available
      .row
        .col-12.col-md-4(v-for="(etalase, index) in  shownEtalase" :key="etalase.etalase_id")
          .card.card-monitoring.shadow-sm.pb-2
            .card-body(style="padding:0px 0px !important")
              img.image-etalase.mb-2.mb-lg-0(width="100%" style="border-top-right-radius: 12px;border-top-left-radius: 12px;" v-if="etalase.image" :src="etalase.image | assets")
              img.mb-2.mb-lg-0(width="100%" style="border-top-right-radius: 12px;border-top-left-radius: 12px;" v-else src="@/assets/img/car-placeholder.png")
              div.pl-2.pr-2
                h4.my-3(style="font-weight:bold") {{etalase.series}}  -  {{etalase.name}}
                .row.px-3
                  .col-lg-6.col-md-10.col-sm-3.col-xs-1(style="padding:0px 0px !important;border:1px solid #AAA5B0")
                    .matador-h5(style="text-align:center;color:blue; font-weight:normal") {{etalase.total_available}} Unit Available
                  .col-lg-2.col-md-2
                .row.py-2
                  .col-lg-6.col-md-12.col-sm-12
                    .matador-text-04.mb-1 Per Month
                  .col-lg-6.col-md-12.col-sm-12(style="text-align: right")  
                    strong Rp. {{mataUang(etalase.price_per_month)}}
                .row.py-2
                  .col-lg-6.col-md-12.col-sm-12
                    .matador-text-04.mb-1 Per Day
                  .col-lg-6.col-md-12.col-sm-12(style="text-align: right")  
                    strong Rp. {{mataUang(etalase.price_per_day)}}
                .row.py-2
                  .col-lg-6.col-md-12.col-sm-12
                    .matador-text-04.mb-1 Per Hour
                  .col-lg-6.col-md-12.col-sm-12(style="text-align: right")  
                    strong Rp. {{mataUang(etalase.price_per_hour)}}
                .row.py-2
                  .col-lg-6.col-md-12.col-sm-12
                    .matador-text-04.mb-1 Per Km
                  .col-lg-6.col-md-12.col-sm-12(style="text-align: right")  
                    strong Rp. {{mataUang(etalase.price_per_km)}}
              .pt-3.pl-2.pr-2.pb-2
                matador-button.pt-2.pb-2(v-if="$store.state.auth.role!='guest'&& $store.state.auth.organization_id" style="background-color:#6DDF92 !important;border:0px solid #6DDF92;border-radius:6px;" :outline="false"  :disabled="!etalase.is_can_order" :block="true" @click="rentModal(etalase)" ) 
                  .d-flex.align-items-center.justify-content-center
                    //- div
                      img.mr-1(width="18" src="@/assets/img/reset-icon.png")
                    span(style="line-height: 2rem") Rent this vehicle
                matador-button.pt-2.pb-2(v-if="$store.state.auth.role=='guest'" style="background-color:#6DDF92 !important;border:0px solid #6DDF92;border-radius:6px;" :outline="false"  :block="true" @click="showRegisterModal" ) 
                  .d-flex.align-items-center.justify-content-center
                    //- div
                      img.mr-1(width="18" src="@/assets/img/reset-icon.png")
                    span(style="line-height: 2rem") Rent this vehicle
                matador-button.pt-2.pb-2(v-if="$store.state.auth.role!='guest'&& $store.state.auth.organization_id==null" style="background-color:#6DDF92 !important;border:0px solid #6DDF92;border-radius:6px;" :outline="false"  :block="true" @click="goToRegistration" ) 
                  .d-flex.align-items-center.justify-content-center
                    //- div
                      img.mr-1(width="18" src="@/assets/img/reset-icon.png")
                    span(style="line-height: 2rem") Rent this vehicle                    
      .pt-4.mt-3
        .row.align-item-center
          .col-md-10.col-sm-12.col-xs-12
            b-pagination(
              v-model="page"
              :total-rows="pageLength"
              :per-page="perPage"
              aria-controls="my-table"
            )
          .col-md-2.col-sm-12.col-xs-12
            .d-flex.align-items-center
              span.mr-2 Per&nbsp;Page: 
              b-dropdown(:text='perPage.toString()' variant='light')
                b-dropdown-item(@click="perPage = 12") 12
                b-dropdown-item(@click="perPage = 48") 48
                b-dropdown-item(@click="perPage = 96") 96
                b-dropdown-item(@click="perPage = 184") 184
  rent-vehicle-modal(
    v-if="showRentVehicleModal"
    @close="onClose" 
    :dataVehicle="dataVehicle"
    @success="onEtalaseChanged"
    )
</template>
<script>
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import produce from 'immer';
import PlusIcon from "vue-material-design-icons/Plus.vue";
import VehicleModal from "@/components/Matador/VehicleModal";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import RentVehicleModal from "@/components/Matador/RentVehicleModal";
import {mapGetters} from 'vuex'
import {
  getEtalase,
  
} from "@/utils/api";
import { postSetDeviceStatus } from '../../../utils/api';
export default {
  components: {
    ChevronDown,
    ChevronUp,
    VehicleModal,
    ConfirmationModal,
    RentVehicleModal,
    PlusIcon
  },
  watch: {
    // search: {
    //   handler: function(newVal){
    //     typeof timeout != 'undefined' && clearTimeout(timeout);
    //     window.timeout = setTimeout(() => {
    //       this.getMonitoring();
    //     }, 500);
    //   }
    // },
    perPage: {
      handler: function(newVal, oldVal){
        if(newVal != oldVal){
          this.page = 1;
        }
      }
    },
    status: {
      handler: function(newVal, oldVal){
        /* this.getMonitoring(); */
      }
    }
  },
  computed: {
   /*  shownEtalase(){
      return this.etalase;
    }, */
    ...mapGetters({
      auth:'gettersAuth',
    
    }),
    shownEtalase(){
      const etal = JSON.parse(JSON.stringify(this.etalase)).filter((item) => {
        return (item.series || '').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
          || (item.name|| '').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      });
      this.pageLength = etal.length
      const result = etal.splice(this.page * this.perPage - this.perPage, this.perPage);
      // console.log(this.perPage)
      return result;
      // const result = JSON.parse(JSON.stringify(this.etalase)).splice(this.page * this.perPage - this.perPage, this.perPage);
      // const etal = result.filter((item) => {
      //   return (item.series || '').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      //     || (item.name|| '').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      // });
      // return etal;
    }
  },
  data() {
    return {
      pageLength:0,
      page: 1,
      showMapModal: false,
      showVehicleModal: false,
      showStatusModal: false,
      loadingMapModal: false,
      willResetVehicle: null,
      loading: false,
      vehicle: null,
      perPage: 12,
      search: '',
      devices: [],
      status: 'all',
      //
      etalase:[],
      showRentVehicleModal:false,
      dataVehicle:null,
    }  
  },
  methods: {
    goToRegistration(){
        this.$router.push({path: '/registration'});   
    },
    onClose(){
      
      this.showRentVehicleModal = false
    },
    showRegisterModal(){
      this.$store.commit("setShowRegisterModal", !this.$store.state.showRegisterModal);

    },
    onEtalaseChanged(params){
      this.getEtalase();
      // console.log(params)
      this.showRentVehicleModal= false;
      if(params == "success"){
        this.$toast.open('Successfully rent vehicle');
      }else if(params == "checkout"){
        this.$toast.open('Successfully checkout vehicle');

      }
      else{
        this.$toast.error('Failed rent vehicle');
      }
    },
    rentModal(params){
      // console.log("rentModal",params)
      this.dataVehicle=params;
      this.showRentVehicleModal=true
    },
    mataUang(req){
      var	number_string = req.toString()
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
    onSearch(value){
      this.search = value;
    },
    async getEtalase(){
      this.loading = this.etalase.length == 0;
        try {
          let ob={
            is_show:1
          };
        let response =await getEtalase(ob);
        // console.log("ini response",response.data.result);
        this.etalase=response.data.result;
        this.pageLength=response.data.result.length
        // console.log(this.etalase,"ini response")
        this.loading=false;
      } catch (error) {
        
      }
    
    },
    toggleRow(index){
      this.loading = true;
      let devices = JSON.parse(JSON.stringify(this.devices));
      devices[this.page * this.perPage - this.perPage + index].expanded = !devices[this.page * this.perPage - this.perPage + index].expanded;
      this.devices = devices;
      this.loading = false;
    },
  },
  created() {
    this.$store.dispatch('getUserCart',this.auth.user_id)
  },
  mounted(){
    this.getEtalase()
    // this.onFollowVehicle('unit-2227');
    // this.onFollowVehicle('unit-2192');
  }
};
</script>
<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
#monitoring {
  .material-design-icon {
    > .material-design-icon__svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .card.card-monitoring {
    border: 1px solid rgba(0,0,0,0.015);
  }
  .device-list {
    a.text-dark {
      &:hover {
        background-color: #fbfbfb;
      }
    }
    td {
      .btn-sm {
        text-transform: none;
        border-radius: 5px;
        border-width: 2px;
        &.btn-outline-danger {
          &:hover, &:active, &:visited, &:focus {
            background-color: #fff !important;
            color: $danger-color;
          }
        }
      }
    }
  }
}
@media screen and (max-width:1024px)  {
  .image-etalase{
    height: 155px !important;
  }
}
@media screen and (max-width:936px)  {
  .image-etalase{
    height: 94px !important;
  }
}
@media screen and (max-width:871px)  {
  .image-etalase{
    height: 80px !important;
  }
}
@media screen and (max-width:774px)  {
  .image-etalase{
    height: 64px !important;
  }
}
@media screen and (max-width:768px)  {
  .image-etalase{
    height: 115px !important;
  }
}




</style>
