// import roleDriver from '@/roles/roleDriver'

import Page from './Page.vue'
import DriverList from './pages/Driver/DriverList.vue'
import HistoryList from './pages/History/HistoryList'
import EmergencyList from './pages/Emergency/EmergencyList.vue'

import { TabOption } from './entity'


const Router = () => {

  return {
    path: '/drivers',
    name: 'drivers',
    // beforeEnter: roleDriver,
    component: Page,
    children: [
      { path: '', redirect: TabOption.DRI.path },
      { path: TabOption.DRI.path, component: DriverList},
      { path: TabOption.REP.path, component: HistoryList},
      { path: TabOption.SOS.path, component: EmergencyList}
    ]
  }

}

export default Router