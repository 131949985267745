<template lang="pug">
  
.row
  .col-12

    .d-flex.justify-content-end.mb-4
      matador-search#geo-search-box(placeholder='Search Generator' autocomplete='off')
      //- matador-button.py-2.px-4.ml-2(icon="fa fa-plus" @click="addGeofenceModal") Add Geofence

    keep-alive
      .table-responsive
        bs-table#geofence-table(
          ref="geofenceTable"
          :columns="table.fields",
          :options="table.options",
          :data="generatorList"
          @on-post-body="vueFormatterPostBody"
        )

  geofence-form-modal(
    v-if="openGeofenceModal" 
    :geofenceTable="geofenceTable" 
    @close="toggleGeofenceModal" 
    :geofence="geofence"
  )

  confirmation-modal(
    v-if="deletedGeofence"
    @close="deletedGeofence = null" 
    @confirm="onDeleteGeofence"
    title="Delete Geofence" 
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do you want to remove this geofance?

  geofence-detail-modal(
    :loading="detailLoading" 
    :generator="detailGenerator" 
    :isDetailOpen="isDetailOpen"
    :loadingGeofence="loadingGeofence"

    @onDeleteConfirm="onDeleteConfirmBtn"
    @onEditGeofence="onEditGeofence"
    
    @close="isDetailOpen = false" 
  )
  
  map-location-modal(
    @close="showMapLocationModal = false"

    :isDetailOpen="showMapLocationModal"
    :longitude="position.lng"
    :latitude="position.lat"
  )

</template>


<script>
import GeofenceFormModal from "./GeofenceModal.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal.vue";
import GeofenceDetailModal from './GeofenceDetail.vue'
import MapLocationModal from '../Component/MapLocationModal.vue'

import { Generator } from '../../libs/repository'
import { TabName } from '../../entity'
import TableOption from './table'

import Api from '@/utils/api'

import tableMixin from '@/mixins/table'
// import entity from '../../entity'
import {GeoEntity} from '../../entity'


const tableOpt = TableOption(TabName.GEO)
const Repository = Generator.init({isDummy: false})

export default {
  name: 'Geofence',
  mixins: [tableMixin],

  components: {
    GeofenceFormModal,
    ConfirmationModal,
    GeofenceDetailModal,
    MapLocationModal
  },
  
  data() {
    return {
      position: {
        lat: 0,
        lng: 0,
      },
      showMapLocationModal: false,
      showGeofenceModal: false,
      deletedGeofence: null,
      loading: false,
      detailGenerator: null,
      isDetailOpen: false,
      geofence: null,
      detailLoading: true,
      openGeofenceModal: false,
      loadingGeofence: false,
      TabName,
      generatorList: [],
      table: {
        options: {...tableOpt.options, 
          ajax: this.fetchData,
          searchSelector: '#geo-search-box'
        },
        fields: tableOpt.fields(this)
      }
    }
  },

  computed: {
    geofenceTable() {
      return this.$refs.geofenceTable
    }
  },

  methods: {

    isGuest() {
      return this.$store.state.auth.role === 'guest'
    },

    fetchData(params) {

      let payload = {
        limit: params.data.limit || 10,
        page: params.data.offset/params.data.limit || 0,
        q: params.data.search,
        sort: `${params.data.sort || 'created_at'}.${params.data.order || 'desc'}`
      }

      const responseHandler = response => {
        let data = response.data
        let rows = data.result.data || []
        let pagination = data.result.pagination
        
        this.generatorList = rows.map(item => GeoEntity(item))
        if (!rows || !rows.length) return params.success(false)

        let resource = {
          rows: this.deviceList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0
        }
        setTimeout(() => params.success(resource), 500)
      }

      Api.generator.list(payload)
      .then(responseHandler)
      .catch(e => {
        if (params.error) params.error(true)
        this.$toast.error("Failed to collect generator data")
      })
      
    },

    addGeofenceModal() {
      this.geofence = null
      this.openGeofenceModal = !this.openGeofenceModal
    },

    toggleGeofenceModal() {
      this.openGeofenceModal = !this.openGeofenceModal
    },

    onDeleteConfirmBtn() {
      this.isDetailOpen = false
      this.deletedGeofence = this.detailGenerator
      this.detailGenerator = null
    },

    onShowLocation(row) {
      this.position.lat = row.latitude
      this.position.lng = row.longitude
      
      this.showMapLocationModal = !this.showMapLocationModal
    },

    onEditGeofence() {
      if (this.loadingGeofence) return false

      this.loadingGeofence = true
      Repository.getDetailGenset(this.detailGenerator.id)
        .then(result => {
          
          this.isDetailOpen = false
          this.detailGenerator = null
          
          this.geofence = result
          this.openGeofenceModal = true

        })
        .catch(e => this.$toast.error("Failed to collect detail data"))
        .finally(e =>  {
          this.loadingGeofence = false
        })
    },

    onDeleteConfirm(geofence) {
      this.deletedGeofence = geofence
    },

    onDetailGeofence(geofence) {
      this.isDetailOpen = true
      this.detailLoading = true

      Repository.detailGeneratorGeo(geofence.id)
      .then(result => {
        this.detailGenerator = result
      })
      .catch(e => this.$toast.error("Failed to collect detail data"))
      .finally(e => {
        this.detailLoading = false
      })

    },

    onDeleteGeofence() {

      if (this.loading) return
      this.loading = true

      Api.generator.remove_geofence({genset_id: this.deletedGeofence.id})
        .then(response => this.$refs.geofenceTable.refresh())
        .catch(e => this.$toast.error("Failed to delete generator"))
        .finally(e => {
          this.deletedGeofence = null 
          this.loading = false
        })
        
    }

  },

  mounted() {
    const self = this;
    window.$('#geo-search-box input').on('search', function() {
      if(!window.$(this).val()) {
        self.$refs.geofenceTable.resetSearch();
      }
    });
  }

}
</script>
