// import roleGenerator from '@/roles/roleGenerator'

import Page from './Page.vue'

import { TabOption } from './entity'
import MonitoringPage from './pages/Monitor/Monitor.vue'
import ReportPage from './pages/Report/Report.vue'
import GeofencePage from './pages/Geofence/Geofence.vue'


const Router = () => {

  return {
    path: '/generators',
    name: 'generators',
    // beforeEnter: roleGenerator,
    component: Page,
    children: [
      { path: '', redirect: TabOption.MON.path },
      { path: TabOption.MON.path, component: MonitoringPage},
      { path: TabOption.REP.path, component: ReportPage},
      { path: TabOption.GEO.path, component: GeofencePage}
    ]
  }

}

export default Router