<template lang="pug">
div#DashboardRental
  .container
    .row.justify-content-between
      .matador-h2.ml-2.matador-text-02 Dashboard Rental
      matador-button.mr-3.btn(@click="rentalSettingPage()" style="border:1px solid #7998B9 !important ;padding-right: 25px !important;padding-left: 25px !important;background-color:#7998B9 !important") 
          .mdi.mdi-24px.mdi-rotate-180.mdi-cogs.mr-2 
          .matador-h3 Setting
    .row.mt-3
      .col-2.mt-2(style="padding-right:0px !important")
        .matador-text-02 Show data :
      .col-3
        select.custom-select.form-control(type='text', v-model="duration"
          :class="{ 'matador-placeholder': duration === ''}" style="opacity:60%;color:black !important ")
            option(value="" selected disabled) Choose time display
            option(v-for="item in optionsDuration" :value="item.value") {{item.text}}
      .col-3(v-if="this.auth.role == 'superadmin' ")
        select.custom-select.form-control(type='text', v-model="id_organization"
          :class="{ 'matador-placeholder': id_organization === ''}" style="opacity:60%;color:black !important")
            option(value="" selected disabled) Choose Organisasi
            option(v-for="item in  optionSelectOrganization" :value="item.value") {{item.text}}
    .d-flex.justify-content-center.mt-4(v-if="alertChoose && this.auth.role == 'superadmin' ")
        .matador-h4.matador-text-03 Please Choose Organization And Time Display
    .d-flex.justify-content-center.mt-4(v-else-if="alertChoose ")
        .matador-h4.matador-text-03 Please Choose  Time Display
    .row.mt-4(v-else)
      .col-lg-8.col-md-12.col-sm-12.col-xs-12
        .row
          .col-4
            card(style="padding:0px !important")
                .matador-h5.matador-text-02 Online Visitor
                .matador-h3-bold.matador-text-02.mt-2 {{dataDashboard.total_visitor}}
                .row.mt-4.pl-3.pr-3.justify-content-between
                  .d-flex.matador-text-green(v-if="statusVisitorPersen.up")
                    .matador-caption-2 {{dataDashboard.percentase_total_visitor}}
                    .ml-1.mdi.mdi-24px.mdi-trending-up( style="margin-top:-5px !important")
                  .d-flex.matador-text-danger(v-else-if="statusVisitorPersen.down")
                    .matador-caption-2 {{dataDashboard.percentase_total_visitor}}
                    .ml-1.mdi.mdi-24px.mdi-trending-down( style="margin-top:-5px !important")
                  .d-flex.matador-text-light-blue(v-else-if="statusVisitorPersen.linear")
                    .matador-caption-2 {{dataDashboard.percentase_total_visitor}}
                    .ml-1.mdi.mdi-24px.mdi-trending-neutral( style="margin-top:-5px !important")
                  .matador-caption-2.matador-text-03 This {{duration}}
          .col-4
            card(style="padding:0px !important")
                .matador-h5.matador-text-02 Finished Order
                .matador-h3-bold.matador-text-02.mt-2 {{dataDashboard.order_done}}
                .row.mt-4.pl-3.pr-3.justify-content-between
                  .d-flex.matador-text-green(v-if="statusOrderDone.up")
                    .matador-caption-2 {{dataDashboard.percentase_order_done}}
                    .ml-1.mdi.mdi-24px.mdi-trending-up(style="margin-top:-5px !important")
                  .d-flex.matador-text-danger(v-else-if="statusOrderDone.down")
                    .matador-caption-2 {{dataDashboard.percentase_order_done}}
                    .ml-1.mdi.mdi-24px.mdi-trending-down(style="margin-top:-5px !important")
                  .d-flex.matador-text-light-blue(v-else-if="statusOrderDone.linear")
                    .matador-caption-2 {{dataDashboard.percentase_order_done}}
                    .ml-1.mdi.mdi-24px.mdi-trending-neutral(style="margin-top:-5px !important")
                  .matador-caption-2.matador-text-03 This {{duration}}
          .col-4
            card(style="padding:0px !important")
                .matador-h5.matador-text-02 Canceled Order
                .matador-h3-bold.matador-text-02.mt-2 {{dataDashboard.order_cancel}}
                .row.mt-4.pl-3.pr-3.justify-content-between
                  .d-flex.matador-text-green(v-if="statusOrderCancel.up")
                    .matador-caption-2 {{dataDashboard.percentase_order_cancel}}
                    .ml-1.mdi.mdi-24px.mdi-trending-up(style="margin-top:-5px !important")
                  .d-flex.matador-text-danger(v-else-if="statusOrderCancel.down")
                    .matador-caption-2 {{dataDashboard.percentase_order_cancel}}
                    .ml-1.mdi.mdi-24px.mdi-trending-down(style="margin-top:-5px !important")
                  .d-flex.matador-text-light-blue(v-else-if="statusOrderCancel.linear")
                    .matador-caption-2 {{dataDashboard.percentase_order_cancel}}
                    .ml-1.mdi.mdi-24px.mdi-trending-neutral(style="margin-top:-5px !important")
                  .matador-caption-2.matador-text-03 This {{duration}}
        .row 
          .col-12
            card(style="padding:0px !important")
              .d-flex
                .matador-h5.matador-text-02 Revenue
                .d-flex.ml-3.matador-text-green(v-if="statusVisitorPersen.up")
                    .matador-caption-2 {{dataDashboard.percentase_total_visitor}}
                    .ml-1.mdi.mdi-24px.mdi-trending-up( style="margin-top:-5px !important")
                .d-flex.ml-3.matador-text-danger(v-else-if="statusVisitorPersen.down")
                    .matador-caption-2 {{dataDashboard.percentase_total_visitor}}
                    .ml-1.mdi.mdi-24px.mdi-trending-down( style="margin-top:-5px !important")
                .d-flex.ml-3.matador-text-light-blue(v-else-if="statusVisitorPersen.linear")
                    .matador-caption-2 {{dataDashboard.percentase_total_visitor}}
                    .ml-1.mdi.mdi-24px.mdi-trending-neutral( style="margin-top:-5px !important")
              line-chart(:chart-data="lineChart"  ref="revenue_chart" :stacked="true" :height="200" :width="null"  )
        .row 
          .col-12
            card(style="padding:0px !important")
              .d-flex.justify-content-between
                .matador-h5.matador-text-02 Newest Order
                a.matador-h5.matador-text-05(@click="managePage('/rentList')") All Order
                    chevron-right.ml-2

              //- .table-responsive
              //-   .d-flex.mt-4.pb-1(style="border-bottom:1px solid #F0F1F3")
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-bg-4 #Order
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-bg-4 Name
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-bg-4 Start Date
              //-     div(style="argin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-bg-4 Order Status
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-bg-4 Amount
              //-   .d-flex.mt-3(v-for="(item,index) in dataDashboard.newest_order")
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-03(style="font-weight:normal;") {{item.order_id}}
                
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{item.name}}
              //-     div(style="margin-left:25px;margin-right:25px;")
                  
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{formatDate2(item.start)}}
              //-     div(style="margin-left:25px;margin-right:25px;")
                    
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.text-warning(v-show="item.status=='Order Created'" ) {{item.status}}
              //-       .matador-h5.matador-text-light-blue(v-show="item.status=='Order Done'" ) {{item.status}}
              //-       .matador-h5.matador-text-green(v-show="item.status=='Order Rent'" ) {{item.status}}
              //-       .matador-h5.text-danger(v-show="item.status=='Order Canceled'" ) {{item.status}}
              //-       .matador-h5.text-success(v-show="item.status=='Order Confirmed'" ) {{item.status}}
              //-     div(style="margin-left:25px;margin-right:25px;")
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{item.currency}} {{mataUang(item.price)}}
              //- .row.mt-4.pb-1(style="text-align:left;border-bottom:1px solid #F0F1F3")
              //-   .col-lg-2.col-md-2.col-sm-2
              //-     .matador-h5.matador-text-bg-4 #Order
              //-   .col-lg-2.col-md-2.col-sm-2
              //-     .matador-h5.matador-text-bg-4 Name
              //-   .col-lg-2.col-md-2.col-sm-2
              //-     .matador-h5.matador-text-bg-4 Start Date
              //-   .col-lg-3.col-md-3.col-sm-3
              //-     .matador-h5.matador-text-bg-4 Order Status
              //-   .col-lg-3.col-md-3.col-sm-3
              //-     .matador-h5.matador-text-bg-4 Amount
              //- .table-responsive
              //-   .row.mt-3(v-for="(item,index) in dataDashboard.newest_order")
              //-     .col-lg-2.col-md-2.col-sm-12
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{item.order_id}}
              //-     .col-lg-2.col-md-2.col-sm-12
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{item.name}}
              //-     .col-lg-2.col-md-2.col-sm-12
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{formatDate2(item.start)}}
              //-     .col-lg-3.col-md-3.col-sm-12
              //-       .matador-h5.text-warning(v-show="item.status=='Order Created'" ) {{item.status}}
              //-       .matador-h5.matador-text-light-blue(v-show="item.status=='Order Done'" ) {{item.status}}
              //-       .matador-h5.matador-text-green(v-show="item.status=='Order Rent'" ) {{item.status}}
              //-       .matador-h5.text-danger(v-show="item.status=='Order Canceled'" ) {{item.status}}
              //-       .matador-h5.text-success(v-show="item.status=='Order Confirmed'" ) {{item.status}}
              //-     .col-lg-3.col-md-3.col-sm-12
              //-       .matador-h5.matador-text-03(style="font-weight:normal") {{item.currency}} {{mataUang(item.price)}}
              .row.mt-3.pb-1
                keep-alive
                  .table-responsive
                    bs-table.m-0(
                      ref="table"
                      :columns="table.columns",
                      :options="tableOptions",
                      @on-post-body="vueFormatterPostBody"
                    )
      .col-lg-4.col-md-12.col-sm-12.col-xs-12
        .row
          .col-12
            card
              .row.justify-content-between
                  .matador-h5.matador-text-02 Vehicle
                  a.matador-h5.matador-text-05(@click="managePage('/manageCatalogue')") All Vehicle
                    chevron-right.ml-2
              div.doughnut-text-center(style="text-align:center;")
                .matador-h5.matador-text-03  Available
                .matador-h2.matador-text-02 {{dataDashboard.vehicle_available}}
              DoughNut( ref="vehicle_chart" :chart-data="doughnutData()" :height="300" :options="doughnutOptions")
        .row
          .col-12
            card(style="padding:0px !important;")
              .matador-h5.matador-text-02 Top 5 Vehicles
              div(v-for="(item,index) in dataDashboard.top_vehicle" :key="index")  
                .row.mt-4()
                  .col-lg-5.col-md-4.col-sm-4.col-xs-4
                    img.mb-2.mb-lg-0(width="100px" height="75px"  v-if="item.image" :src="item.image | assets")
                    img.mb-2.mb-lg-0(width="100px" height="75px"  v-else src="@/assets/img/car-placeholder.png")
                  .col-lg-7.col-md-8.col-sm-8.col-xs-8
                    .matador-h5.matador-text-01 {{item.series}}
                    .matador-caption-3.matador-text-02.mt-3 Rp {{mataUang(item.price_per_day)}} /day

</template>
<script>
import DoughNut from "@/components/Chartjs/DoughnutChart";
import LineChart from "@/components/Chartjs/LineChart";
import { getRentalDashboard } from "@/utils/api";
import {  getOrganizationList } from "@/utils/api";
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import {mapGetters} from 'vuex'
import dayjs from 'dayjs';
import tableMixin from "@/mixins/table";
const doughnutOptions = {
  responsive: true, 
  maintainAspectRatio: false, 
  animation: {
    animateRotate: true
  },
  legend: {
        display: true
  },
}

export default {
  mixins: [tableMixin],
  components:{
    LineChart,DoughNut,ChevronRight,ChevronDown
  },
  data() {
    return {
      optionSelectOrganization:[],
      doughnutOptions,
      statusVisitorPersen:{
        up:false,
        down:false,
        linear:false,
      },
      statusOrderDone:{
        up:false,
        down:false,
        linear:false,
      },
      statusOrderCancel:{
        up:false,
        down:false,
        linear:false,
      },
      gradient:['rgba(238, 201, 69, 1)','rgba(241, 246, 255, 1)'],
      gradientOne:"rgba(238, 201, 69, 1)",
      gradientTwo: "rgba(241, 246, 255, 1)",
      dataDashboard:[],
      duration:'weekly',
      id_organization:'',
      alertChoose:true,
      table:{
        columns:[
          {
          title:"#Order_id",
          field:"order_id",
          widthUnit:'em',
          width:'7',
          class:"matador-h5-normal matador-text-03"
          },
          {
          title:"Name",
          field:"name",
        
          widthUnit:'em',
          width:'7',
          class:"matador-h5-normal matador-text-03"
          },
          {
            title:"Start Date",
          field:"start",
          widthUnit:'em',
          width:'7',
          class:"matador-h5-normal matador-text-03",
          formatter:(value,row)=>{
              var date= this.formatDate2(value)
              return date
            }
          },
          {
          title:"Order Status",
          field:"status",
          widthUnit:'em',
          width:'7',
          class:"matador-h5-normal matador-text-03",
          formatter:(value,row)=>{
            return this.vueFormatter({
              template:
              `<div>
                <p v-show="value=='Order Created'" class="matador-h5 text-warning">{{value}}</p>
                <p v-show="value=='Order Done'" class="matador-h5 matador-text-light-blue">{{value}}</p>
                <p v-show="value=='Order Rent'" class=" matador-h5 matador-text-green">{{value}}</p>
                <p v-show="value=='Order Canceled'" class=" matador-h5 text-danger">{{value}}</p>
                <p v-show="value=='Order Confirmed'" class=" matador-h5 text-success">{{value}}</p>
              </div>`,
              data: { value,row },
            });
          }
          
          },
          {
          title:"Amount",
          field:"price",
          
          widthUnit:'em',
          width:'7',
          class:"matador-h5-normal matador-text-03",
          formatter:(value,row)=>{
              var date= "Rp. "+this.mataUang(value)
              return date
            }
          },
        ]
      },
      optionsDuration:[
        {
          text:"7 days ago",
          value:"weekly",
        },
        {
          text:"1 Month ago",
          value:"monthly",
        },
      ]
    }
  },
  watch:{
    duration:{
      handler:function(newVal){
      this.duration=newVal
        if(this.auth.role=="superadmin"){
          this.conditionalSelectSuperadmin();
        }else{
          this.conditionalSelect();
        }
      }
    },
    id_organization:{
      handler:function(newVal){
      this.id_organization=newVal
        this.conditionalSelectSuperadmin()
      }
    },
  },
  computed:{
    tableOptions() {
      return {
          ajax: this.getTableData,
          classes: ["table", "table-hover"],
        }
    },
    ...mapGetters({
      auth:'gettersAuth'
    }),
    lineChart(){
      if(this.dataDashboard.length !=0){
        let label={};
        let total={};
        label=this.dataDashboard.revenue.length !=0 ? this.dataDashboard.revenue.map((item)=>{
          return this.formatDate(item.label);
        }) :null;
        total=this.dataDashboard.revenue.length !=0 ? this.dataDashboard.revenue.map((item)=>{
          return item.total;
        }):null;
        let dataCollection={
          labels: label,
          datasets:[
            {
              label:this.duration=='weekly'?"week":"month",
              data : total,
              backgroundColor:[this.gradientOne]
              
            }
          ],
        }
        return dataCollection;
      }else{
        console.log("data Line Card Kosong")
      }
    },
  },
  methods: {
  conditionalSelect(){
        if(this.duration != '' ){
          this.alertChoose=false
          this.getRentalDashboardData();
          this.doughnutData();
          // this.lineChart();
          // this.updateLineChart();
          
          }else{
            this.alertChoose=true
          }
    },
  conditionalSelectSuperadmin(){
      if(this.duration != '' && this.id_organization != '' ){
        this.alertChoose=false
        this.getRentalDashboardData();
        this.doughnutData();
        // this.lineChart();
        // this.updateLineChart();
        
        }else{
          this.alertChoose=true
        }
    },
    managePage(params){
      this.$router.push(params)
    },
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
    formatDate(params){
      var date=dayjs(params).format('DD/MMM')
      return date
    },
    formatDate2(params){
      var date=dayjs(params).format('D MMM YYYY')
      return date
    },
    updateDoughNut(){
      this.$refs.vehicle_chart.update();
    },
    updateLineChart(){
      this.$refs.revenue_chart.update();
    },
    doughnutData(){
      if(this.dataDashboard.length !=0){
        let label=["On Rent","Idle"];
        
        /* let availableLabel=this.dataDashboard != null ? this.dataDashboard.vehicle_available.toString():"0"; */
        let rent=parseInt(this.dataDashboard.vehicle_on_rent)
        let idle=parseInt(this.dataDashboard.vehicle_idle)

          // Chart.pluginService.register({
          //   beforeDraw: function(chart) {
          //     var width = chart.chart.width,
          //         height = chart.chart.height,
          //         ctx = chart.chart.ctx;

          //     ctx.restore();
          //     var fontSize = (height / 114).toFixed(2);
          //     ctx.font = fontSize + "em sans-serif";
          //     ctx.textBaseline = "middle";

          //     var text = availableLabel,
          //         textX = Math.round((width - ctx.measureText(text).width) / 2),
          //         textY = height / 2;

          //     ctx.fillText(text, textX, textY);
          //     ctx.save();
          //   }
          // });
        let dataCollection={
          labels: label,
          datasets:[
            { 
              data : [rent,idle],
              backgroundColor:["#669DFF","#C48FD3",]
            },
            
          ],
        }
        return dataCollection;
      }else{
        console.log("data doughtnut kosong")
      }
    },

    orderDoneFunc(){
      if(this.dataDashboard.length !=0){
        if(this.dataDashboard.percentase_order_done.includes("+")){
          this.statusOrderDone.up=true;
          this.statusOrderDone.down=false;
          this.statusOrderDone.linear=false;
        }else if(this.dataDashboard.percentase_order_done.includes("-")){
          this.statusOrderDone.up=false;
          this.statusOrderDone.down=true;
          this.statusOrderDone.linear=false;
        }else{
          this.statusOrderDone.up=false;
          this.statusOrderDone.down=false;
          this.statusOrderDone.linear=true;
        }
      }else{
        console.log("OrderDashboard Data kosong")
      }
    },
    orderCancelFunc(){
      if(this.dataDashboard.length !=0){
        if(this.dataDashboard.percentase_order_cancel.includes("+")){
          this.statusOrderCancel.up=true;
          this.statusOrderCancel.down=false;
          this.statusOrderCancel.linear=false;
        }else if(this.dataDashboard.percentase_order_cancel.includes("-")){
          this.statusOrderCancel.up=false;
          this.statusOrderCancel.down=true;
          this.statusOrderCancel.linear=false;
        }else{
          this.statusOrderCancel.up=false;
          this.statusOrderCancel.down=false;
          this.statusOrderCancel.linear=true;
        }
      }else{
        console.log("orderCancelFunc Belum Ada")
      }
    },
    onlineVisitorFunc(){
      if(this.dataDashboard.length !=0){

        if(this.dataDashboard.percentase_total_visitor.includes("+")){
            this.statusVisitorPersen.up=true;
          this.statusVisitorPersen.down=false;
          this.statusVisitorPersen.linear=false;
        }else if(this.dataDashboard.percentase_total_visitor.includes("-")){
          this.statusVisitorPersen.up=false;
          this.statusVisitorPersen.down=true;
          this.statusVisitorPersen.linear=false;
        }else{
          this.statusVisitorPersen.up=false;
          this.statusVisitorPersen.down=false;
          this.statusVisitorPersen.linear=true;
        }
      }else{
        console.log("OnlineVisitor data belum Ada")
      }
    },
    async getDataOrganizationList(){
      let response = await getOrganizationList()
      this.optionsList=response.data.result;
      response.data.result.forEach(element => {
        let obj={
          value:element.organization_id,
          text:element.name
        }
        this.optionSelectOrganization.push(obj);
        // console.log(this.optionSelectOrganization,"select isi")
      });
    },
    async getRentalDashboardData(){
      try {
        let obj={
          user_id:this.auth.user_id,
          duration:this.duration,
          organization_id:this.auth.role=="superadmin"?this.id_organization : this.auth.organization_id
        }
        let response = await getRentalDashboard(obj); 
          if (response.data.code == 200){
            this.dataDashboard=response.data.result;
            this.onlineVisitorFunc();
            this.orderDoneFunc();
            this.orderCancelFunc();
          }else{
            this.dataDashboard=[]
          }
          // console.log(this.dataDashboard,"ini data dashboard")
      } catch (error) {
        console.log(error)
      }
    },
    getTableData(params){
      try {
        let obj={
            user_id:this.auth.user_id,
            duration:this.duration,
            organization_id:this.auth.role=="superadmin"?this.id_organization : this.auth.organization_id
          }
          getRentalDashboard(obj).then(
            ({data: {code,status,result,message}}) =>{
              result.newest_order.map(item => {
                console.log(item)
                return item
              })
              params.success(result.newest_order);
            }
        );
      } catch (error) {
        // console.log("return data 0");
      }
    },
    rentalSettingPage(){
      this.$router.push('/rentalSetting')
    }
  },
  async created() {
    
    await this.getDataOrganizationList();
    await this.getRentalDashboardData()
    this.auth.role!='superadmin'?this.alertChoose=false :this.alertChoose=true;
    
  },
}
</script>
<style >
.doughnut-text-center{
  z-index: 2;
  position: relative;
  text-align:center;
  top:200px;
}
.th-inner{
  font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
color: #D3D4D8;
}
</style>