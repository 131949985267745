<template lang="pug">
div

  driver-form-modal(
    ref="form"
    :table="$refs.driverTable"
    :visible="showAdd"
    :currentDriver="currentDriver"
    :isEdit="isEdit"
    @setFormTypeEdit="setFormType"
    @close="showAdd=false"
    @onSave="onDriverChanged"
    @onDelete="onDriverDeleted"
  )

  geofence-modal(
    v-if="isGeofenceOpen"
    :devices="selectedDevices"
    :savingLoading="updateLoading"
    @result="collectResult"
    @close="isGeofenceOpen = false"
  )

  confirmation-modal(
    v-if="isConfirmDeleteOpen"
    @close="isConfirmDeleteOpen = false" 
    @confirm="onConfirmDelete" 
    title="Delete Driver"
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do yo want to delete this driver?

  list-out-of-geofence-modal(
    :visible="isListOutOfGeofenceOpen"
    :drivers="listOutOfGeofenceDriver"
    :loading="listOutOfGeofenceLoading"
    :timestamp="Date.now()"
    @close="isListOutOfGeofenceOpen = false"
  )

  div.row
    .col-12
      .d-flex.justify-content-end.mb-3
        matador-search#search-box(placeholder='Search Drivers' autocomplete='off')
        matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
          | Add Driver
        div.ml-2
          button.btn.btn-warning(
            v-if="totalOutOfGeofence > 0" 
            @click="fetchOutOfGeofenceList"
          ) Out of geofence ({{totalOutOfGeofence}})

      keep-alive
        .table-responsive
          bs-table(
            ref="driverTable"
            :columns="table.columns",
            :options="table.options",
            :data="userList",
            @on-post-body="vueFormatterPostBody"
          )
    
</template>

<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import DriverFormModal from "./CreateModal/CreateModal.vue";

import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import GeofenceModal from './Geofence/Geofence.vue'
import ListOutOfGeofenceModal from './ListOutOfGeofenceModal.vue'

import { TableOption } from './table'
import Api from '@/utils/api';
import {mapState} from 'vuex'

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus,
    DriverFormModal,
    GeofenceModal,
    ConfirmationModal,
    ListOutOfGeofenceModal
  },

  computed: mapState(['totalOutOfGeofence']),

  data() {
    return {
      currentDriver: {},
      selectedDevices: [],
      isGeofenceOpen: false,
      updateLoading: false,
      itemListLoadings: {},
      isListOutOfGeofenceOpen: false,
      listOutOfGeofenceDriver: [],
      listOutOfGeofenceLoading: false,
      selectedOrganization: null,
      // position: '',
      // geofence: null,
      inputData: {
        username: '',
        fullname: '',
        email: '',
        // position: '',
        // organization: '',
        password: '',
        // password_confirm: '',
      },
      isConfirmDeleteOpen: false,
      showAdd: false,
      isEdit: false,
      userList: [],
      table: TableOption(this)
    }
  },
  methods: {
    closeGeofence() {
      this.isGeofenceOpen = false
    },

    collectResult(result) {
      
      let devices = {}
      result.forEach(dev => {
        devices[dev.device_unit_id] = dev
      })

      this.selectedDevices.forEach(dev => {
        if (dev.device_unit_id && !devices[dev.device_unit_id]) {
          devices[dev.device_unit_id] = dev
        }
      })

      let payload = { 
        user_id: this.currentDriver.user_id,
        devices
      }

      if (this.updateLoading) return false
      this.updateLoading = true
      
      Api.driver.update(payload)
        .then(response => {
          if (response.status !== 200)
            return this.$toast.error('Failed to update geofence')
          
          let message = response.data.message
          this.$toast.success(message)
          this.$refs.driverTable.refresh()
          this.isGeofenceOpen = false
        })
        .catch(e => {
          let error = e.response ? e.response.data : e
          this.$toast.error(error.message)
        })
        .finally(() => {
          this.updateLoading = false
          this.currentDriver = {}
        })
    },

    openGeofenceModal(driver) {
      if (!this.selectedDevices.length)
        return this.$toast.error('No device is found')

      this.isGeofenceOpen = true
      this.currentDriver = driver
    },

    setFormType(formType) {
      this.isEdit = formType
    },

    clickAdd() {
      this.currentDriver = Object.assign({}, {updated_at: Date.now()})
      this.showAdd = true;
      this.isEdit = false
    },

    clickEdit(device) {
      this.currentDriver = device;
      this.showAdd = true;
      this.isEdit = true
    },

    clickDelete(driver) {
      this.deletedDriver = driver
      this.isConfirmDeleteOpen = true
    },

    onConfirmDelete() {
      Api.driver.delete(this.deletedDriver)
        .then(response => {
          if (response.status !== 200)
            return this.$toast.error('Failed to delete Driver')
          this.$toast.success(response.data.message)
        })
        .catch(e => {
          let error = e.response ? e.response : e
          let message = error.message || 'Failed to delete Driver'
          this.$toast.error(message)
        })
        .finally(() => {
          this.isConfirmDeleteOpen = false
          this.$refs.driverTable.refresh()
        })
    },
    
    fetchDriverList(params) {
      let payload = params.data
      Api.driver.list(payload)
        .then(response => {
          this.userList = response.data.result.data
          params.success(true);
        })
        .catch(e => {
          let err = e.response ? e.response : e
          let message = err.message
          this.$toast.error(message)
          params.error(true)
        })
    },


    getDetail(driver) {
      return Api.driver.detail(driver)
        .then(response => {
          if (response.status !== 200)
            return this.$toast.error("Failed to collect user data")

          let driver = response.data.result.data
          let devices = driver.devices || []
          let units = []

          devices.forEach(device => {
            if (device.device_unit_id) {
              units.push({
                id: device.device_unit_id,
                device_id: device.device_id,
                device_unit_id: device.device_unit_id,
                name: device.device_name,
                coordinate: device.coordinate,
                action: device.action,
                color: device.color
              })
            }
          })

          this.selectedDevices = units
          this.geofenceDevices = []

          units.forEach(item => {
            if (item.coordinate && !!item.coordinate.length) {
              this.geofenceDevices.push(item)
            }
          })

          this.selectedUser = {
            id: driver.user_id,
            name: driver.name
          }

          return {
            response, 
            selectedDevices: this.selectedDevices, 
            selectedUser: this.selectedUser
          }
        })
    },

    onDriverChanged(device) {
      this.showAdd = false;
      this.$refs.table.refresh();
      this.$toast.open('Successfully save driver data.');
    },

    onDriverDeleted() {
      this.$refs.table.refresh();
      this.$toast.open('Successfully delete driver data.');
    },


    fetchOutOfGeofenceList() {
      if (this.listOutOfGeofenceLoading)
        return false

      this.listOutOfGeofenceLoading = true
      this.isListOutOfGeofenceOpen = true
      
      let params = {
        organization_id: this.selectedOrganization ? this.selectedOrganization.organization_id : null
      }

      Api.driver.list_out_of_geofence(params)
      .then(response => {
        this.listOutOfGeofenceDriver = response.data.result.data.map(data => {
          return {
            driver_id: data.driver_id,
            driver: data.driver_name,
            latitude: data.latitude,
            longitude: data.longitude
          }
        })
      })
      .catch(e => {
        let error = e.response ? e.response : e
        let message = error.data ? error.data.message : e.message
        this.$toast.error(message)
      })
      .finally(() => {
        this.listOutOfGeofenceLoading = false
      })
    }

  },
  

  mounted() {

    let self = this;
    window.$('.search input').on('search', function() {
      if(!window.$(this).val()) {
        self.$refs.driverTable.resetSearch();
      }
    });
    
  },
};
</script>
<style>
  @import './styles/driver_list.scss';
</style>