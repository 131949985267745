import Dropdown from "@/components/Dropdown.vue";
import Api from '@/utils/api'


export function TableOption(self) {

    const options = {
      ajax: self.fetchDriverList,
      search: true,
      searchSelector: '#search-box',
      pagination: true,
      showSearchButton: true,
      sortable: true,
      paginationHAlign: "center",
      paginationDetailHAlign: "right",
      // sidePagination: "server",
      paginationParts: ["pageInfo", "pageList"],
      classes: ["table", "table-hover"],
      loadingFontSize: 16,
    }


    const badgeFormatter = (value, row) => {
      let devices = ['-']

      if (row.devices && !!row.devices.length) {
        devices = row.devices.map(item => (
          `<span class="badge badge-grey">${item.device_name}</span>`
        ))
      }
      
      return devices.join(', ')
    }



    const toggleGeofenceFormatter = (value, row) => {
      
      return self.vueFormatter({
          template: `
          <div class="d-flex justify-content-center">
              <span 
                :class="loading ? 'badge badge-light badge-as-btn' : 'badge badge-success badge-as-btn' " 
                style="padding: 6px 10px;" 
                @click="onClick">{{ loading ? 'Loading...' : 'Edit Geofence' }}</span>
          </div>
          `,
          data() {
            return { row, value, loading: false }
          } ,
          methods: {
            onClick() {
              if (this.loading) return false
              this.loading = true
              
              self.getDetail(row)
                .then(response => {
                  self.openGeofenceModal(row)
                })
                .finally(() => this.loading = false)

            },
          },
      });
    }


    
    const dropdownFormatter = (value, row) => {
      return self.vueFormatter({
        template: `
        <div>
            <dropdown tag="a" class="dropdown-toggle-no-caret dropleft" icon="mdi mdi-dots-vertical matador-primary-color h4">
                <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit Driver</li>
                <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete Driver</li>
            </dropdown>
        </div>`,
        data: { row, value },
        methods: {
          onEdit() {
            self.clickEdit(row)
          },
          onDelete() {
            self.clickDelete(row)
          }
        },
        components : {
          Dropdown
        }
      });
    }




    const columns = [
      {
        title: "Driver Name",
        field: "name",
        searchable: true,
        sortable: true,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Phone",
        field: "phone",
        sortable: true,
        searchable: false,
        widthUnit: '%',
        width: '22'
      },
      {
        field: "description",
        title: "Description",
        sortable: false,
        searchable: false,
        widthUnit: '%',
        width: '22'
      },
      {
        field: "device_name",
        title: "Device Name",
        sortable: true,
        searchable: false,
        formatter: badgeFormatter,
        widthUnit: '%',
        width: '22'
      },
      {
        field: "",
        title: "Geofence",
        sortable: false,
        searchable: false,
        formatter: toggleGeofenceFormatter,
        class: 'text-center',
        widthUnit: '%',
        width: '22'
      },
      {
        field: "",
        title: "",
        formatter: dropdownFormatter,
        sortable: false,
        searchable: false,
        widthUnit: '%',
        width: '5'
      },
    ]

    return {
        options,
        columns
    }
}