<template lang="pug">
div#pdf-report.p-1
  //- loader(v-if="progress < maxProgress && maxProgress - progress > 1" :loading="true" :text="`Please wait, ${progress} of ${maxProgress} tasks has been completed`")
  //- loader(v-if="progress < maxProgress && maxProgress - progress == 1" :loading="true" :text="`Almost there, preparing your report...`")
  .row.justify-content-center(v-if="progressBarValue < 100")
    .col-lg-6
      p.text-center.mt-5 {{ progressBarValue > 90 ? 'Almost there...' : 'Preparing your report' }}
      b-progress(
        :value="progressBarValue" 
        :variant="progressBarVariant" 
        :animated="true" 
        :height="'7px'"
        class="mt-3"
      )
      
  div(v-if="device_id && dates" v-show="progress >= maxProgress" :style="'opacity:'+(progressBarValue === 100 ? 1 : 0.00125)")
    main-report(:device_id="device_id" :dates="dates" :report-token="reportToken" @load="onLoad" :is-pdf="true")
    .page-break
    //- sensor-report(:device_id="device_id" :dates="dates" :report-token="reportToken" @load="onLoad" :is-pdf="true")
    //- .page-break
    graph-report(:device_id="device_id" :dates="dates" :report-token="reportToken" @load="onLoad" :is-pdf="true")
    .page-break
    //- location-report(:device_id="device_id" :dates="dates" :report-token="reportToken" @load="onLoad" :is-pdf="true")
    //- .page-break
    map-report(:device_id="device_id" :zooms="zoom"  :dates="dates" :report-token="reportToken" @load="onLoad" :is-pdf="true")
    br
    br
</template>

<script>
import MainReport from '../../../components/Matador/Reports/MainReport.vue';
import SensorReport from '../../../components/Matador/Reports/SensorReport.vue';
import GraphReport from '../../../components/Matador/Reports/GraphReport.vue';
import MapReport from '../../../components/Matador/Reports/MapReport.vue';
import LocationReport from '../../../components/Matador/Reports/LocationReport.vue';
import { getReportXlsx } from '../../../utils/api';
export default {
  components: {
    MainReport,
    SensorReport,
    GraphReport,
    LocationReport,
    MapReport
  },
  data(){
    return {
      device_id: null,
      dates: null,
      progress: 0,
      progressBarValue: 0,
      maxProgress: 3,
      zoom:10,
      reportToken: Math.random()
    }
  },
  computed: {
    progressBarVariant(){
      let variant = 'danger';
      if(this.progressBarValue >= 95) variant = 'success';
      else if(this.progressBarValue >= 60) variant = 'primary';
      else if(this.progressBarValue >= 35) variant = 'warning';
      return variant;
    }
  },
  watch: {
    progress: {
      immediate: true,
      handler: function(newVal){
        if(newVal == 1) {
          this.progressBarValue = 20;
          setTimeout(() => {
            if(this.progressBarValue < 35) this.progressBarValue = 35;
            console.log("progress1")
          }, 3750);
        }
        if(newVal == 2) {
          this.progressBarValue = 50;
          setTimeout(() => {
            if(this.progressBarValue < 70) this.progressBarValue = 70;
            console.log("progress2")
          }, 3750);
        }
        if(newVal == 3) {
          this.progressBarValue = 80;
          setTimeout(() => {
            if(this.progressBarValue < 97) this.progressBarValue = 97;
            console.log("progress3")
          }, 3750);
        }
        if(newVal == 4) this.progressBarValue = 100;
        this.maxProgress - newVal == 1 && getReportXlsx({
          device_id: this.device_id,
          start_date: this.dates[0],
          end_date: this.dates[1],
        }).then(({ data: { result: { url } } }) => {
          setTimeout(() => {
            
            window.location.href = url;
            this.progress++;
            console.log("progress4")
            
          }, 10000);
          setTimeout(() => {window.print();console.log("progressterakhir")}, 20000);
        });
      }
    }
  },
  methods: {
    onLoad(){
      this.progress++;
    }
  },
  mounted(){
    this.device_id = this.$route.query.device_id;
    this.dates = [this.$route.query.start_date, this.$route.query.end_date]
  }
}
</script>