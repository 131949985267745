<template lang="pug">

div.modal-mask#detailHistoryModal(v-if="isDetailOpen")
  .modal-wrapper
    .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")

      .modal-content(v-if="detail && detail.history_id")

        .modal-header.d-flex.align-items-center
          div.px-2(v-if="loading")
            | Latest Condition
          .d-flex.flex-column(v-if="!loading")
            .h5.font-weight-bold.card-title.px-2 {{ unit.name }}
            p.card-subtitle.px-2.m-0.p-0 {{ unit.vin }}

          button.close(
            type="button",
            data-dismiss="modal",
            aria-label="Close",
            @click="$emit('close')"
          )
            span.pr-3(aria-hidden="true") &times;

        .modal-body
          loader(:loading="loading")
            .map-wrapper
              .map-container

                google-map(
                  ref="googleMap"
                  :options="map.options"
                  :zoom="map.zoom"
                )
                  google-map-marker(
                    v-if="map.marker.position.lat && map.marker.position.lng"
                    :position="map.marker.position"
                  )

            hr 
            div.row.mb-5
              div.col-md-4.unit-info
                p
                  span Driver:
                  span {{ driver.name }}
                p
                  span Phone:
                  span {{ driver.phone }} 
                
              div.col-md-4(v-for="table in titles")
                table.table
                  tbody
                    tr(v-for="info in table")
                      td.py-0.px-1.item-info
                        div.d-flex.justify-content-between
                          p {{ info.title }} 
                          p {{ meta[info.key] }} <span v-html="info.unit"></span>

      .modal-content(v-else)
        .modal-body.d-flex.justify-content-center.flex-column.align-items-center.p-5
          loader(:loading="loading")
            h3 History data not found
            div.mt-5
              button.btn.btn-light(@click="$emit('close')") close
            
</template>

<script>

const titleEntity = (key, title, unit) => {
  unit = unit || ''
  return {key, title, unit}
}


export default {
  props: ['isDetailOpen', 'loading', 'detail'],
  data() {
    return {
      id: null,
      
      map: {
        zoom: 12,
        options: {
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
        },
        marker: {
          position: {} 
        },
      },

      unit: {
        name: '',
        vin: ''
      },

      driver: {
        name:'-',
        phone: '-'
      },

      meta: {},
      
      titles: [
        {
          fuel: titleEntity('fuel', 'Fuel'),
          operate_time: titleEntity('operate_time', 'Operate Time'),
          speed: titleEntity('speed', 'Speed'),
          engine_speed: titleEntity('engine_speed', 'Engine Speed'),
        },
        {
          engine_temperature: titleEntity('engine_temperature', 'Engine Temp', '&deg;C'),
          signal: titleEntity('signal', 'Signal'),
          timestamp: titleEntity('timestamp', 'Last Update')
        }
      ] 
    }
  },

  watch: {
    detail: {
      handler(value) {
        if (!value) return null

        this.meta = value.meta || {}
        let position = {
          lat: parseFloat(this.meta.latitude),
          lng: parseFloat(this.meta.longitude)
        }
        this.map.marker.position = position

        const self = this
        const googleMap = this.$refs.googleMap
        
        if (position.latitude && position.longitude) {
          initCallback(1000, 10, (done) => {
            googleMap.$_getMap()
              .then(map => map.setCenter(self.map.marker.position))
              
            if (googleMap.map) done()
          })
        }


        if (value.unit) {
          this.unit.vin = value.unit.vin || '-'
          this.unit.name = value.unit.name || '-'
          this.unit.id = value.unit.id 
        }

        if (value.driver) {
          this.driver.id = value.driver.id
          this.driver.name = value.driver.name || '-' 
        }
        
        this.id = value.history_id
      }
    }
  }

  
}
</script>

<style lang="scss">
#detailHistoryModal {
  .card-subtitle {
    color: #b5b5b5;
    font-size: 0.8rem;
  }

  .map-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    
    .map-container {
      background: #dcdcdc;
      border-radius: 10px;
      min-height: 300px;
      overflow: hidden;
      display: flex;
      justify-content: stretch;
      flex-direction: column;

      .vue-google-map {
        flex: 1;
        display: flex;
        justify-content: stretch;
        flex-direction: column;
      }
    }

    .map-view {
      width: 100%;
      flex: 1!important;
    }
  }


  .unit-info {
    p {
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-bottom: 5px;
      font-size: 0.8rem;
      
      span:last-child {
        font-weight: bold;
      }
    }
  }

  .item-info {
    border: none!important;
    
    p {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }
  }
}
</style>
