<template lang="pug">
div#monitoring
  card(title='Monitoring')
    .d-flex.mb-4
      div.w-100.pr-4
        matador-search#search-button(
          @input="onSearch" 
          placeholder='Search' 
          autocomplete='off'
        )
        
      .p-0
        b-dropdown#dropdown-1.my-0(style='width: 200px' :text='"Show : "+{"all": "All Status", "true": "On", "idle":"Idle","false": "Off"}[status]' variant='primary' size="md")
          b-dropdown-item(@click="status = 'all'") Vehicle Status: All status
          b-dropdown-item(@click="status = 'true'") Vehicle Status: On
          b-dropdown-item(@click="status = 'idle'") Vehicle Status: Idle
          b-dropdown-item(@click="status = 'false'") Vehicle Status: Off

    loader(:loading="loading")
      p.text-center(v-if="devices.length == 0") No data available

    div(:loading="!loading")
      .row
        .col-12.col-md-4(
          v-for="(device, index) in devices" 
          :key="device.device_id" 
          @mouseenter="hoveredDevice = device"
        )
          monitoring-card(
            :device="device"
            @confirm="confirm"
            @followVehicle="onFollowVehicle"
            @openDetailObd="onOpenObdDetail"
          )
          
      .pt-4.mt-3
        .d-flex.align-item-center.justify-content-between
          b-pagination(
            v-model="page"
            :total-rows="totalItem"
            :per-page="perPage"
            aria-controls="my-table"
          )
          div.d-flex.align-items-center
            span.mr-2 Per&nbsp;Page: 
            b-dropdown(:text='perPage.toString()' variant='light')
              b-dropdown-item(@click="perPage = 12") 12
              b-dropdown-item(@click="perPage = 48") 48
              b-dropdown-item(@click="perPage = 96") 96
              b-dropdown-item(@click="perPage = 184") 184
  
  monitoring-map(
    v-if="!!showMapModal"
    :loading="loadingMapModal"
    :vehicle="vehicleMap"
    @close="showMapModal = false"
    @followVehicle="onFollowVehicle"
    @showDetail="onOpenDetail"
    @clearTracking="clearTracking"
  )
  
  monitoring-detail(
    v-if="!!showVehicleModal"
    :loading="loadingDetail"
    :dataVehicle="vehicleDetail" 
    @close="showVehicleModal = false"
  )

  monitoring-obd(
    v-if="!!showObdModal"
    :vehicle="selectedVehicle"
    @close="showObdModal = false"
  )
  
  confirmation-modal(
    v-if="!!willResetVehicle"
    :vehicle="willResetVehicle" 
    @close="willResetVehicle = null" 
    @confirm="onResetVehicle" 
    confirm-text="Yes, Reset"
  )
    p.m-0
      | You are about to 
      strong reset realtime setting 
      | vehicle 
      strong {{ willResetVehicle ? willResetVehicle.description : '' }}
  
  confirmation-modal(
    v-if="showStatusModal"
    :vehicle="hoveredDevice" 
    @close="showStatusModal = false" 
    @confirm="onSwitchStatus" 
    :confirm-text='hoveredDevice.operating ? "Yes, Turn Off" : "Yes, Turn On"'
  )
    p.m-0
      | You are about to 
      strong {{ hoveredDevice.operating ? 'turn off ' : 'turn on ' }}
      | vehicle 
      strong {{ hoveredDevice.vehicle_id }}
      | . Do you want to proceed?


</template>
<script>
import DropdownMonitoring from "@/components/DropdownMonitoring.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";

import MonitoringMap from "./components/MapModal.vue";
import MonitoringCard from "./components/ListCard.vue";
import MonitoringDetail from "./components/VehicleDetailModal.vue";
import MonitoringObd from "./components/OBDDetailModal.vue";

import Api from "@/utils/api";
import entity from "./entity";

export default {
  components: {
    DropdownMonitoring,
    ChevronDown,
    ChevronUp,
    ConfirmationModal,

    MonitoringMap,
    MonitoringCard,
    MonitoringDetail,
    MonitoringObd,
  },

  data() {
    return {
      interval: null,

      totalItem: 0,
      perPage: 12,
      page: 1,

      map: null,
      latitude: null,
      longitude: null,

      showMapModal: false,
      showVehicleModal: false,
      showStatusModal: false,
      showObdModal: false,

      loading: false,
      loadingMapModal: false,
      loadingDetail: false,
      loadingRestart: false,
      loadingSwitch: false,

      vehicleMap: null,
      vehicleDetail: null,
      willResetVehicle: null,
      hoveredDevice: null,

      selectedVehicle: null,
      search: "",
      devices: [],
      status: "all",
    };
  },

  watch: {
    page: {
      handler() {
        this.getMonitoring();
      },
    },

    perPage: {
      handler(number, old) {
        if (number != old) {
          this.page = 1;
        }
        this.getMonitoring();
      },
    },

    status: {
      handler: function () {
        this.getMonitoring();
      },
    },
  },

  methods: {
    onOpenObdDetail(vehicle) {
      if (!vehicle.obd) return false;
      this.selectedVehicle = vehicle;
      this.showObdModal = true;
    },

    onOpenDetail() {
      if (this.loadingDetail || !this.vehicleMap.vehicle_id) return;
      this.loadingDetail = true;
      this.showVehicleModal = true;

      const vehicle_id = this.vehicleMap.vehicle_id;
      this.vehicleDetail = entity.VehicleDetailModalEntity();

      const responseHandler = (response) => {
        let result = response.data;

        if (response.status !== 200) throw new Error(result.message);

        this.vehicleDetail = result.result.data;
      };

      Api.vehicle
        .detail(vehicle_id)
        .then(responseHandler)
        .catch((e) => {})
        .finally(() => {
          this.loadingDetail = false;
        });
    },

    confirm(type, vehicle) {
      if (type === "reset") {
        this.willResetVehicle = vehicle;
      } else if (type === "switch") {
        this.showStatusModal = true;
        this.hoveredDevice = vehicle;
      }
    },

    onSearch(value) {
      this.search = value;
      this.getMonitoring();
    },

    noop() {
      this.willResetVehicle = null;
    },

    onSwitchStatus() {
      if (this.loadingSwitch) return;
      this.loadingSwitch = true;

      let action = this.hoveredDevice.operating ? "off" : "on";

      let payload = {
        vehicle_id: this.hoveredDevice.vehicle_id,
        imei: this.hoveredDevice.imei,
        action,
      };

      const responseHandler = (response) => {
        let result = response.data;
        if (response.status !== 200)
          throw new Error(result.message || "Failed to switch status");

        this.$toast.success(
          `Successfully switch operating status to: ${action}`
        );
        this.getMonitoring();
      };

      Api.vehicle
        .switch_status(payload)
        .then(responseHandler)
        .catch((e) => {
          this.$toast.error("Failed to switch status");
        })
        .finally(() => {
          this.hoveredDevice = null;
          this.loadingSwitch = false;
          this.showStatusModal = false;
        });
    },

    onResetVehicle() {
      if (this.loadingRestart) return;
      this.loadingRestart = true;

      const responseHandler = (response) => {
        if (response.status !== 200)
          throw new Error("Failed to restart vehicle");

        this.$toast.open("Successfully reset the vehicle data");
      };

      let payload = {
        vehicle_id: this.willResetVehicle.vehicle_id,
        imei: this.willResetVehicle.imei,
        action: "setReset(3)",
      };
      Api.vehicle
        .restart(payload)
        .then(responseHandler)
        .catch((e) => {
          this.$toast.error("Failed to restart vehicle");
        })
        .finally(() => {
          this.loadingRestart = false;
          this.willResetVehicle = null;
        });
    },

    getMonitoring() {
      if (this.loading) return;

      this.loading = true;
      this.devices.length == 0;

      let query = {
        q: null,
        status: this.status,
        page: this.page,
        limit: this.perPage,
        sort: "device_name.asc",
      };

      if (this.search) query.q = this.search;

      switch (this.status) {
        case "true":
          query.status = "on";
          break;

        case "false":
          query.status = "off";
          break;

        case "idle":
          query.status = "idle";
          break;

        default:
          query.status = null;
      }

      Api.vehicle
        .monitoring_list(query)
        .then((response) => {
          if (response.status !== 200) throw new Error();

          let data = response.data.result.data;
          let pagination = response.data.result.pagination || {};

          this.devices = data.map((item) => entity.MonitoringListEntity(item));
          this.totalItem = pagination.total_data || 0;
        })
        .catch((e) => {})
        .finally(() => (this.loading = false));
    },

    onFollowVehicle(vehicle) {
      this.vehicleMap = entity.VehicleFollowEntity(vehicle);
      this.clearTracking();
      this.showMapModal = true;
    },

    clearTracking() {
      if (!window.animateTracker) return;

      clearInterval(window.animateTracker);
      window.animateTracker = null;
    },
  },
  mounted() {
    this.getMonitoring();
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
#monitoring {
  .material-design-icon {
    > .material-design-icon__svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  .card.card-monitoring {
    border: 1px solid rgba(0, 0, 0, 0.015);
    .monitoring-img {
      position: relative;
      button {
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
  }
  .device-list {
    a.text-dark {
      &:hover {
        background-color: #fbfbfb;
      }
    }
    td {
      .btn-sm {
        text-transform: none;
        border-radius: 5px;
        border-width: 2px;
        &.btn-outline-danger {
          &:hover,
          &:active,
          &:visited,
          &:focus {
            background-color: #fff !important;
            color: $danger-color;
          }
        }
      }
    }
  }
}
</style>
