<template lang="pug">
#geofence-form(:class="{'visible': visible}")
  div
    h4.h5.font-weight-bold.text-dark.mt-2.mb-4 Geofence Properties
    
    b-form-group(label="Device Name")
      multiselect(
        v-model="selectedDevices",
        track-by="id",
        :multiple="true",
        label="name",
        placeholder="Device name",
        :options="devices",
        :searchable="true",
        :allow-empty="false",
        :closeOnSelect="false"
      )

    b-form-group
      .row
        .col-5
          label Color
          .row
            .col-4(v-for="item in colors")
              a.color-selector(href="javascript:;" @click="changeColor(item)")
                span.color(:style="'background-color: '+item")
                span.tick(v-if="color === item") ✔

        .col-auto.ml-auto
          label.d-block Auto-off (Engine)
          toggle-button(
            :sync="true"
            :margin="4"
            :width="40"
            :height="22" 
            :value="auto_off" 
            :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
            :labels="{'checked': 'On', 'unchecked': 'Off'}"
            @change="onSwitchAutoEngine"
          )

    .d-flex.justify-content-end
      button.btn.btn-light.py-1.px-4.border-0(@click="$emit('cancel')")
        span.d-block.px-3.text-danger Cancel

      button.btn.btn-danger.py-1.px-4(:disabled="!isCanSave" @click="onSave") 
        span.d-block.px-3 {{ loading ? 'Loading...' : 'Save'}}

</template>

<script>

export default {
  data(){
    return {
      geofence_id: null,
      selectedDevices: [],
      auto_off: false,
      lines: []
    }
  },
  props: ['visible', 'colors', 'color', 'paths', 'geofence', 'devices', 'loading'],
  computed: {
    coordinates() {
      return this.paths.map(({ lat, lng }) => ([lat, lng]));
    },
    isCanSave(){
      return this.coordinates.length >= 3 && this.color;
    }
  },
  watch: {
    devices: {
      immediate: true,
      handler: function(devices) {
        this.selectedDevices = []
        devices.forEach(item => {
          this.selectedDevices.push(item)
        })
      }
    }
  },
  
  methods: {
      
    onSwitchAutoEngine({ value: val }){
      this.auto_off = val;
    },

    changeColor(color){
      this.$emit('switchColor', color);
    },

    onSave() {
      let devices = this.selectedDevices.map(item => {
        item.coordinate = this.paths.map(item => [item.lat, item.lng])
        item.color = this.color
        item.action = this.auto_off ? 'on' : 'off'

        return item
      })

      this.$emit('result', devices)
    },
    
  },


  
}
</script>

<style lang="scss">
    @import './form.scss';
</style>