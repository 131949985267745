<template lang="pug">
div
  card
    .row
      .col-lg-3.col-md-2.col-sm-12.col-xs-12(style="padding:0px 0px !important")
        .matador-h3 Rental Setting
      .col-lg-3.col-md-2.col-sm-12.col-xs-12(v-if="this.auth.role=='superadmin'" style="padding:0px 0px !important")
        select.custom-select.form-control(type='text', v-model="id_organization"
                    :class="{ 'matador-placeholder': id_organization === ''}" style="color:black !important")
                      option(value="" selected disabled) Choose Organization
                      option(v-for="item in optionsList" :value="item.organization_id") {{item.name}}
    .row.mt-4   
      b-card.lining( no-body )
      b-tabs.texted(id="tabSetting" card vertical style="width:100% !important;height:700px;")
        b-tab( 
          :class="{'active': tabType == 'Invoice'}" title="Invoice" 
        )
          .row()
            .col-md-2.col-xs-1
            .col-md-8.col-xs-8
              .form-group
                .matador-h5.matador-text-02 Bank
                input.form-control(v-model="bank" type="text" placeholder="Bank")
            .col-md-2
          .row()
            .col-md-2.col-xs-1
            .col-md-8.col-xs-8
              .form-group
                .matador-h5.matador-text-02 A/C Number
                input.form-control(v-model="acNumber" type="number" placeholder="Account number")
            .col-md-2
          .row()
            .col-md-2.col-xs-1
            .col-md-8.col-xs-8
              .form-group
                .matador-h5.matador-text-02 In Favor
                input.form-control(v-model="favor" type="text" placeholder="PT.xxx")
            .col-md-2
          .row()
            .col-md-2.col-xs-1
            .col-md-8.col-xs-8
              .form-group
                .matador-h5.matador-text-02 Terms and Conditions
                ckeditor(:editor="editor" v-model="terms" :config="editorConfig")
                //- input.form-control(v-model="" type="textarea" placeholder="Terms and Conditions")
            .col-md-2
          .row()
            .col-md-2.col-xs-1
            .col-md-8.col-xs-8
              .form-group
                .matador-h5.matador-text-02 Notes
                ckeditor(:editor="editor" v-model="notes" :config="editorConfig")
                //- input.form-control(v-model="notes" type="textarea" placeholder="Notes")
            .col-md-2
          .row.mt-4.pb-2
            .col-lg-2.col-md-2.col-xs-1
            .col-lg-3.col-md-5.col-xs-3
              a(style="color:white !important"  href="#dialogku")
                matador-button.btn.btn-block.btn-primary(:disabled="!allowPostInvoiceData" @click="openDialogInvoice()")
                  .matador-h5 Save Changes
          .row.mt-4
            .col-lg-2.col-md-0.col-xs-0
            .col-lg-8.col-md-12.col-xs-12
          

        b-tab(   
                    
          :class="{'active': tabType == 'VAT'}" title="VAT")
          .row()
            .col-md-2.col-xs-1
            .col-md-8.col-xs-8
              .form-group
                .matador-h5.matador-text-02 VAT
                input.form-control(v-model="vat" type="textarea" placeholder="10%")
            .col-md-2
          .row.mt-4.pb-2
            .col-md-2.col-xs-1
            .col-md-4.col-xs-3
              a(style="color:white !important" href="#dialogmu")
                matador-button.btn.btn-block.btn-primary(:disabled="!allowPostVAT" @click="openDialogVAT()")
                  .matador-h5 Save Changes
          .row(style="margin-top:25vh")
            .col-lg-2.col-md-0.col-xs-1
            .col-lg-8.col-md-12.col-xs-10
  dialog-confirmation(v-if="showDialogInvoice" id="dialogku" @closeInvoice="resetInvoiceData()" :modal="'closeInvoice'" @confirm="postInvoiceData")
  dialog-confirmation(v-if="showDialogVAT" id="dialogmu" @closeVAT="resetVAT()" @confirm="postVAT" :modal="'closeVAT'" )


</template>
<script>
import DialogConfirmation from "../../../components/Matador/Setting/DialogConfirmation.vue";

// password
import Cropper from "cropperjs";
import FileUpload from "vue-upload-component";
import Dropdown from "@/components/Dropdown.vue";
import tableMixin from "@/mixins/table";
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import EyeOn from "vue-material-design-icons/Eye.vue";
import { mapGetters } from "vuex";
import { getOrganizationList } from "@/utils/api";
import { postRentalSetting, getRentalSetting } from "../../../utils/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  mixins: [tableMixin],
  components: {
    DialogConfirmation,
    EyeOff,
    EyeOn,
    FileUpload,
  },

  data() {
    return {
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        toolbar: ["bold", "italic", "|", "link"],
      },
      bank: null,
      acNumber: null,
      acName: null,
      favor: null,
      terms: null,
      notes: null,
      showDialogInvoice: false,
      showDialogVAT: false,
      vat: null,
      id_organization: "",
      optionsList: [],
      optionSelectOrganization: [],
      showVAT: false,

      show: "all",
      toggleApi: false,
      search: "",
      password: null,
      passwordType: "password",
      showDialogTheme: false,
      showDialogLogo: false,
      showDialogApi: false,
      showDialogRole: false,
      showDialogLoc: false,
      showDialogPassword: false,
      mergeArray: [],
      perPage: 10,
      table: {
        columns: [
          {
            title: "Username",
            field: "name",
            searchable: true,
            sortable: true,
            widthUnit: "%",
            width: "20",
          },
          {
            field: "position_id",
            title: "Role",
            searchable: false,
            sortable: true,
            widthUnit: "%",
            width: "20",

            formatter: (value, row) => {
              return this.vueFormatter({
                template: `
                <b-form-select v-model=value disabled-field="notEnabled" v-on:input="roleChange(row,value)">
                  <option value="position-1631546184562">Admin</option>
                  <option value="position-1632709715540">Super Admin</option>
                  <option value="position-1632709746127">Staff</option>
                </b-form-select>
              `,
                // <option value="position-1632709765088">User</option>
                //  <option value="position-1632709773654">Guest</option>

                data: { row, value },
                events: {
                  "click .btn": (e, value, row) => {
                    this.clickRow(row);
                  },
                },
                methods: {
                  roleChange: this.roleChange,
                },
              });
            },
          },
          {
            field: "log_auth.last_login",
            title: "Last Login",
            sortable: true,
            searchable: false,
            widthUnit: "%",
            width: "15",
          },
        ],
      },
      apiKeyFormAlive: null,
      show: "all",
      apiKeyForm: null,
      allRole: [
        {
          user_id: "",
          name: "",
          role: "{'position':'','position':''}",
          last_login: "",
        },
      ],
      dataRole: [],
      currentLogo: null,
      dataLogo: [],
      user_id: null,
      files: [],
      file: null,
      image: null,
      edit: false,
      cropper: true,
      currentTheme: localStorage.getItem("theme-color"),
      currentThemeAlive: null,
      roleType: "member",
      roleTypes: [],
      tabType: "Invoice",
      tabTypes: ["Invoice", "VAT"],
      passwords: null,
      confPasswords: null,
      newpassword: null,
      fieldsRole: [
        { widthUnit: "%", width: "30", key: "Roles", sortable: true },
        { widthUnit: "%", width: "70", key: "Permission", sortable: true },
        {
          widthUnit: "%",
          width: "20",
          key: "Member",
          label: "Member",
          sortable: true,
        },
      ],
      itemsRole: [
        {
          isActive: true,
          Roles: "Super Admin",
          Permission:
            "Dashboard, Vehicle Detail, Monitoring, Alert, Report, Track, Geofence, Rental & Payment, Driver, Vehicle, Device, User, User Privilege, Setting",
          Member: "5",
        },
        {
          isActive: false,
          Roles: "Admin",
          Permission:
            "Dashboard, Vehicle Detail, Monitoring, Alert, Report, Track, Geofence, Rental & Payment, Driver, Vehicle, Device, User, User Privilege, Setting",
          Member: "1",
        },
        {
          isActive: false,
          Roles: "Staff",
          Permission:
            "Dashboard, Vehicle Detail, Monitoring, Alert, Report, Track, Geofence, Rental & Payment, Billing, Setting",
          Member: "1",
        },
        {
          isActive: false,
          Roles: "User",
          Permission: "Rental & Payment, Setting",
          Member: "3",
        },
        {
          isActive: false,
          Roles: "Guest",
          Permission: "Etalase, Setting",
          Member: "0",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      auth: "gettersAuth",
    }),
    tableOptions() {
      return {
        data: this.getRoles(),
        search: true,
        pagination: true,
        pageSize: this.perPage,
        searchSelector: "#search-box",
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        paginationParts: ["pageInfo", "pageList"],
        classes: ["table", "table-hover"],
      };
    },
    tabTypeComponent() {
      return this.tabType.toLowerCase();
    },
    allowPostVAT() {
      if (this.auth.role == "superadmin") {
        return this.id_organization != "" && this.vat != null;
      } else {
        return this.vat != null;
      }
    },
    allowPostInvoiceData() {
      if (this.auth.role == "superadmin") {
        return (
          this.id_organization != "" &&
          this.bank != null &&
          this.acNumber != null &&
          this.favor != null &&
          this.terms != null &&
          this.notes != null
        );
      } else {
        return (
          this.bank != null &&
          this.acNumber != null &&
          this.favor != null &&
          this.terms != null &&
          this.notes != null
        );
      }
    },
  },
  watch: {
    pageSize: function (newVal) {
      this.$refs.table.refreshOptions();
    },
    id_organization: {
      handler: function () {
        this.getRentalData();
      },
    },
  },
  props: ["selected", "close", "title", "placeholder", "ratio"],

  methods: {
    openDialogInvoice() {
      this.showDialogInvoice = true;
      this.showDialogVAT = false;
    },
    openDialogVAT() {
      this.showDialogInvoice = false;
      this.showDialogVAT = true;
    },
    resetInvoiceData() {
      this.bank = null;
      this.terms = null;
      this.notes = null;
      this.acNumber = null;
      this.acName = null;
      this.favor = null;
      this.showDialogInvoice = false;
      this.getRentalData();
    },
    async postInvoiceData() {
      let organization =
        this.auth.role == "superadmin"
          ? this.id_organization
          : this.auth.organization_id;
      // console.log(this.auth.role,"ini organization")
      let obj = {
        organization_id: organization,
        bank_name: this.bank,
        account_name: this.favor,
        account_number: this.acNumber,
        term_condition: this.terms,
        notes: this.notes,
      };
      let response = await postRentalSetting(obj);
      if (response.data.code == 200) {
        this.showDialogInvoice = false;
        this.$toast.open(
          "Successfuly to change Invoice Data on this organization"
        );
      }
    },
    resetVAT() {
      this.vat = null;
      this.getRentalData();
      this.showDialogVAT = false;
    },
    async postVAT() {
      let organization =
        this.auth.role == "superadmin"
          ? this.id_organization
          : this.auth.organization_id;
      // console.log(organization,"ini organization")
      let obj = {
        organization_id: organization,
        vat: this.vat,
      };
      let response = await postRentalSetting(obj);
      if (response.data.code == 200) {
        this.showDialogVAT = false;
        this.$toast.open("Successfuly to change vat on this organization");
      }
    },
    async getDataOrganizationList() {
      let response = await getOrganizationList();
      this.optionsList = response.data.result;
      response.data.result.forEach((element) => {
        let obj = {
          value: element.organization_id,
          text: element.name,
        };
        this.optionSelectOrganization.push(obj);
        // console.log(this.optionSelectOrganization,"select isi")
      });
    },
    showVATFunc() {
      if (this.showVAT) {
        this.showVAT = false;
      } else {
        this.showVAT = true;
      }
    },
    async getRentalData() {
      let response = await getRentalSetting({
        organization_id:
          this.auth.role == "superadmin"
            ? this.id_organization
            : this.auth.organization_id,
      });
      // console.log("response",response)
      this.vat = response.data.result.vat;
      this.bank = response.data.result.bank_name;
      this.terms = response.data.result.term_condition;
      this.acNumber = response.data.result.account_number;
      this.notes = response.data.result.notes;
      this.favor = response.data.result.account_name;
    },
  },
  mounted() {
    this.getDataOrganizationList();
    if (this.auth.role != "superadmin") {
      this.getRentalData();
    }
    // var that = this;
    // window.$('.search input').on('search', function() {
    //   if(!window.$(this).val()) {
    //     that.$refs.table.resetSearch();
    //   }
    // });
  },
};
</script>

<style>
#tabSetting__BV_tab_controls_ {
  border-width: 2px;
  border-right-style: solid;
  border-color: #d9d9d9;
}
.texted {
  font-family: "Open Sans", sans-serif !important;
}
#color-choose {
  margin-bottom: 15px;
  border-width: 2px;
  border-bottom-style: solid;
  border-color: #d3d4d8;
}
.lining {
  border-width: 2px;
  border-right-style: solid;
  border-color: gray;
}
.image-border {
  border: solid 2px white;
}
.button {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 2px;
  background-clip: content-box;
  height: 80px;
  width: 10%;
  border-radius: 10%;
  margin: 10px 10px 10px 10px;
  position: static;
}

.blue {
  background-color: #7998b9;
  border: solid 2px #7998b9;
}
.red {
  background-color: #c2797c;
  border: solid 2px #c2797c;
}

.yellow {
  background-color: #fedf8c;
  border: solid 2px #fedf8c;
}
.gray {
  background-color: #d3d4d8;
  border: solid 2px #d3d4d8;
}

.upload-wrapper {
  border: none;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.upload-wrapper .avatar-upload .rounded {
  margin: auto;
  width: 400px;
  height: 400px;
}
.upload-wrapper .text-center .btn {
  margin: 0 0.5rem;
}
.upload-wrapper .avatar-edit-image {
  max-width: 100%;
}
.upload-wrapper img {
  max-width: 100%;
}
.upload-wrapper .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.upload-wrapper .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.nav nav-tabs flex-column card-header h-100 border-bottom-0 rounded-0 {
  padding-right: 10rem !important;
}
</style>

<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";
@import "../../../assets/sass/matador/_change-theme.scss";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
