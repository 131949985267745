<template lang="pug">
div#manageCatalogue
  card(title='Manage Catalogue')
    .row.mb-3()
      .col-md-10.col-sm-12.col-xs-12.mt-2
        matador-search#search-box(style="" v-model="search" placeholder='Search Rental By Series or Vehicle Type' autocomplete='off' )
      .col-md-2.col-sm-12.col-xs-12.mt-2
        matador-button.btn.btn-block.btn-outline-primary(style="height:40px;color:white !important" @click="onAdd", icon="fa fa-plus") Add Product
    .border-left.border-right.border-bottom
    keep-alive
      .table-responsive
        bs-table.m-0(
          ref="table"
          :columns="table.columns",
          :options="tableOptions",
          :data="etul"
          @on-post-body="vueFormatterPostBody"
          @on-search="onEvent"
        )
    etalase-add-form-modal(v-if="showEtalaseForm" :etal="itemEtalase" @close="showEtalaseForm = false" @success="onEtalaseChanged")

  confirmation-modal(
    v-if="deleteModal"
    @close="deleteModal = false" 
    @confirm="onConfirmDelete" 
    title="Delete Vehicle"
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do yo want to delete this etalase?

  confirmation-modal-accept(
    v-if="updateModal"
    @close="closeUpdateModal()" 
    @confirm="onConfirmShowHide" 
    :title="title"
    confirm-text="Yes, Update"
  )
    p.m-0
      | {{paragraph}}
</template>
<script>
import produce from 'immer';
import Vue from 'vue';
import dayjs from "dayjs";
import tableMixin from "@/mixins/table";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import ConfirmationModalAccept from "@/components/Matador/ConfirmationModalAccept";
import EtalaseAddFormModal from "@/components/Matador/EtalaseAddFormModal";
import EtalaseModal from "@/components/Matador/EtalaseModal";
import Dropdownetalase from "@/components/Dropdownetalase.vue";
import { getVehicleList, postSetAutoAssignEtalase ,postDeleteVehicle, postDeleteEtalase, postShowHideEtalase } from "@/utils/api";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import { get } from "lodash";
import { getManageEtalase,getEtalase } from '../../../utils/api';
export default {
  mixins: [tableMixin],
  components: {
    ConfirmationModal,
    ConfirmationModalAccept,
    PlusIcon,
    EtalaseAddFormModal,
    EtalaseModal,
    Dropdownetalase
  },
  watch: {
    search: {
      handler: function(newVal){
        this.currentPage = 1;
      }
    },
    checked: {
      handler: function(newVal){
        // console.log(newVal,"chekce")

      }
    },
    perPage: {
      handler: function(newVal, oldVal){
        if(newVal != oldVal){
          this.page = 1;
        }
      }
    },
  
    
  },
 
  data() {
    return {
      itemEtalase:null,
      checked:1,
      showAdd:false,
      perPage:10,
      title:"",
      paragraph:"",
      etul:[],
      // isBusy: false,
      // deleteModal: false,
      // updateModal: false,
      // page:1,
      // search: '',
      // checked:true,
      // fields: [
      //   { key: 'series', label: 'Series', sortable: true, class: '' },
      //   { key: 'name', label: 'Vehicle Type', sortable: true, class: '' },
      //   { key: 'total_vehicle', label: 'Total Vehicle', sortable: true, class: '' },
      //   { key: 'total_available', label: 'Avail', sortable: true, class: '' },
      //   { key: 'total_rent', label: 'On Rent', sortable: true, class: '' },
      //   { key: 'price_per_month', label: 'Per Month', sortable: true, class: '',
      //     formatter:(value,key,item)=>{
      //       var prefix="Rp.";
      //       var mataUang=this.mataUang(prefix,value);
      //       return mataUang;
      //     }
      //   },
      //   { key: 'price_per_day', label: 'Per Day', sortable: true, class: '',
      //     formatter:(value,key,item)=>{
      //         var prefix="Rp.";
      //         var mataUang=this.mataUang(prefix,value);
      //         return mataUang;
      //       }
      //   },
      //   { key: 'price_per_hour', label: 'Per Hour', sortable: true, class: '',
      //     formatter:(value,key,item)=>{
      //       var prefix="Rp.";
      //       var mataUang=this.mataUang(prefix,value);
      //       return mataUang;
      //     }
      //   },
      //   { key: 'price_per_km', label: 'Per Km', sortable: true, class: '',
      //     formatter:(value,key,item)=>{
      //       var prefix="Rp.";
      //       var mataUang=this.mataUang(prefix,value);
      //       return mataUang;
      //     }
      //   },
      //   { key: 'is_show', label: 'Visibility', sortable: true, class:'hello',
      //     formatter: (value, key) => {
      //       return value ? 'Shown' : 'Hidden' 
      //     }, 
      //   },
      //   { key: 'actions', label: ''},
      // ],
      // perPage: 12,
      // currentPage: 1,
      // totalRows: 0,
      // vehicle: null,
      // etalase:[],
      // showEtalaseForm: false,
      // showEtalaseDetail: false
      search:'',
      showEtalaseForm:false,
      showEtalaseDetail:false,
      deleteModal:false,
      updateModal:false,
      currentUser: this.$store.state.auth.user_id,
      table:{
        columns:[
        {
          title:"Series",
          field:"series",
          searchable:true,
          widthUnit:'em',
          width:'7',
          sortable:true
        },
        {
          title:"Vehicle Type",
          field:"name",
          searchable:true,
          widthUnit:'em',
          width:'7',
          sortable:true
        },
        {
          title:"Total Vehicle",
          field:"total_vehicle",
          searchable:true,
          widthUnit:'em',
          width:'8',
          sortable:true
        },
        {
          title:"Avail",
          field:"total_available",
          searchable:true,
          widthUnit:'em',
          width:'4',
          sortable:true
        },
        {
          title:"On Rent",
          field:"total_rent",
          searchable:true,
          widthUnit:'em',
          width:'6',
          sortable:true
        },
        {
          title:"Per Month",
          field:"price_per_month",
          searchable:true,
          widthUnit:'em',
          width:'6',
          sortable:true,
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        {
          title:"Per Day",
          field:"price_per_day",
          searchable:true,
          widthUnit:'em',
          width:'6',
          sortable:true,
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        {
          title:"Per Hour",
          field:"price_per_hour",
          searchable:true,
          widthUnit:'em',
          width:'6',
          sortable:true,
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        {
          title:"Per Km",
          field:"price_per_km",
          searchable:true,
          widthUnit:'em',
          width:'6',
          sortable:true,
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        {
          title:"Visibility",
          field:"is_show_text",
          searchable:false,
          widthUnit:'em',
          width:'6',
          sortable:true,
          formatter:(value,row)=>{
            return this.vueFormatter({
              template:
              `<div>
                <p v-show="value=='Shown'" class="matador-h5 text-primary">Shown</p>
                <p v-show="value=='Hidden'" class="matador-h5 text-danger">Hidden</p>
              </div>`,
              data: { value,row },
            });
          }
        },
        {
          field:"",
          title:"",
          formatter:(value,row)=>{
            return this.vueFormatter({
              template:
                `<div>
                  <dropdownetalase class="dropdown-toggle-no-caret" tag="a" icon="mdi mdi-dots-vertical h4 text-dark m-0">
                    <li class="p-3 matador-text-03" style="borderBottom:1px solid black !important;" @click="onShow(row)">{{row.is_show==1?'Hide this product in catalogue':'Shown this product in catalogue'}}</li>
                    <li class="p-3 matador-text-03" style="borderBottom:1px solid black !important;">
                      <b-form-checkbox v-model="checked" :checked="checked" @change="onCheck(row)":value="row.is_auto_assign==1?1:0">Auto Assign</b-form-checkbox>
                    </li>
                    <li class="p-3 matador-text-03" style="borderBottom:1px solid #F5F5F5 !important;" @click="onEdit(row)">Edit Product</li>
        
                    <li class="p-3 matador-text-03" style="borderBottom:0.1px solid #F5F5F5 !important;" @click="onDelete(row)">Delete Product</li>
                  </dropdownetalase>
                </div>`,
              data:{
                row,
                value,
                checked:this.checked
                },
              methods:{
                onShow : this.onShow,
                onEdit : this.onEdit,
                onDelete : this.onDelete,
                onCheck : this.onCheck,
              },
              components:{
                Dropdownetalase
              }
            })
          },
            sortable: false,
            searchable: false,
            widthUnit: 'em',
            width: '6'
        }
      ]
      }
    }
  },
  computed:{
    tableOptions() {
      return {
          ajax: this.getEtalase2,
          search: true,
          pagination: true,
          pageSize: this.perPage,
          searchSelector: "#search-box",
          showSearchButton: true,
          sortable: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: [ "pageInfo", "pageList"],
          classes: ["table", "table-hover"],
        }
    },
  },
  methods: {
    closeUpdateModal(){
      this.updateModal=false;
      this.$refs.table.refresh();
    },
    onCheck(row){
      this.title="Update Auto Assign";
      this.paragraph="Do yo want to set auto assign this etalase?"
      this.updateModal = true;
      this.itemEtalase={
        etalase_id:row.etalase_id,
        is_auto_assign:row.is_auto_assign
      }
    },
    onEvent(event) {
    },
    getEtalase2(params){
      try {
        let response = getManageEtalase().then(
          ({data: {code,status,result,message}}) =>{
            result.map(item => {
              // console.log(item)
              if(item.is_show==1){
                item.is_show_text="Shown"
              }else{
                item.is_show_text="Hidden"
              }
              return item
            })
            params.success(result);
          }
        );
      
      
      } catch (error) {
        // console.log("return data 0");
      }
    },
    mataUang(prefix,req){
      var	number_string = req.toString()
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
    
      return prefix+rupiah;
    },
    async onConfirmShowHide(){
      try {
        var req;
        if (this.title=="Update Visibility"){
          let show=null;
            if(this.itemEtalase.is_show == 1){
              show=0
            }else{
              show=1
            }
            var req={
              etalase_id:this.itemEtalase.etalase_id,
              is_show:show
            }
        }else{
          let assign=null;
          if(this.itemEtalase.is_auto_assign ==1 ){
            assign=0
          }else{
            assign=1
          }
          var req={
            etalase_id:this.itemEtalase.etalase_id,
            is_auto_assign:assign
          }
        }
        let api=this.title=="Update Visibility"?postShowHideEtalase(req) : postSetAutoAssignEtalase(req);
        let response=await api;
        this.updateModal=false;
        this.itemEtalase = null;
        this.$refs.table.refresh();
      } catch (error) {
        console.log(error)
      } 
    },
    async getEtalase(){
      try {
        let response = await getManageEtalase();
        // console.log(response.data.result);
        this.etalase=response.data.result;
      
      
      } catch (error) {
        // console.log("return data 0");
      }
    },
    getVehicles(ctx, callback){
      const { 
        currentPage: page,
        perPage: limit,
        filter: search,
        sortBy: sort_by,
        sortDesc
      } = ctx
      const sort = sortDesc ? 'desc' : 'asc';
      getVehicleList({ display_location: 0, start: page * limit - limit, limit, sort_by: sort_by || 'vehicle_name', sort, search }).then(({ data: { result: { vehicle, total_rows } } }) => {
        this.totalRows = total_rows;
        callback(vehicle.map(item => {
          item.description = get(item, 'description');
          item.vehicle_name = get(item, 'vehicle_name') || get(item, 'unit_id');
          item.name = get(item, 'name');
          item.series = get(item, 'series');
          return item;
        }));
      });
    },
    onAdd(){
      this.itemEtalase=null
      this.showEtalaseForm = true;
      // console.log(this.etalase)
    },
    onEdit(etal){
      this.itemEtalase = etal;
      // console.log(etal)
      
      this.showEtalaseForm = true;
    },
    async onShow(v){
      // console.log(v)
      this.title="Update Visibility";
      this.paragraph="Do yo want to update visibility this etalase?"
      this.updateModal = true;
      this.itemEtalase={
        etalase_id:v.etalase_id,
        is_show:v.is_show
      }
      
    /*  this.vehicle = vehicle;
      getVehicleDetail({
        unit_id: vehicle,
        trip: dayjs().format("YYYY-MM-DD"),
      }).then(({ data: { code, result, message } }) => {
        this.vehicle = result;
      });
      this.showVehicleDetail = true; */
    },
    onEtalaseChanged(){
      this.getEtalase2();
      this.showEtalaseForm = false;
      this.$refs.table.refresh();
      this.$toast.open('Successfully save etalase data.');
    },
    onDelete(etal){
      this.itemEtalase = etal.etalase_id;
      this.deleteModal = true;
    },
    onConfirmDelete(){
      this.deleteModal = false;
      postDeleteEtalase(this.itemEtalase).then(() => {
        this.$refs.table.refresh();
        this.$toast.open('The etalase successfully deleted.');
        this.getEtalase();
      });
      this.itemEtalase = null;
    },

  },
  async mounted(){
    //  await this.getEtalase2();
  }
};
</script>
<style lang="scss" scope>
@import "../../../assets/sass/paper/variables.scss";
#vehicles {
  
}
.text-danger{
  color:red;
}

</style>