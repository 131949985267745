'use strict'

// import dayjs from 'dayjs'
const dayjs = require('dayjs')

const VehicleFollowEntity = data => {
  data = data || {}

  return {
    vehicle_id: data.vehicle_id,
    imei: data.imei,
    vin: data.vin,
    latitude: data.latitude,
    longitude: data.longitude,
    status: data.status
  }
}

const GetStatus = data => {
  
  if (data.device_status && data.operate_status)
      return 'on'

  if (!data.device_status && !data.operate_status)
      return 'off'

  return 'idle'
  
}


const MonitoringListEntity = data => {
    data = data || {}

    return {
      device_id: data.device_id,
      device_name: data.device_name,
      operating: data.operating,
      latitude: data.latitude,
      longitude: data.longitude,
      imei: data.imei,
      thumb: data.vehicle_thumb,
      vehicle_name: data.vehicle_name,
      vehicle_id: data.vehicle_id,
      vin: data.vehicle_vin,
      device_status: data.device_status,
      operate_status: data.vehicle_status,
      obd: data.obd_status,
      status: GetStatus(data)
    }
}

const VehicleDetailModalEntity = data => {
  data = data || {}

  const meta = {
    latitude: data.log_latitude || 0.0,
    longitude: data.log_longitude || 0.0,
    speed: typeof data.log_speed === "undefined" ? '-' : data.log_speed,
    power_status: data.log_power_status ? 'On' : 'Off', // on/off
    operate_status: data.log_operate_status ? 'On' : 'Off', // on/off
    uuid: data.imei,
    idle_time: typeof data.du_idle_time === "undefined" ? '-' : data.du_idle_time,
    operate_time: typeof data.du_opt_time === "undefined" ? '-' : data.du_opt_time || 0,
    operate_mileage: typeof data.du_opt_mileage === "undefined" ? '-' : data.du_opt_mileage || 0,
    total_mileage: typeof data.log_total_mileage === "undefined" ? '-' : data.log_total_mileage,
    total_time: typeof data.log_total_time === "undefined" ? '-' : data.log_total_time,
    total_operate_time: typeof data.log_total_operate_time === "undefined" ? '-' : data.log_total_operate_time,
    total_operate_mileage: typeof data.log_total_operate_mileage === "undefined" ? '-' : data.log_total_operate_mileage,

    voltage: data.log_voltage || '-',
    start_counter: data.log_start_count || '-',
    engine: data.ut_engine,
    tire_count: data.ut_tire_count,
    torque: data.ut_torque,
    brand: data.b_brand || '-',
    series: data.ut_series || '-',
    year: data.ut_year || '-',
    fuel: data.ut_fuel || '-',

    signal: data.log_signal || '-',
    network_id: data.dev_network_provider_id,
    data_usage: data.log_data_usage || 0,
    temperature: typeof data.log_temperature === "undefined" ? '-' : `${data.log_temperature} C`,
  }

  if (meta.speed !== '-') {
    let speed = parseFloat(meta.speed) || 0
    meta.speed = (speed / 1000).toFixed(2)
    
  }

  if (meta.idle_time !== '-') {
    let idle = parseFloat(meta.idle_time) || 0
    meta.idle_time = (idle / 3600).toFixed(2)
  }

  if (meta.operate_time !== '-') {
    let opt_time = parseFloat(meta.operate_time) || 0
    meta.operate_time = (opt_time / 3600).toFixed(2)
  }

  if (meta.total_mileage !== '-') {
    let total_mil = parseFloat(meta.total_mileage) || 0
    meta.total_mileage = (total_mil / 1000).toFixed(2)
  }

  if (meta.total_time !== '-') {
    let total_time = parseFloat(meta.total_time) || 0
    meta.total_time = (total_time / 3600).toFixed(2)
  }

  if (meta.operate_mileage !== '-') {
    let operate_mil = parseFloat(meta.operate_mileage) || 0
    meta.operate_mileage = (operate_mil / 1000).toFixed(2)
  }

  if (meta.total_operate_time !== '-') {
    let operate = parseFloat(meta.total_operate_time) || 0
    meta.total_operate_time = (operate / 3600).toFixed(2)
  }

  if (meta.total_operate_mileage !== '-') {
    let operate = parseFloat(meta.total_operate_mileage) || 0
    meta.total_operate_mileage = (operate / 1000).toFixed(2)
  }
  
  let location = data.log_location_name || '-'

  return {
    vehicle_id: data.unit_id,
    vin: data.vin || '-',
    device_name: data.unit_id || '-',
    vehicle_type: data.vt_vechile_type_name || '-',
    image: data.image || null,
    location,
    status: GetStatus(data),
    tabs: [
      [
        [ "Vin", data.vin || '-' ],
        [ "Coordinate", `${meta.latitude}, ${meta.longitude}`],
        [ "Location", location ],
        [ "Power Status", meta.power_status ],
        [ "Operating Status", meta.operate_status],
        [ "UUID", data.imei || '-' ],
        [ "Speed", `${meta.speed} Km/h` ],
        [ "Today Idle Time", `${meta.idle_time} Hr` ],
        [ "Today Operating Time",  `${meta.operate_time} Hr`],
        [ "Today Operating Mileage", `${meta.operate_mileage} Km` ],
        [ "Total Time",  `${meta.total_time} Hr` ],
        [ "Today Mileage (odometer)", `${meta.total_mileage} Km` ],
        [ "Total Operating Time", `${meta.total_operate_time} Hr` ],
        [ "Total Operating Mileage", `${meta.total_operate_mileage} Km`],
      ],
      [
        [ "Voltmeter", meta.voltage ],
        [ "Start Stop Counter", meta.start_counter ],
        [ "Engine", meta.engine ],
        [ "Tire Count", meta.tire_count ],
        [ "Torque", meta.torque ],
        [ "Brand", meta.brand ],
        [ "Series", meta.series ],
        [ "Year", meta.year ],
        [ "Fuel", meta.fuel ],
      ],
      [
        [ "GSM Signal", meta.signal ],
        [ "IMEI", data.imei ],
        [ "Carrier/Mobile Network", meta.network_id ],
        [ "SIM Number", data.sim_number || '-' ],
        [ "Description", data.description || '-' ],
        [ "Data Usage", `${meta.data_usage} MB`],
        [ "Device Temperature", meta.temperature ]
      ]
    ]
  }
}


const ObdLabelInfoEntity = () => {
  return {
    fuel_level: [ "Fuel level", 0, '%'],
    fuel_engine: [ "Engine fuel", 0, 'L/H'],
    run_time: [ "Run time since engine start", 0, 'sec'],
    time_code_clear: [ "Time since code clearede", 0, 'sec'],
    distance: [ "Distance traveled since codes cleared", 0, 'km'],
    battery: [ "Battery voltage", 0, 'Volt'],
    engine_load: [ "Engine Load", 0, ,'%'],
    engine_temp: [ "Engine coolant temperature", 0, '&deg;C'],
    oil_temp: [ "Oil temperature", 0, '&deg;C' ],
    rpm: [ "Engine RPM", 0, 'RPM' ],
    throttle: [ "Throttle", 0, '%'],
  }
}


const OBDCodeEntity = data => {
  data = data || {}
  
  return {
    code: data.code,
    description: data.description,
    timestamp: dayjs(data.timestamp).format('YYYY-MM-DD hh:mm A'),
    error: data.error,
    diagnostic: data.diagnostic
  }
}


const OBDDetailEntity = (data) => {
  data = data || {}

  data.last_log = data.last_log || {}
  let codes = {}
  let timestamp = []
  let items = {}
  
  Object.keys(data.last_log).forEach(key => {
    timestamp.push(key)
    codes[key] = OBDCodeEntity(data.last_log[key])
  })

  timestamp = timestamp.sort()
  timestamp.forEach(key => {
    items[key] = codes[key]
  })

  let result = {
    vehicle_name: data.vehicle_name,
    device_name: data.device_name,
    image: data.image,
    imei: data.imei,
    meta: {
      fuel_level: data.fuel_level || 0,
      fuel_engine: data.engine_fuel || 0,
      run_time: data.engine_start || 0,
      time_code_clear: data.trouble_code_cleared || 0,
      distance: data.distance_code_cleared || 0,
      battery: data.battery_voltage || 0,
      engine_load: data.engine_load || 0,
      engine_temp: data.engine_coolant_temperature || 0,
      oil_temp: data.oil_temperature || 0,
      rpm: data.engine_rpm || 0,
      throttle: data.throttle || 0,
    },
    codes: items
  } 

  return result
}



module.exports = {
    MonitoringListEntity,
    VehicleDetailModalEntity,
    VehicleFollowEntity,
    ObdLabelInfoEntity,
    OBDDetailEntity
}