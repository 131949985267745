<template lang="pug">
transition(name="modal")
  div#listOutOfGeofence
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog.modal-lg.modal-dialog-scrollable(role="document")
          .modal-content

            .modal-body
              loader(:loading="loading")
                div.row
                  div.col-6
                    table.table.table-sm
                      thead
                        tr
                          th(width="60%") 
                            span.d-flex.pl-2 Driver
                          th(width="20%") Position

                      tbody
                        tr(v-if="drivers.length === 0")
                          td(colspan="2")
                            div.d-flex.justify-content-center.align-items-center(style="min-height: 200px;")
                              | No data found
                        tr(v-else v-for="(data, _) in drivers" :key="data.driver_id")
                          td 
                            span {{ data.driver }}
                          td
                            span.badge.badge-success.py-1.px-3(@click="setMapCenter(data)") view
                        
                  div.col-6.py-3
                    div.d-flex.justify-content-between.align-items-center.mb-3
                      p.m-0 
                        strong {{ selectedDriver ? selectedDriver.driver : 'Driver' }} Location
                      button.close(
                        type="button",
                        data-dismiss="modal",
                        aria-label="Close",
                      )
                        span(aria-hidden="true" @click="$emit('close')") &times;
                      
                    div.d-flex(style="height: 300px; position: relative")
                      
                      div.map-holder(v-if="!selectedDriver.position || !selectedDriver.position.lat")
                        | No selected driver

                      google-map(
                        ref="map"
                        :options="mapOptions"
                        :zoom="12"
                      )
                        google-map-marker(
                          :position="selectedDriver.position"
                        )

</template>

<script>
export default {
  props: ['drivers', 'loading', 'visible', 'timestamp'],
  data() {
    return {
      items: [],
      selectedDriver: {},
      mapOptions: {
        draggable: true,
        fullscreenControl: true,
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false
      },
    }
  },

  methods: {
    setMapCenter(data) {
      
      let position = {
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude)
      }
      this.position = position

      this.selectedDriver = data
      this.selectedDriver.position = position

      
      if (position.lat && position.lng) {
        this.$refs.map.$_getMap().then(map => {
          map.setCenter(position)
          map.setZoom(15)
        })
      }
    }
  },

  watch: {
    timestamp: {
      handler() {
        this.selectedDriver = {}
      }
    }
  }
  

}
</script>

<style lang="scss">
  #listOutOfGeofence {
    table {
      display: flex;
      flex-direction: column;

      th {
        color: #000000!important;
      }

      tbody {
        display: flex;
        flex-direction: column;
        width: 100%; 
        max-height: 400px; 
        overflow-y: auto;

        tr {
          display: flex;
          td:first-child {
            flex: 1;
          }
        }
        
      }
    }
    .badge {
      cursor: pointer;
    }

    .map-holder {
      background: #e2e2e2;
      width: 100%;
      height: 300px;
      color: #999999;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
    }
  }
</style>

