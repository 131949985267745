import Api from '@/utils/api'

export default {

  collectResult(result) {
    this.geofenceDevices = result
    this.isGeofenceOpen = false
  },

  togglePassword() {
    this.passwordType = this.passwordType == 'text' ? 'password' : 'text';
  },

  onClose() {
    this.selectUser = null

    if(this.isDirty) {
      this.showCancelModal = true;
      this.password = ''
    } else {
      this.doClose();
    }
  },

  doClose() {
    this.showCancelModal = false;
    this.$emit('close');
  },

  changePhone (event) {
    let val = event.target.value.trim()

    // It can only be positive integer or empty, and the rule can be modified according to the requirement.
    if (/^[0-9]\d*$|^$/.test(val)) {
        this.phone = val || ''
    } else {
        event.target.value = this.phone || ''
    }
  },
  
  onCreateNewUserClick () {
    this.selectedUser = { user_id : 'new_user', name : 'Create New User' };
    this.$refs.selectUser.toggle();
  },


  onAddOperator(newVal) {
    this.networkList = [ { name: newVal }, ...this.networkList  ];
    this.network_provider = newVal;
  },


  cleaningForm() {
    this.username = ''
    this.password = ''
    this.email = ''
    this.user_id = ''
    this.driver_id = ''
    this.description = ''
    this.phone = ''
    this.name = ''
    this.country = ''
    this.selectedDevices = []
    this.geofenceDevices = []
    this.selectedUser = null
    this.geofence = false
    this.$emit('setFormTypeEdit', false)
  },

  
  doSave() {
    
    let payload = {
      user_id: this.user_id === 'new_user' ? null : this.user_id,
      devices: {},
      description: this.description,
      phone: this.phone,
      name: this.name,
      geofence: this.geofence
    }

    if (this.user_id === 'new_user') {
      payload = {...payload, 
        username: this.username,
        email: this.email,
        country: this.country,
        password: this.password
      }
    }

    this.selectedDevices.forEach(item => {
      payload.devices[item.id] = item
    })

    if (this.geofence && !this.geofenceDevices.length)
      return this.$toast.error('Geofence is required')


    if (this.loading) return false
    let callback = this.isEdit ? 'update' : 'create'
    
    this.loading = true;
    Api.driver[callback](payload)
      .then(response => {
        if (response.status !== 200)
          return this.$toast.error('Failed to add new driver')
        
        let message = response.data.message
        this.$toast.success(message)
        this.cleaningForm()
        
        this.table.refresh()
        this.$emit('close')
      })
      .catch(e => {
        let error = e.response ? e.response.data : e
        this.$toast.error(error.message)
      })
      .finally(() => {
        this.loading = false
      })
  },


  fetchUserList() {
    return Api.driver.user_list()
      .then(response => {
        let users = response.data.result.data.map(user => {
          return {
            user_id: user.user_id,
            name: user.name
          }
        })
        this.users = users
      })
      .catch(e => {
        let error = e.response ? e.response : e
        this.$toast.error(error.message)
      })
      .finally(() => {
        this.listDeviceLoading = false
      })
  },
  

  fetchDeviceUnitList() {
    if (this.listDeviceLoading) return false

    this.listDeviceLoading = true
    Api.device_unit.list()
      .then(response => {

        let devices = response.data.result.data.map(device => {
          return {
            id: device.device_unit_id,
            device_id: device.device_id,
            name: device.device_name
          }
        })
        this.devices = devices

      })
      .catch(e => {
        let error = e.response ? e.response : e
        this.$toast.error(error.message)
      })
      .finally(() => {
        this.listDeviceLoading = false
      })

  },

  toggleGeofence() {
    this.geofence = !this.geofence
  },

  onOpenGeofence() {
    this.isGeofenceOpen = true
  },

}
