<template lang="pug">
div
  //- vehicle-form-modal(v-if="showVehicleEdit" :vehicle="vehicle" @close="showVehicleEdit = false" @success="onVehicleChanged")

  sidebar-modal(:visible="show" :dataOrder="dataOrder" @close="show = false") 
  //- b-sidebar(id="sidebar-right" :visible="show" right shadow style="")

  card(title='Order List')
    matador-search#search-box(style="width:100%" placeholder='Search Rental' autocomplete='off')
    .d-flex.mb-2.mt-3
      .matador-tab-table
        a.font-weight-bolder.w-100.text-center.py-3.px-3(
          href="javascript:;",
          :class="{ active: modalTabIndex == 0 }",
          @click="chooseTableMenu('all')"
        ) All Order
        a.font-weight-bolder.w-100.text-center.py-3.px-3(
          href="javascript:;",
          :class="{ active: modalTabIndex == 1 }",
          @click="chooseTableMenu('pre-payment')"
        ) Pre-Paid
        a.font-weight-bolder.w-100.text-center.py-3.px-3(
          href="javascript:;",
          :class="{ active: modalTabIndex == 2 }",
          @click="chooseTableMenu('post-payment')"
        ) Post-Paid
    div.d-flex.mt-4
        keep-alive
          .table-responsive
            bs-table(
              fixed
              ref="table"
              :columns="table.columns",
              :options="tableOptions",
              :data="orderData",
              @on-post-body="vueFormatterPostBody"
              @on-search="onEvent"
            )
  confirmation-modal(
    v-if="deleteModal"
    @close="deleteModal = false" 
    @confirm="onConfirmCancelOrder" 
    title="Cancel Order"
    confirm-text="Yes, Cancel this Order"
  )
    p.m-0
      | Do yo want to cancel this order?
    //- dialog-confirmation(v-if="showDialogOrder" @close="showDialogOrder=false" @confirm="confirmOrder()")

        

</template>
<script>
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import tableMixin from "@/mixins/table";
import { mapGetters } from 'vuex'
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import Dropdown from "@/components/Dropdown.vue";
import SidebarModal from "@/components/Matador/Rental/SidebarModal.vue";
import DialogConfirmation from "@/components/Matador/Setting/DialogConfirmation.vue";
import dayjs from 'dayjs';

import { getOrderList,getDetailOrder,postCancelOrder } from "@/utils/api";

export default {
  mixins: [tableMixin],

  components: {
    ConfirmationModal,
    DialogConfirmation,
    SidebarModal,
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    Plus
  },

  data() {
    return {
      deleteModal:false,      
      tableMenu:'all',
      modalTabIndex: 0,
      showDialogOrder: false,
      dataOrder: {},
      show: true,
      order_id: null,
      customer_name: null,
      order_date: null,
      payment: null,
      image_vehicle: null,
      vehicle_name: null,
      duration: null,
      fee: null,
      qty: null,
      total: null,
      image_user: null,
      showOrderList: false,
      organizationList: [],
      position: "",
      showAdd: false,
      orderData: [],
      perPage: 10,
      table: {
        columns: [
          // {
          //   field: "",
          //   title: "",
          //   formatter: (value, row) => {
          //     return this.vueFormatter({
          //       template:
          //       `<div class="form-check">
          //         <input type="checkbox" value="" id="flexCheckDefault" style="margin-left:-15%" @change="onChecked(row)">
          //       </div>`,
          //       data: { row, value },
          //       methods: {
          //         onChecked : this.clickChecked
          //       },
          //       components : {
          //       }
          //     });
          //   },
          //   sortable: false,
          //   searchable: false,
          //   widthUnit: '%',
          //   width: '7'
          // },
          {
            title: "Customer",
            field: "customer",
            searchable: true,
            sortable: true,
            widthUnit: "em",
            width: "6"
          },
          {
            title: "Order Date",
            field: "order_date",
            sortable: true,
            searchable: true,
            widthUnit: "em",
            width: "7",
            formatter: (value, key, item) => {
              var date = this.dateFormat(value);
              return date;
            }
          },

          {
            field: "start",
            title: "Start",
            sortable: true,
            searchable: true,
            widthUnit: "em",
            width: "7",
            formatter: (value, key, item) => {
              var date = this.formatDateTime(value);
              return date;
            }
          },
          {
            title: "Return",
            field: "finish",
            sortable: true,
            searchable: true,
            widthUnit: "em",
            width: "7",
            formatter: (value, key, item) => {
              var date = this.formatDateTime(value);
              return date;
            }
          },

          {
            field: "payment_plan",
            title: "Payment Plan",
            sortable: true,
            searchable: true,
            widthUnit: "em",
            width: "8"
          },

          {
            title:"Payment Status",
            field:"payment_status",
            searchable:false,
            widthUnit:'em',
            width:'8',
            sortable:true,
            formatter:(value,row)=>{
            return this.vueFormatter({
              template:
              `<div>
                <p v-show="value=='Unpaid'" class="matador-h5 text-warning">{{value}}</p>
                <p v-show="value=='Paid'" class="matador-h5 text-primary">{{value}}</p>
                <p v-show="value=='Canceled'" class="matador-h5 text-danger">{{value}}</p>
              </div>`,
              data: { value,row },
            });
            }
          },
          {
            field: "status",
            title: "Status",
            align: "center",
            sortable: true,
            searchable: true,
            widthUnit: "em",
            width: "8",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `<div>
                <p v-if="value=='Order Created'" class="ready">Order Created</p>
                <p v-if="value=='Order Confirmed'" class="ready">Order Confirmed</p>
                <p v-if="value=='Order Ready'" class="ready">Order Ready</p>
                <p v-if="value=='On Rent'" class="ready">On Rent</p>
                <p v-if="value=='End Soon'" class="endsoon">End Soon</p>
                <p v-if="value=='Time Up'" class="timeout">Time Up</p>                
                <p v-if="value=='Returned'" class="done">Order Done</p>
                <p v-if="value=='Order Canceled'" class="timeout">Order Canceled</p>

              </div>`,
                data: { value, row }
              });
            }
          },
          {
            field: "",
            title: "",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `
                <div>
                  <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
                    <li class="p-3 matador-text-03" @click="onDetail(row)" aria-controls="sidebar-right"  :aria-expanded="show"> Order Details </li>
                    <li class="p-3 matador-text-03" v-on:click="onCancel(row)">Cancel Order</li>
                  </dropdown>
                </div>
              `,
                data: { row, value, show: this.show },
                methods: {
                  onDetail: this.clickDetail,
                  onCancel: this.clickCancel
                },
                components: {
                  Dropdown
                }
              });
            },
            sortable: false,
            searchable: false,
            widthUnit: "em",
            width: "5"
          }
        ]
      }
    };
  },
  computed: {
    tableOptions() {
      return {
        ajax: this.fetchOrderList,
        search: true,
        pagination: true,
        pageSize: this.perPage,
        searchSelector: "#search-box",
        showSearchButton: true,
        sortable: true,
        paginationHAlign: "center",
        paginationDetailHAlign: "right",
        paginationParts: ["pageInfo", "pageList"],
        classes: ["table", "table-hover"]
      };
    },
    ...mapGetters({
      auth:'gettersAuth'
    })
    
  },
  methods: {
    
    orderListDetail(){
      if(this.$route.query.order_id){
         this.fetchDetailOrder(this.$route.query.order_id)
         this.clickDetail(this.$route.query.order_id)
      }
    },
    chooseTableMenu(params){
      if(params=='all'){
        this.modalTabIndex=0;
        this.tableMenu='all'
        this.$refs.table.refresh();
      }else if(params=='pre-payment'){
        this.modalTabIndex=1;
        this.tableMenu='pre-payment'
        this.$refs.table.refresh();
      }else if(params=='post-payment'){
        this.modalTabIndex=2;
        this.tableMenu='post-payment'
        this.$refs.table.refresh();
      }
    },
    clickShow() {},
    clickDelete() {},
    clickChecked(row) {
      this.showDialogOrder = true;
    },
     dateFormat(params){
      var date=dayjs(params).format('DD/MM/YYYY')
      return date
    },
    formatDateTime(params){
      var date=dayjs(params).format('DD/MM/YYYY HH:mm')
      return date
    },
    async onConfirmCancelOrder(){
      try {
        let obj={
          order_id:this.order_id,
          user_id:this.auth.user_id,
        }
        let response= await postCancelOrder(obj)
        if (response.data.code == 200) {
          this.$toast.open('Cancel Order successfully.');
          this.$refs.table.refresh();
        }else{
          this.$toast.error('Failed Cancel Order.');
          this.$refs.table.refresh();
        }
      } catch (error) {
        
      }
      this.deleteModal=false;
    },
    mataUang(prefix, req) {
      if (req) {
        var number_string = req.toString();
        var sisa = number_string.length % 3;
        var rupiah = number_string.substr(0, sisa);
        var ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return prefix + rupiah;
      } else {
        return req;
      }
    },
    async fetchOrderList(params) {
      try {
        getOrderList({sort: this.tableMenu}).then(
          ({data: {code,status,result,message}}) =>{
            // this.dataOrder=result;
            result.map(item => {
              return item
            })
            params.success(result);
          }
        );
      } catch (error) {
        
      }
    },
        fetchDetailOrder(params){
        getDetailOrder({order_id:params}).then(({ data: { code, result, message } }) => {
           this.dataOrder = result
      });
    },
    clickAdd() {},
    clickDetail(order) {
      this.show = false;      
      this.dataOrder = order;
      this.show = true;
    },
    clickCancel(row) {
      this.order_id=row.order_id
      this.deleteModal=true;
    },
    onEvent(event) {
      console.log(event);
    }
  },
  watch: {
    pageSize: function(newVal) {
      this.$refs.table.refreshOptions();
    }
  },
  mounted() {
    // reset search if clear
    var that = this;
    window.$(".search input").on("search", function() {
      if (!window.$(this).val()) {
        that.$refs.table.resetSearch();
      }
    });
    this.orderListDetail()
  }
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem;
}
.tab-filter {
  min-width: 200px;
  border-right: 2px solid #eaeaec;
  padding-right: 0.5rem;
}
.badge-circle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;

  text-align: center;

  font: 10pt Arial, sans-serif;
  font: 10pt Arial, sans-serif;
}
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.ready {
  color: #0cca4a !important;
}
.endsoon {
  color: #fc9905;
}
.timeout {
  color: #992024;
}
.done {
  color: #005bff;
}
</style>
