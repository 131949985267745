<template lang="pug">
div
  div.card.p-3
    .card-header
      h5.font-weight-bold.card-title
        | {{title}}

    .card-body
      div.pb-3
        div.d-flex.justify-content-between
          b-button-group#main-nav-tab
            router-link(
              v-for="(tab, _) in Object.keys(TabOption)" 
              :key="tab.key"
              :to="TabOption[tab].path"
              class="btn"
            ) {{ TabOption[tab].title }}
      router-view
</template>

<script>

import { TabOption } from './entity'

const defaulTab = TabOption.DRI

export default {
  data() {
    return {
      title: defaulTab.title,
      selectedTab: defaulTab,
      TabOption
    }
  },
}
</script>

<style lang="scss" scoped>
  @import 'driver_page.scss';
</style>
