<template lang="pug">
transition(name="modal")
  .modal-mask(@click="onModalClick")
    #geo-form.modal-wrapper
      .modal-dialog(style="width: 1180px;max-width: 95%;" role="document")
        .modal-content
          .modal-body.p-0
            google-map#map(
              ref="geofenceMap"
              :zoom="12"
              :options="mapOptions"
            )
              google-map-polygon(
                :paths="lines[0].path",
                :strokeColor="lines[0].strokeColor"
                :fillColor="lines[0].fillColor"
                :fillOpacity="0.7"
              )

            .buttons(v-if="!drawMode")
              button.btn.btn-light(@click="drawMode = true")
                img(:height="16" :width="16" src="@/assets/img/pen.png")
                span {{ hasGeofence ? 'Edit Geofence' : 'Add Geofence' }}

            //- .buttons(v-if="geofence && !drawMode")
            //-   button.btn.btn-light.btn-add(@click="drawMode = true")
            //-     img(:height="16" :width="16" src="@/assets/img/pen.png")
            //-     span Edit Geofence

            //- .buttons(v-if="!drawMode && !geofence")
            //-   button.btn.btn-light.btn-add(@click="drawMode = true")
            //-     img(:height="16" :width="16" src="@/assets/img/pen.png")
            //-     span Add Geofence
              
            .buttons(v-if="drawMode && lines[0].path.length >= 3 && !showForm")
              button.btn.btn-danger.btn-cancel(@click="lines[0].path = []")
                span Clear Pointer

              button.btn.btn-success.btn-cancel(@click="showForm = true")
                h4.my-0 ✓
                span {{ geofence ? 'Confirm Geofence' : 'Add Geofence' }}

            geofence-form(
              :visible="showForm" 
              :colors="colors" 
              @switchColor="onSwitchColor" 
              @cancel="close"
              :color="lines[0].fillColor" 
              :paths="lines[0].path"
              :geofence="geofence"
              :devices="devices"
            )

            button.close-map(@click="$emit('close')")
              img(:height="20" :width="20" src="@/assets/img/close.png")
</template>

<script>

import GeofenceForm from './GeofenceForm.vue';
import { GeofenceColors } from '@/utils/colors';
import { InitPolygon, InitMarker } from '@/utils/map'

const lines = [{
  path: [],
  geodesic: true,
  strokeColor: GeofenceColors[1],
  fillColor: GeofenceColors[1],
}]

export default {

  props: ['title', 'confirmText', 'geofence', 'devices', 'geofenceTable'],
  
  data(){
    return {
      lines,
      hasGeofence: [],
      colors: GeofenceColors,
      drawMode: false,
      showForm: false,
      markersList: []
    }
  },
  components: {
    GeofenceForm
  },
  watch: {
    geofence: {
      immediate: true,
      handler: function(generator) {

        this.lines[0].path = []
        if (generator) {
          
          this.lines[0].path = generator.coordinate.map(([lat, lng]) => ({ lat, lng }));
          this.lines[0].fillColor = generator.color;
          this.lines[0].strokeColor = generator.color;

          this.hasGeofence = !!this.lines[0].path.length 
          
          if (!!this.lines[0].path.length) {
            const self = this
            initCallback(1000, 10, (done) => {
                InitPolygon(self.lines[0], self.$refs.geofenceMap)
                if (self.$refs.geofenceMap) done()
            })
          }
        }
      }
    }
  },

  computed: {
    mapOptions(){
      return {
        draggable: true,
        fullscreenControl: false,
        draggableCursor: this.drawMode && !this.showForm ? "url(/icons/plus-thin.png) 12 12, auto" : null,
      }
    }
  },

  
  methods: {

    isEdit() {
      if (this.geofencePath && this.drawMode) {
        return true
      }

      return false
    },

    initMap() {
      const self = this
      if (this.geofence.latitude && this.geofence.longitude) {
        let position = {
          lat: this.geofence.latitude,
          lng: this.geofence.longitude
        }
        
        initCallback(1000, 10, (done) => {
          InitMarker([position], self.$refs.geofenceMap, ({map}) => {
            map.setCenter(position)
            map.setZoom(map.zoom + .5);

            setTimeout(() => done(), 1000)
          })
        })

      }

      initCallback(1000, 10, done => {
        self.$refs.geofenceMap.$_getMap().then((map) => {
          window.google.maps.event.addListener(map, 'click', ({ latLng: { lat, lng } }) => {
            self.drawMode && !self.showForm && self.lines[0].path.push({lat: lat(), lng: lng()});
          })
        });
        if (self.$refs.geofenceMap) done()
      })
      
    },

    onSwitchColor(color){
      this.lines[0].strokeColor = color;
      this.lines[0].fillColor = color;
    },

    onAddDevice(){
      this.showForm = true;
    },

    onModalClick(event){
      if(event.target.id === 'geo-form'){
        this.$emit('close');
      }
    },

    close() {
      this.geofenceTable.refresh()
      this.$emit("close")
      this.showForm = false
    }

  },


  mounted(){
    this.initMap();
  }
}
</script>

<style lang="scss">
#geo-form {
  .modal-content {
    border-radius: 10px;
    .modal-body {
      min-height: 200px;
      position: relative;
      overflow: hidden;
      #map {
        height: 600px;
        max-height: 95%;
        .map-view {
          border-radius: 10px;
        }
      }
      .buttons {
        position: absolute;
        bottom: 15px;
        width: 320px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        margin-left: -160px;
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 25px;
          padding-right: 25px;
          img, h4 {
            margin-right: 5px;
          }
          margin: 0 5px;
          &.btn-danger, &.btn-success {
            border-width: 0;
          }
        }
      }
      .close-map {
        position: absolute;
        top: 7px;
        right: 10px;
        border: none;
        background-color: transparent;
        padding: .5rem;
      }
      .btn-add-device {
        position: absolute;
        top: 17px;
        right: 20px;
      }
    }
  }
}
</style>