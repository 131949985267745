<template lang="pug">
div#rentList
  sidebar-modal-customer(:visible="show" :dataOrder="dataOrder" @close="show = false") 
  card(title="Rent List")
    .mb-3
      matador-search#search-box(style="flex-grow:0 !important; width:100%; marginRight:20px" v-model="search" placeholder='Search Rental By Series or Vehicle Type' autocomplete='off' )
      .d-flex.mt-3
        .matador-tab-table
          a.font-weight-bolder.w-100.text-center.py-3.px-3(
            href="javascript:;",
            :class="{ active: modalTabIndex == 0 }",
            @click="chooseTableMenu('all')"
          ) All Order
          a.font-weight-bolder.w-100.text-center.py-3.px-3(
            href="javascript:;",
            :class="{ active: modalTabIndex == 1 }",
            @click="chooseTableMenu('pre-payment')"
          ) Pre-Paid
          a.font-weight-bolder.w-100.text-center.py-3.px-3(
            href="javascript:;",
            :class="{ active: modalTabIndex == 2 }",
            @click="chooseTableMenu('post-payment')"
          ) Post-Paid
    
      .d-flex.mt-4
        keep-alive
          .table-responsive
            bs-table.m-0(
              ref="table"
              :columns="table.columns",
              :options="tableOptions",
              :data="dataRentList"  
              @on-post-body="vueFormatterPostBody"
              @on-search="onEvent"
            )
  confirmation-modal(
    v-if="deleteModal"
    @close="deleteModal = false" 
    @confirm="onConfirmCancelOrder" 
    title="Cancel Order"
    confirm-text="Yes, I'm Cancel this Order"
  )
    p.m-0
      | Do yo want to cancel this order?

</template>
<script>
import tableMixin from "@/mixins/table";
import { getRentList,postCancelOrder,getDetailOrder} from "@/utils/api";
import { mapGetters } from 'vuex'
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import DialogConfirmation from "@/components/Matador/Setting/DialogConfirmation.vue";
import Dropdownetalase from "@/components/Dropdownetalase.vue";
import SidebarModalCustomer from "@/components/Matador/Rental/SidebarModalCustomer.vue";
import dayjs from 'dayjs';
export default {
  mixins: [tableMixin],
  components:{
    ConfirmationModal,
    Dropdownetalase,
    DialogConfirmation,
    SidebarModalCustomer,
  },
  data() {
    return {
      show:false,
      dataOrder:{}, 
      order_id:null,
      deleteModal:false,
      modalTabIndex:0,
      tableMenu:'all',
      loading:false,
      dataRentList:[],
      search:'',
      currentUser: this.$cookies.get('user_id'),
      table:{
        columns:[
          {
            title:"#Order",
            field:"order_id",
            searchable:true,
            widthUnit:'em',
            width:'6',
            sortable:true,
            class:"matador-text-03",
          },
          {
            title:"Order Date",
            field:"order_date",
            searchable:true,
            widthUnit:'em',
            width:'8',
            sortable:true,
            class:"matador-text-03",
            formatter:(value,row)=>{
              return this.vueFormatter({
                template:
                `<div>
                  <p >{{onFormatDate(value)}}</p>
                </div>`,
                data:{
                  row,
                  value,
                  },
                methods:{
                  onFormatDate : this.formatDate,
                },
              });
            }
          },
          {
            title:"Start",
            field:"start",
            searchable:true,
            widthUnit:'em',
            width:'8',
            sortable:true,
            class:"matador-text-03",
            formatter:(value,row)=>{
              return this.vueFormatter({
                template:
                `<div>
                  <p >{{onFormatDateTime(value)}}</p>
                </div>`,
                data:{
                  row,
                  value,
                  },
                methods:{
                  onFormatDateTime : this.formatDateTime,
                },
              });
            }
          },
          {
            title:"Return",
            field:"finish",
            searchable:true,
            widthUnit:'em',
            width:'7',
            sortable:true,
            class:"matador-text-03",
            formatter:(value,row)=>{
              return this.vueFormatter({
                template:
                `<div>
                  <p >{{onFormatDateTime(value)}}</p>
                </div>`,
                data:{
                  row,
                  value,
                  },
                methods:{
                  onFormatDateTime : this.formatDateTime,
                },
              });
            }
          },
          {
            title:"Total",
            field:"price",
            searchable:true,
            widthUnit:'em',
            width:'6',
            sortable:true,
            class:"matador-text-03",
            formatter:(value,row)=>{
              return this.vueFormatter({
                template:
                `<div>
                  <p >Rp {{onFormatMataUang(value)}},-</p>
                </div>`,
                data:{
                  row,
                  value,
                  },
                methods:{
                  onFormatMataUang : this.mataUang,
                },
              });
            }
          },
          {
            title:"Payment Status",
            field:"payment_status",
            searchable:false,
            widthUnit:'em',
            width:'9',
            sortable:true,
            formatter:(value,row)=>{
            return this.vueFormatter({
              template:
              `<div>
                <p v-show="value=='Unpaid'" class="matador-h5 text-warning">{{value}}</p>
                <p v-show="value=='Paid'" class="matador-h5 text-primary">{{value}}</p>
                <p v-show="value=='Canceled'" class="matador-h5 text-danger">{{value}}</p>
              </div>`,
              data: { value,row },
            });
            }
          },
          {
            title:"Order Status",
            field:"status",
            searchable:false,
            widthUnit:'em',
            width:'12',
            sortable:true,
            formatter:(value,row)=>{
            return this.vueFormatter({
              template:
              `<div>
                <p v-show="value=='Order Created'" class="matador-h5 text-warning">{{value}}</p>
                <p v-show="value=='Order Done'" class="matador-h5 matador-text-light-blue">{{value}}</p>
                <p v-show="value=='On Rent'" class="matador-h5 matador-text-green">{{value}}</p>
                <p v-show="value=='Order Canceled'" class="matador-h5 text-danger">{{value}}</p>
                <p v-show="value=='Order Confirmed'" class="matador-h5 text-success">{{value}}</p>
              </div>`,
              data: { value,row },
            });
            }
          },
           {
          field:"",
          title:"",
          formatter:(value,row)=>{
            return this.vueFormatter({
              template:
                `<div>
                  <dropdownetalase class="dropdown-toggle-no-caret" tag="a" icon="mdi mdi-dots-vertical h4 text-dark m-0">
                    <li class="p-3 matador-text-03" style="borderBottom:1px solid #F5F5F5 !important;" @click="onDetail(row)" >Order Details</li>
                    <li class="p-3 matador-text-03" style="borderBottom:1px solid #F5F5F5 !important; " @click="onShow(row)">View Invoice </li>
                    <li class="p-3 matador-text-03" style="borderBottom:0.1px solid #F5F5F5 !important;" @click="onCancel(row)" >Cancel Order</li>
                  </dropdownetalase>
                </div>`,
              data:{
                row,
                value,
                checked:this.checked
                },
              methods:{
                onShow : this.onShowInvoice,
                onDetail: this.clickDetail,
                onCancel : this.onModalCancel,
          
              },
              components:{
                Dropdownetalase
              }
            })
          },
            sortable: false,
            searchable: false,
            widthUnit: 'em',
            width: '2'
        }
        ]
      }
    }
  },
  watch:{
    search: {
          handler: function(newVal){
            this.currentPage = 1;
          }
        },
      },
  computed:{
    tableOptions() {
      return {
          ajax: this.getDataRentList,
          search: true,
          pagination: true,
          pageSize: this.perPage,
          searchSelector: "#search-box",
          showSearchButton: true,
          sortable: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: [ "pageInfo", "pageList"],
          classes: ["table", "table-hover"],
        }
    },
    ...mapGetters({
      auth:'gettersAuth'
    })
  },
  methods: {
    clickDetail(order) {
      this.dataOrder = order;
      this.show = true;
    },    
    rentListDetail(){
      if(this.$route.query.order_id){
         this.fetchDetailOrder(this.$route.query.order_id)
         this.clickDetail(this.$route.query.order_id)
      }
    },    
    fetchDetailOrder(params){
        getDetailOrder({order_id:params}).then(({ data: { code, result, message } }) => {
          this.total=0
          result.unit.forEach((value, index) => {
            this.total+= value.total_price
          });
          result.timeline_status = result.timeline_status.reverse()
          this.dataOrder = result
      });
    },    

    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },

    formatDate(params){
      var date=dayjs(params).format('DD/MM/YYYY')
      return date
    },
    formatDateTime(params){
      var date=dayjs(params).format('DD/MM/YYYY HH:mm')
      return date
    },
    onShowInvoice(row){
      this.$router.push('/invoice/'+row.order_id)
    },
    onModalCancel(row){
      this.order_id=row.order_id
      this.deleteModal=true;
    },
    async onConfirmCancelOrder(){
      try {
        let obj={
          order_id:this.order_id,
          user_id:this.auth.user_id,
        }
        let response= await postCancelOrder(obj)
        if (response.data.code == 200) {
          this.$toast.open('Cancel Order successfully.');
          this.$refs.table.refresh();
        }else{
          this.$toast.error('Failed Cancel Order.');
          this.$refs.table.refresh();
        }
      } catch (error) {
        
      }
      this.deleteModal=false;
    },
    onEvent(event) {
    },
    chooseTableMenu(params){
      if(params=='all'){
        this.modalTabIndex=0;
        this.tableMenu='all'
        this.$refs.table.refresh();
      }else if(params=='pre-payment'){
        this.modalTabIndex=1;
        this.tableMenu='pre-payment'
        this.$refs.table.refresh();
      }else if(params=='post-payment'){
        this.modalTabIndex=2;
        this.tableMenu='post-payment'
        this.$refs.table.refresh();
      }
    },
    getDataRentList(params){
      try {
        
        let obj ={
          user_id:this.currentUser,
          sort: this.tableMenu
        }
        getRentList(obj).then(
          ({data: {code,status,result,message}}) =>{
            this.dataRentList=result;
            result.map(item => {
              return item
            })
            params.success(result);
          }
        );
      } catch (error) {
        
      }
    }
  },
  mounted() {
    this.rentListDetail()

  },
}
</script>
<style lang="">
  
</style>