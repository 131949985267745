<template lang="pug">
div(style="min-width: 770px; min-height: 350px")
  .row.px-2
    .col-12.col-md-4.px-2
      b-form-group(label="Generator Name")
        select.form-control(
          :disabled="filterLoading"
          style="background: none!important"
          @change="onSelectDevice"
        )
          option {{filterLoading ? 'Loading...' : 'Please select a device'}}
          option(v-for="dev in deviceNames" :value="dev.id") {{ dev.name }}
        //- autocomplete(
        //-   :options="deviceNames"
        //-   :selected="onSelectDevice"
        //-   :disabled="filterLoading"
        //-   :maxItem="1000"
        //-   :placeholder="filterLoading ? 'Loading...' : 'Please select a device'"
        //- )
  
    .col-12.col-md-3.px-2
      b-form-group(label="Start Date")
        datepicker(
          input-class="form-control datepicker" 
          @selected="onSelectStartDate" 
          name="startDate" 
          ref="startDate"
          placeholder="Select start date"
        )

    .col-12.col-md-3.px-2
      b-form-group(label="End Date")
        datepicker(
          input-class="form-control datepicker" 
          @selected="onSelectEndDate" 
          name="endDate" 
          ref="endDate"
          placeholder="Select end date"
        )

    .col-12.col-md-2.px-2.d-flex.align-items-center
      div.pt-1(style="width: 100%")
        matador-button.mt-2.py-1.px-4(
          size="lg" 
          style="width: 100%; border: 3px solid #1f548b;"
          :disabled="!isClickable"
          @click="onGenerate"
        ) {{ mainReportLoading ? "Loading.." : 'Generate' }}
    
  .row.mt-3(v-if="isReportOpen")
    div.col-12.pb-3
      .accordion-header.px-3.py-1(@click="mainExpanded = !mainExpanded")
        .d-flex.align-items-center.justify-content-between
          p.m-0 Overview
          a.text-dark.h3.m-0(href="javascript:;")
            chevron-up(v-if="mainExpanded")
            chevron-down(v-if="!mainExpanded")

      div.accordion-body(v-if="mainExpanded")
        div.p-3
          .card.mt-2
            .card-body(style="padding: 20px 35px;")
              main-report(
                :loading="mainReportLoading" 
                :mainReport="mainReport" 
                :startDate="selectedStartDate" 
                :endDate="selectedEndDate"
              )

    div.col-12.pb-3(style="position: relative")
      div(style="position: absolute;top: 10px;right: 70px;color: #fff; cursor: pointer;")
        <i @click="onLoadGraph" class="fa fa-refresh"></i>
      .accordion-header.px-3.py-1(@click="toggleLoadGraph" )
        .d-flex.align-items-center.justify-content-between
          p.m-0 Graph
          a.text-dark.h3.m-0(href="javascript:;")
            chevron-up(v-if="graphExpanded" style="color: #ffffff")
            chevron-down(v-if="!graphExpanded")
      
      div.accordion-body(v-if="graphExpanded")
        loader(:loading="graphReportLoading")
          div.row.p-3(v-for="(labels) in graphsLine")
            div.col-6(v-for="(label) in labels")
              div
                .card
                  .card-body
                    line-chart(
                      :chart-data="chart(label, 'data')" 
                      :options="chart(label, 'options')" 
                      :stacked="false"
                      :height="150" 
                      :width="null"
                    )
</template>

<script>
// import { Card, StatsCard, ChartCard, PaperTable } from "@/components/index";
import { ChartCard } from "@/components/index";

import Datepicker from "vuejs-datepicker";
import { Generator } from '../../libs/repository'
import { GetError } from '../../utils'

import { LineChartFactory } from '../../utils/chart'
import { GraphReportEntity } from '../../entity'
import MainReport from './MainReport.vue'
import days from 'dayjs'

import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";

// import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";

import Api from '@/utils/api'

const Repository = Generator.init({ isDummy: false })

export default {
  name: "ReportTab",

  components: {
    ChartCard,
    Datepicker,
    MainReport,
    ChevronDown,
    ChevronUp,
    LineChart
  },

  data() {
    return {
      mainReportLoading: false,
      mainExpanded: true,
      mainReport: null,
      filterLoading: false,

      graphReportLoading: false,
      graphExpanded: false,
      graphReport: GraphReportEntity(),

      isReportOpen: false,
      
      deviceNames:[],
      reportTypes: [],
      selectedDevice: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedType: {
        id: 'all'
      },
      graphsLine: [
        ['speed','oil'],
        ['fuel','temperature'],
        ['alt_volt', 'battery'],
        ['freq', 'operate']
      ],
      graphTitles: {
        fuel: 'Fuel Level',
        speed: 'Engine Speed',
        alt_volt: 'Charge Alternator Voltage',
        oil: 'Oil Pressure',
        temperature: 'Coolant Temperature',
        battery: 'Battery Voltage',
        freq: 'Generator Frequency',
        operate: 'Daily Operating Time'
      }
    }
  },

  

  methods: {
    onSelectDevice(e) {
      this.selectedDevice = {id: e.target.value}
    },

    onSelectType(type) {
      this.selectedType = type
    },

    onSelectStartDate(start) {
      this.selectedStartDate = days(start).format('YYYY-MM-DD')
    },

    onSelectEndDate(end) {
      this.selectedEndDate = days(end).format('YYYY-MM-DD')
    },

    chart(prop, key) {
      
      let data = this.graphReport[prop]
      let options = {
        prop,
        title: this.graphTitles[prop]
      }

      if (prop === 'operate') {
        options.labels = {top: 'Minute'}
      } 
      
      let result = LineChartFactory(data, options)

      if (prop==="fuel")
        console.log({result})

      return result[key]
    },


    buildPayload() {
      return  {
        device_id: this.selectedDevice ? this.selectedDevice.id : null,
        start_date: this.selectedStartDate,
        end_date: this.selectedEndDate
      }
      
    },

    onGenerate() {
      
      let payload = this.buildPayload()

      if (this.mainReportLoading) return false
      this.graphExpanded = false

      if (!payload.device_id || !payload.start_date || !payload.end_date) 
        return false

      this.isReportOpen = true
      this.mainReportLoading = true

      Repository.getMainReport(payload)
        .then(result => { 
          this.mainReport = result
        })
        .catch(e => {
          this.$toast.error(GetError(e, "Failed to collect report data"))
        })
        .finally(() => {
          this.mainReportLoading = false
        })
    },
    
    toggleLoadGraph() {
      this.graphExpanded = !this.graphExpanded
      this.onLoadGraph()
    },

    onLoadGraph() {
      
      let payload = this.buildPayload()
      if (!payload.device_id 
          || !payload.start_date 
          || !payload.end_date 
          || !this.graphExpanded 
          || this.graphReportLoading
        )
        return false
      
      this.graphReportLoading = true
      payload.fields = Object.keys(this.graphTitles).join(',')

      Repository.getGraphReport(payload)
        .then(result => {
          this.graphReport = result
        })
        .catch(e => {
          this.$toast.error(GetError(e, "Failed to collect report data"))
        })
        .finally(() => {
          this.graphReportLoading = false
        })
    }
  },

  computed: {
    isClickable() {
      return (this.selectedStartDate && this.selectedEndDate && this.selectedDevice && this.selectedDevice.id ) ? true : false
    }
  },

  mounted() {
    this.filterLoading = true

    const responseHandler = response => {
      let data = response.data
      let rows = data.result.data || []

      this.deviceNames = []
      rows.forEach(item => {
        if (!!item.devices.length) {
          let device = item.devices[0]
          this.deviceNames.push({
            id: device.device_id,
            name: item.name
          })
        }
      })
    }

    Api.generator.list({limit: 1000})
      .then(responseHandler)
      .catch(e => {
        this.$toast.error("Failed to collect devices data")
      })
      .finally(() => {
        this.filterLoading = false
      })
  }

}
</script>

<style lang="scss">
  .accordion-header {
    background: #1F548B;
    
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .chevron-up-icon svg,
    .chevron-down-icon svg {
      color: #ffffffff;
    }

    p {
      font-size: 0.8rem;
    }
    
  }

  .accordion-body {
    .card {
      box-shadow: none;
      border: 1px solid #ECECEC;
      border-radius: 5px;
    }

  }

  .accordion-body {
    border: 1px solid #dedede;
  }
</style>