import Api from '@/utils/api'


export default {

  currentDriver: {
    handler: function(driver) {
      this.cleaningForm()

      const self = this

      if (!driver.driver_id) {

        if (!this.listDeviceLoading) {
          this.listDeviceLoading = true
          return Promise.all([
            this.fetchDeviceUnitList(),
            this.fetchUserList(),
          ])
          .finally(() => this.listDeviceLoading = false)
        }
        
      }

      if (!this.detailLoading)  {
        this.detailLoading = true
        
        Promise.all([
          this.fetchDeviceUnitList(),
          this.fetchUserList(),
          initDetail(self)
        ])
          .finally(() => this.detailLoading = false)

      }

      function initDetail (self) {
        if (!driver || !driver.user_id)
          return false

        self.driver_id = driver.driver_id
        self.user_id = driver.user_id
        self.phone = driver.phone
        self.description = driver.description
        self.$emit('setFormTypeEdit', true)

        return Api.driver.detail(driver)
          .then(response => {
            if (response.status !== 200)
              return self.$toast.error("Failed to collect user data")

            let driver = response.data.result.data
            let devices = driver.devices || []

            let units = devices.map(device => ({
              id: device.device_unit_id,
              name: device.device_name,
              coordinate: device.coordinate,
              action: device.action,
              color: device.color
            }))

            self.selectedDevices = units
            self.geofenceDevices = []
            units.forEach(item => {
              if (item.coordinate && !!item.coordinate.length) {
                self.geofenceDevices.push(item)
              }
            })

            self.selectedUser = {
              id: driver.user_id,
              name: driver.name
            }

            self.geofence = driver.geofence_status
          })
          .catch(e => {
            let error = e.response ? e.response : e
            self.$toast.error(error.message)
          })
          
      }
    }
  },


  selectedUser: function(user) {
    if(user && user.user_id) {
      this.user_id = user.user_id;
    }
  },
  
  selectedVehicle: function(vehicle) {
    if(vehicle && vehicle.device_unit_id) {
      this.device_unit = vehicle.device_unit_id;
    }
  }
}