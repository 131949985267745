<template lang="pug">
transition(name="modal")
  #obdDetailModal.modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-xl(role="document")
        .modal-content
          .modal-header.d-flex.justify-content-between(style="border: 0")
            span
            div
              span.mr-3.cursor(@click="getDetailObd") <i class="fa fa-refresh"></i>
              span.mr-2(
                role="button",
                data-dismiss="modal",
                aria-label="Close"
                @click="$emit('close')"
                style="font-size: 1.2rem"
              ) x
          .modal-body
            loader(:loading="loading")
              .row
                .col-lg-7.col-md-12
                  .card.shadow-none.rounded
                    .card-body
                      div.mb-3
                        .dtc-code-wrapper.d-flex.justify-content-between
                          .dtc-code.dtc-text(v-for="code in dtcCode") {{ code }}
                          
                      div.alert.m-0(:class="description ? 'alert-danger' : 'alert-warning'")
                        | <img v-if="description" src="@/assets/img/error_obd_icon.png" class="mr-2" style="width:20px"> {{ description || '-' }}

                .col-lg-5.col-md-12
                  .card.shadow-none.rounded
                    .card-body
                      .meta-info
                        .d-flex
                          .pic.d-flex.justify-content-center.align-items-center(style="width: 250px; height: 160px; background:#000;")
                            img(style="border-radius: 10px; max-width: 100%;" :src="thumbnail")
                          .info

                            .item-info
                              span Vehicle Name
                              span {{ detail.vehicle_name }}

                            .item-info
                              span Device Name
                              span {{ detail.device_name }}

              .row
                .col-12
                  .alert.alert-warning.rounded(v-if="diagnoseContent")
                    ul.my-0.px-3.py-2 {{ diagnoseContent }}

              .row
                .col-lg-7.col-md-12
                  .card.shadow-none.rounded
                    .card-body.px-3.pt-4.pb-0
                      div.tab-nav
                        span.tab-nav-item(
                          v-for="(tab, idx) in tabTitles"
                          :class="idx === activeTab ? 'active' : ''"
                          @click="activeTab = idx") 
                            | {{ tab }}
                        
                      div.tab-content
                        p.tab-row(v-if="tabContents[activeTab]" v-for="[label, value, unit] in tabContents[activeTab]") 
                          span {{ label }} 
                          span(v-html="`${value || 0} ${unit || ''}`")
                        
                .col-lg-5.col-md-12
                  .card.shadow-none.rounded.m-0
                    .card-body.p-3

                      .card.shadow-none.rounded.m-0
                        table.table.mb-0
                        
                          thead
                            tr
                              th(style="padding-left: 15px!important") DTC Code
                              th Timestamp
                              th

                          tbody
                            tr(v-if="!isCodes")
                              td.text-center.py-5(colspan="3" style="color: #cccccc") No data found

                            tr(v-else v-for="code in codes" :class="code.error ? 'error': ''")
                              td {{ code.code }}
                              td {{ code.timestamp }}
                              td.dtc-info(:class="isShowDescription(code.code) ? 'active' : ''")
                                div.cursor(@click="selectedCode = code") <i class="fa fa-exclamation-circle"></i>
                                div.dtc-wrapper
                                  div.dtc-head
                                    span DTC Code: {{ code.code }}
                                    span.mr-2.cursor(@click="selectedCode = null") X
                                  div.dtc-content 
                                    strong.d-block.mb-1 Description:
                                    div(style="font-size: 0.8rem") {{ code.description }} 
                                    hr
                                    strong.d-block.mb-1 Diagnostic:
                                    div(style="font-size: 0.8rem") {{ code.diagnostic || '-' }} 
                                    

</template>

<script>
import entity from '../entity'
import Api from '@/utils/api'

export default {
  props: ['isOpen', 'vehicle'],
  data() {
    return {
      detail: {},
      tabTitles: [],
      tabContents: [],
      activeTab: 0,
      activeDtc: {},
      indexDtc: 0,
      totalTab: 2,

      labelInfo: entity.ObdLabelInfoEntity(),
      codes: {},
      diagnosList: [],

      errorMessage: null,

      selectedCode: null,
      loading: false
    }
  },


  computed: {
    thumbnail() {
      if (!this.detail.image || this.detail.image === '-') return require('@/assets/img/truck-dummy.png')
      return this.detail.image
    },
    description() {
      if (!this.activeDtc) return ''
      return this.activeDtc.description
    },
    dtcCode() {
      let keys = Object.keys(this.codes).map(idx => {
        return this.codes[idx].code
      })

      // let keys = []
      let idx = 0
      if (!keys[idx]) return '000000'.split('')
      return keys[idx].split('')
    },

    isCodes() {
      return this.codes && !!Object.keys(this.codes).length
    },

    diagnoseContent() {
      return this.activeDtc ? this.activeDtc.diagnostic : null
    }
  },

  watch: {
    vehicle: {
      immediate: true,
      handler(vehicle) {
        if (vehicle.imei) {
          this.getDetailObd()
        }
      }
    }
  },

  methods: {

    getDetailObd() {
      if (this.loading) return

      const responseHandler = response => {
        let data = response.data
        
        if (response.status !== 200)
          throw new Error(data.message || 'Failed to fetch obd data')
        
        data.result.data.imei = this.vehicle.imei

        let vehicle = entity.OBDDetailEntity(data.result.data)
        this.detail = vehicle
        this.codes = vehicle.codes

        this.tableInfoFactory(vehicle.meta)
        this.collectError(vehicle.codes)
      }

      this.loading = true
      Api.vehicle.monitoring_obd({imei: this.vehicle.imei})
        .then(responseHandler)
        .catch(e => {
          this.$toast.error(e.message || 'Failed to fetch obd data')
        })
        .finally(() => {
          this.loading = false
        })
    },

    isShowDescription(code) {
      return this.selectedCode && this.selectedCode.code === code
    },

    collectError(codes) {
      codes = codes || []
      
      let activeDtc
      Object.keys(codes).forEach(idx => {
        if (codes[idx].error) {
          
          if (!activeDtc) activeDtc = codes[idx]
          this.diagnosList.push(`${activeDtc.code}: ${ activeDtc.diagnostic || activeDtc.description }`)
        }
      })

      this.activeDtc = activeDtc
    },

    tableInfoFactory(data) {
      data = data || {}

      this.tabTitles = []

      ;[...Array(this.totalTab).keys()].forEach(idx => {
        this.tabTitles.push(`Engine Info ${idx + 1}`)
      })
      
      let keys = Object.keys(this.labelInfo)
      let totalCol = Math.ceil(keys.length / this.totalTab)

      let idx = 0
      let cols = []
      keys.forEach(key => {
        if (!cols[idx]) cols[idx] = []

        // insert the data value to columns
        if (data[key]) this.labelInfo[key][1] = data[key]

        cols[idx].push(this.labelInfo[key])
        if (cols[idx].length >= totalCol) idx += 1
      })
      this.tabContents = cols
    },

    rotateDTC() {
      // this.rotate = setInterval(() => {
      //   if (!isOpen) clearInterval(this.rotate)
      // },5000)
    }

  },
  
}
</script>

<style lang="scss" scoped>
@import '../styles/obd.scss';
</style>
