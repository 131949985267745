<template lang="pug">
div#manageEtalase
  card(title='Manage Etalase')
    .mb-3(style="display:flex;")
      matador-search(style="flex-grow:0 !important; width:65vw; marginRight:20px" v-model="search" placeholder='Search Rental By Series or Vehicle Type' autocomplete='off')
      matador-button.btn.btn-outline-primary(style="color:white !important;marginLeft:10px;width:14vw;border-radius:6px !important;" @click="onAdd", icon="fa fa-plus")
        | Add Product
    .border-left.border-right.border-bottom
    keep-alive
      b-table.m-0(
        ref="table"
        :busy.sync='isBusy' 
        :filter='search' 
        :items='shownEtalase' 
        :fields='fields' 
        :perPage='perPage'
        :show-empty="true" 
        :currentPage="currentPage"
        :totalRows="totalRows"
        :filter-debounce="500"
        
      )
        template(#cell(actions)="row")
          div
            dropdownetalase.dropdown-toggle-no-caret( tag='a' icon='mdi mdi-dots-vertical h4 text-dark m-0')
              li.p-3.matador-text-03(style="borderBottom:1px solid black !important;" v-show="row.item.is_show ==1 " @click="onShow(row.item)") Hide this product in Etalase
              li.p-3.matador-text-03(style="borderBottom:1px solid black !important;" v-show="row.item.is_show ==0 " @click="onShow(row.item)") Show this product in Etalase
              li.p-3.matador-text-03(style="borderBottom:1px solid black !important;" ) 
                b-form-checkbox-group( id="checkboxes-4")
                  b-form-checkbox(v-model="check" :checked="check" :value="check==1?1:0" ) Auto Assign+{{row.item.is_auto_assign}}    
              li.p-3.matador-text-03(style="borderBottom:1px solid #F5F5F5 !important;" @click="onEdit(row.item)") Edit Product
              li.p-3.matador-text-03(style="borderBottom:1px solid #F5F5F5 !important;" @click="onShow(row.item)") View Detail 
              li.p-3.matador-text-03(style="borderBottom:0.1px solid #F5F5F5 !important;" @click="onDelete(row.item)") Delete Product
    .pt-4.mt-3
      .d-flex.align-item-center.justify-content-between
        b-pagination(
            v-model="page"
            :total-rows="etalase.length"
            :per-page="perPage"
            aria-controls="my-table"
        )
        div.d-flex.align-items-center
          span.mr-2 Per Page : 
          b-dropdown(:text='perPage.toString()' variant='light')
            b-dropdown-item(@click="perPage = 12") 12
            b-dropdown-item(@click="perPage = 48") 48
            b-dropdown-item(@click="perPage = 96") 96
            b-dropdown-item(@click="perPage = 184") 184
  etalase-add-form-modal(v-if="showEtalaseForm" :etal="itemEtalase" @close="showEtalaseForm = false" @success="onEtalaseChanged")

  confirmation-modal(
    v-if="deleteModal"
    @close="deleteModal = false" 
    @confirm="onConfirmDelete" 
    title="Delete Vehicle"
    confirm-text="Yes, Delete"
  )
    p.m-0
      | Do yo want to delete this etalase?

  confirmation-modal-accept(
    v-if="updateModal"
    @close="updateModal = false" 
    @confirm="onConfirmShowHide" 
    title="Update Visibility"
    confirm-text="Yes, Update"
  )
    p.m-0
      | Do yo want to update visibility this etalase?
</template>
<script>
import produce from 'immer';
import Vue from 'vue';
import dayjs from "dayjs";
import tableMixin from "@/mixins/table";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import ConfirmationModalAccept from "@/components/Matador/ConfirmationModalAccept";
import EtalaseAddFormModal from "@/components/Matador/EtalaseAddFormModal";
import EtalaseModal from "@/components/Matador/EtalaseModal";
import Dropdownetalase from "@/components/Dropdownetalase.vue";
import { getVehicleList, postDeleteVehicle, postDeleteEtalase, postShowHideEtalase } from "@/utils/api";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import { get } from "lodash";
import { getEtalase } from '../../../utils/api';
export default {
  mixins: [tableMixin],
  components: {
    ConfirmationModal,
    ConfirmationModalAccept,
    PlusIcon,
    EtalaseAddFormModal,
    EtalaseModal,
    Dropdownetalase
  },
  watch: {
    search: {
      handler: function(newVal){
        this.currentPage = 1;
      }
    },
    perPage: {
      handler: function(newVal, oldVal){
        if(newVal != oldVal){
          this.page = 1;
        }
      }
    },
    
  },
  computed: {
    
  },
  data() {
    return {
      isBusy: false,
      deleteModal: false,
      updateModal: false,
      page:1,
      search: '',
      check:false,
      fields: [
        { key: 'series', label: 'Series', sortable: true, class: '' },
        { key: 'name', label: 'Vehicle Type', sortable: true, class: '' },
        { key: 'total_vehicle', label: 'Total Vehicle', sortable: true, class: '' },
        { key: 'total_available', label: 'Avail', sortable: true, class: '' },
        { key: 'total_rent', label: 'On Rent', sortable: true, class: '' },
        { key: 'price_per_month', label: 'Per Month', sortable: true, class: '',
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        { key: 'price_per_day', label: 'Per Day', sortable: true, class: '',
          formatter:(value,key,item)=>{
              var prefix="Rp.";
              var mataUang=this.mataUang(prefix,value);
              return mataUang;
            }
        },
        { key: 'price_per_hour', label: 'Per Hour', sortable: true, class: '',
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        { key: 'price_per_km', label: 'Per Km', sortable: true, class: '',
          formatter:(value,key,item)=>{
            var prefix="Rp.";
            var mataUang=this.mataUang(prefix,value);
            return mataUang;
          }
        },
        { key: 'is_show', label: 'Visibility', sortable: true, class:'hello',
          formatter: (value, key) => {
            return value ? 'Shown' : 'Hidden' 
          }, 
        },
        { key: 'actions', label: ''},
      ],
      perPage: 12,
      currentPage: 1,
      totalRows: 0,
      vehicle: null,
      etalase:[],
      showEtalaseForm: false,
      showEtalaseDetail: false
    }
  },
  computed:{
    shownfields(){
      for (let index = 0; index < this.fields.length; index++) {
      
      }
    },
    shownEtalase(){
      const etal = JSON.parse(JSON.stringify(this.etalase)).filter((item) => {
        return (item.series || '').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
          || (item.name || '').toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      });
      const result = etal.splice(this.page * this.perPage - this.perPage, this.perPage);
      return result;
    }
  },
  methods: {
    mataUang(prefix,req){
      var	number_string = req.toString()
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
    
      return prefix+rupiah;
    },
    async onConfirmShowHide(){
      try {
        let show=null;
        if(this.itemEtalase.is_show == 1){
          show=0
        }else{
          show=1
        }
        let req={
          etalase_id:this.itemEtalase.etalase_id,
          is_show:show
        }
        let response=await postShowHideEtalase(req);
        this.updateModal=false;
        this.getEtalase();
        this.itemEtalase = null;
      } catch (error) {
        console.log(error)
      } 
    },
    async getEtalase(){
      try {
        let response = await getEtalase();
        this.etalase=response.data.result;
      
      } catch (error) {
      }
    },
    getVehicles(ctx, callback){
      const { 
        currentPage: page,
        perPage: limit,
        filter: search,
        sortBy: sort_by,
        sortDesc
      } = ctx
      const sort = sortDesc ? 'desc' : 'asc';
      getVehicleList({ display_location: 0, start: page * limit - limit, limit, sort_by: sort_by || 'vehicle_name', sort, search }).then(({ data: { result: { vehicle, total_rows } } }) => {
        this.totalRows = total_rows;
        callback(vehicle.map(item => {
          item.description = get(item, 'description');
          item.vehicle_name = get(item, 'vehicle_name') || get(item, 'unit_id');
          item.name = get(item, 'name');
          item.series = get(item, 'series');
          return item;
        }));
      });
    },
    onAdd(){
      this.itemEtalase=null
      this.showEtalaseForm = true;
      // console.log(this.etalase)
    },
    onEdit(etal){
      this.itemEtalase = etal;
      // console.log(etal)
      this.showEtalaseForm = true;
    },
    async onShow(v){
      this.updateModal = true;
      this.itemEtalase={
        etalase_id:v.etalase_id,
        is_show:v.is_show
      }
    /*  this.vehicle = vehicle;
      getVehicleDetail({
        unit_id: vehicle,
        trip: dayjs().format("YYYY-MM-DD"),
      }).then(({ data: { code, result, message } }) => {
        this.vehicle = result;
      });
      this.showVehicleDetail = true; */
    },
    onEtalaseChanged(){
      this.getEtalase();
      this.showEtalaseForm = false;
      this.$refs.table.refresh();
      this.$toast.open('Successfully save etalase data.');
    },
    onDelete(etal){
      this.itemEtalase = etal.etalase_id;
      this.deleteModal = true;
    },
    onConfirmDelete(){
      this.deleteModal = false;
      postDeleteEtalase(this.itemEtalase).then(() => {
        this.$refs.table.refresh();
        this.$toast.open('The etalase successfully deleted.');
        this.getEtalase();
      });
      this.itemEtalase = null;
    },

  },
  mounted(){
    this.getEtalase();
  }
};
</script>
<style lang="scss" scope>
@import "../../../assets/sass/paper/variables.scss";
#vehicles {
  
}
.text-danger{
  color:red;
}

</style>
