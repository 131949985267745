'use strict'

import day from 'dayjs'
import {SOS_STATES} from '../../entity'

export function TableOption(self) {

    const options = {
      ajax: self.fetchTableData,
      search: false,
      searchSelector: "#sos-search-box",
      showSearchButton: false,
      sortable: true,
      pagination: true,
      paginationHAlign: "center",
      paginationDetailHAlign: "right",
      // sidePagination: "server",
      paginationParts: ["pageInfo", "pageList", "pageNumbers"],
      classes: ["table", "table-hover"],
      loadingFontSize: 16,
    }


    const actionFormatter = (value, row) => {

      const template = {
        template: `
          <select class="form-control"
            style="background: none!important"
            v-model="value"
            @change="onSelectStatus">
            <option v-for="state in statuses" :key="state.id" :value="state.id">{{ state.name }}</option>
          </select>
          `,
        data: { 
          row, 
          value: row.status,
          statuses: []
        },
        methods: {
          onSelectStatus(e) {
            this.value = e.target.value
            self.onChangeStatus(row, this.value)
          },
        },
        mounted() {
          
          this.statuses = Object.keys(SOS_STATES).map(key => ({
            id: key,
            name: SOS_STATES[key]
          }))
        }
      }

      return self.vueFormatter(template);
    }

  

    let columns = [
      {
        title: "Timestamp",
        field: "timestamp",
        searchable: false,
        sortable: true,
        formatter: (value, row) => {
          let date = day(value).format('YYYY/MM/DD')
          let time = day(value).format('HH:mm')
          return `
            <div>
              <span>${date}</span>
              <span>${time}</span>
            </div>
          `
        },
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Driver",
        field: "driver",
        sortable: true,
        searchable: true,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Devices",
        field: "device",
        sortable: true,
        searchable: false,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Location",
        field: "location",
        sortable: true,
        searchable: true,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Action",
        field: "",
        sortable: false,
        searchable: false,
        formatter: actionFormatter,
        widthUnit: '%',
        width: '22'
      },
    ]
  
  
    return { columns, options }
  }