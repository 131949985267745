<template lang="pug">
div.row#historyList
  .col-12
    .d-flex.justify-content-end.mb-3.mt-3

      .col-12.col-md-4.pr-2.pl-0
        label.filter-label Sorted by
        b-form-group
          autocomplete(
            v-on:selected="onSelectSortBy"
            v-model="selectedSortBy"
            :options="sortOption"
            :placeholder="filterLoading ? 'Loading...' : 'Please select an option'"
          )

      .col-12.col-md-3.px-2
        label.filter-label Start Date
        b-form-group
          datepicker(
            input-class="form-control datepicker" 
            @selected="onSelectStartDate" 
            placeholder="Select start date"
          )

      .col-12.col-md-3.px-2
        label.filter-label End Date
        b-form-group()
          datepicker(
            input-class="form-control datepicker" 
            @selected="onSelectEndDate" 
            placeholder="Select end date"
          )

      .col-12.col-md-2.pl-2.pr-0.d-flex.align-items-center
        div.pt-1(style="width: 100%")
          matador-button.mt-2.py-1.px-4(
            size="lg" 
            style="width: 100%; border: 3px solid #1f548b;"
            :disabled="!isClickable"
            @click="onGenerate"
          ) {{ loading ? "Loading.." : 'Generate' }}

    keep-alive
      .table-responsive
        bs-table(
          ref="reportTable"
          :columns="table.columns",
          :options="table.options",
          :data="list",
          @on-post-body="vueFormatterPostBody"
        )

    latest-condition(
      :isDetailOpen="isDetailOpen",
      :loading="historyLoading"
      :detail="selectedHistory"
      @close="isDetailOpen=false"
    )
     
</template>

<script>

// TODO @tiduronline faker is needed

import tableMixin from '@/mixins/table'
import Datepicker from "vuejs-datepicker";
import days from 'dayjs'
import {TableOption} from './table'
import Api from '@/utils/api'
import entity from '../../entity'

import LatestCondition from './LatestCondition.vue'


export default {
  mixins: [tableMixin],
  
  components: {
    Datepicker,
    LatestCondition
  },

  data() {
    return {
      filterLoading: false,
      loading: false,

      selectedSortBy: null,
      selectedStartDate: null,
      selectedEndDate: null,
      isDetailOpen: false,
      historyLoading: false,
      selectedHistory: null,
      list: [],

      sortOption: [
        { id: 'timestamp', name: "Timestamp" },
        { id: 'driver', name: "Driver" },
        { id: 'device', name: 'Device'}
      ],
      table: TableOption(this),
    }
  },

  
  
  methods: {

    onDetail(row) {
      this.isDetailOpen = true
      // if (this.historyLoading) return false
      this.historyLoading = true
  
      let params = {
        history_id: row.history_id,
        driver_id: row.driver_id
      }

      Api.driver.history_detail(params)
        .then(response => {
          this.selectedHistory = response.data.result.data
        })
        .catch(e => {
          let error = e.response ? e.response : e
          let message  = error.data ? error.data.message : e.message
          
          if (error.status !== 404) 
            this.$toast.error(message ||  'Failed to collect data detail' )
        })
        .finally(() => {
          this.historyLoading = false
        })
    },

    onSelectSortBy(type) {
      this.selectedSortBy = type.id
    },

    onSelectStartDate(start) {
      this.selectedStartDate = days(start).format('YYYY-MM-DD')
    },

    onSelectEndDate(end) {
      this.selectedEndDate = days(end).format('YYYY-MM-DD')
    },

    onGenerate() {

      if (this.loading) return false
      this.loading = true

      let payload = {
        sorted: this.selectedSortBy ? `${this.selectedSortBy}.asc` : 'timestamp.asc',
        start_date: this.selectedStartDate,
        end_date: this.selectedEndDate
      }

      Api.driver.history_list(payload)
        .then(response => {
          const data = response.data.result.data
          this.list = data.map(item => entity.HistoryListEntity(item))
        })
        .catch(e => {
          let error = e.response ? e.response.data : e
          let message = error.message || 'Failed to collect log history'
          this.$toast.error(message)
        })
        .finally(() => {
          this.loading = false
        })

    },

  },

  computed: {
    isClickable() {
      return ( this.selectedStartDate && this.selectedEndDate ) ? true : false
    }
  },

  mounted() {
    // this.selectedStartDate = days().subtract('1', 'month').format('YYYY-MM-DD')
    // this.selectedEndDate = days().subtract('1', 'month').format('YYYY-MM-DD')

    this.selectedStartDate = days().format('YYYY-MM-DD')
    this.selectedEndDate = days().format('YYYY-MM-DD')

    this.onGenerate()
  }

}
</script>

<style lang="scss">
#historyList {
  .filter-label {
    color: #000;
    font-weight: bold;
  }
}
</style>
