'use strict'

export const Devices = () => {
    // DUMMY RESULT
    const statuses = ['off', 'on', 'idle']
    const random = () => {
      const idx = Math.floor(Math.random() * 3);
      return statuses[idx]
    }

    return new Promise(resolve => {
      let data = [...Array(20).keys()].map(i => {
        const status = random()
        return MonitorEntity({
          uuid: `86917003281032${(i+1)}`,
          device_unit_id: (i+1),
          device_name: `1F-Dummy-823${i+1}`,
          coordinate: [
            [-7.747279280313777,110.40153575001527],
            [-7.756209147030451,110.40136408863832],
            [-7.755401214504785,110.41084837971498],
            [-7.747024138481455,110.40921759663392],
            [-7.747747039938237,110.40162158070375],
            [-7.748087228429709,110.40162158070375]
          ],
          location: 'Indonesia',
          last_log: [
            {
              timestamp: "2022-03-01T02:29:06.127Z",
              status,
            }
          ]
        })
      })
      
      resolve(data)
    })
}

export const CreateGenerator = () => {
  return new Promise(resolve => {
    let data = {
      data: params,
      message: 'Successfully add new Generator'
    }
    setTimeout(() => resolve(data), 3000)
  })
}


export const DetailDevice = () => {
  return new Promise(resolve => {
    resolve({
      uuid: "860665051716909",
      oil_pressure: 0,
      coolant_temp: 0,
      fuel_level: 0,
      charge_alternator_voltage: 0,
      engine_speed: 0,
      generator_freq: 0,
      battery_voltage: 0,
      device_status: 0,
      main_out_power_line_1: 0,
      main_out_power_line_2: 0,
      main_out_power_line_3: null,
      timestamp: "2015-10-17T17:00:22.000Z",
      status: "off"
    })
  })  
}

export default {
  Devices,
  CreateGenerator,
  DetailDevice
}