<template lang="pug">
div#report
  card(title='Reports' style="z-index: 9999;")
    .row.px-2
      .col-12.col-md-3.px-2
        b-form-group(label="Device Vehicle Name")
          autocomplete(
            :options="optionDevices"
            v-on:selected="onSelectVehicle"
            :disabled="false"
            name="device_id"
            :maxItem="1000"
            placeholder="Please select an option"
          )
      .col-12.col-md-3.px-2
        b-form-group(label="Report Data")
          autocomplete(
            :options="optionReports"
            v-on:selected="onSelectReport"
            :disabled="false"
            name="device_id"
            :maxItem="1000"
            placeholder="Please select an option"
          )
      .col-12.col-md-3.px-2
        b-form-group(label="Start Date")
          datepicker(input-class="form-control datepicker" @selected="onStartDate" :value="start_date" name="startDate" ref="startDate")
      .col-12.col-md-3.px-2
        b-form-group(label="End Date")
          datepicker(input-class="form-control datepicker" @selected="onEndDate" :value="end_date" name="endDate" ref="endDate")
      .col-12.col-md-auto.px-2.ml-md-auto.mt-2
        matador-button.py-1.px-4(:disabled="!device_id || !report || !start_date || !end_date" @click="onGenerateReport" size="lg") Generate
  div(v-show="dates.length == 2")
    card.mt-4(title='Overview')
      .d-flex.mb-4
        matador-button.py-1.ml-auto(icon="fa fa-print" size="lg" :outline="true" @click="onPrintPdf")
          | &nbsp;Print PDF
      main-report(:device_id="device_id" :dates="dates" :report-token="reportToken")
      //- report-accordion(title="Sensor Table Log" v-if="report == 'all' || report == 'sensor'")
      //-   sensor-report(:device_id="device_id" :dates="dates" :report-token="reportToken")
      report-accordion(title="Graph" v-if="report == 'all' || report == 'sensor'")
        graph-report(:device_id="device_id" :dates="dates" :report-token="reportToken")
      //- report-accordion(title="Location" v-if="report == 'all' || report == 'sensor'")
      //-   location-report(:device_id="device_id" :imei="imei" :dates="dates" :report-token="reportToken")
      report-accordion(title="Maps" v-if="report == 'all' || report == 'location'")
        map-report(:device_id="device_id" :dates="dates" :report-token="reportToken")
</template>
<script>
import { getDeviceUnit } from '../../../utils/api';
import Datepicker from "vuejs-datepicker";
import ReportAccordion from "../../../components/Matador/Reports/ReportAccordion.vue";
import MainReport from "../../../components/Matador/Reports/MainReport.vue";
import SensorReport from "../../../components/Matador/Reports/SensorReport.vue";
import GraphReport from "../../../components/Matador/Reports/GraphReport.vue";
import LocationReport from "../../../components/Matador/Reports/LocationReport.vue";
import MapReport from "../../../components/Matador/Reports/MapReport.vue";
import dayjs from 'dayjs';
import Vue from 'vue';
export default {
  components: {
    Datepicker,
    ReportAccordion,
    MainReport,
    SensorReport,
    GraphReport,
    LocationReport,
    MapReport,
  },
  data() {
    return {
      device_id: Vue.config.devtools ? 'device-1220' : null,
      imei: null,
      report: 'all',
      // start_date: Vue.config.devtools ? dayjs().add(-360, 'days').format('YYYY-MM-DD') : null,
      start_date: dayjs().format('YYYY-MM-DD'),
      // end_date: Vue.config.devtools ? dayjs().add(-300, 'days').format('YYYY-MM-DD') : null,
      end_date: dayjs().format('YYYY-MM-DD'),
      showReport: Vue.config.devtools,
      dates: [],
      reportToken: Math.random(),
      optionDevices: [],
      optionReports: [
        {id: 'all', name: 'All'}
      ],
      reports: {
        main: null,
      }
    }
  },
  methods: {
    onStartDate(val){
      this.start_date = val;
    },
    onEndDate(val){
      this.end_date = val;
    },
    onGenerateReport(){
      this.dates = [dayjs(this.start_date).format('YYYY-MM-DD'), dayjs(this.end_date).format('YYYY-MM-DD')];
      this.showReport = true;
      this.reportToken = Math.random();
    },
    onSelectVehicle(val){
      // console.log(val.id);
      if(val.id) this.device_id = val.id;
      if(val.imei) this.imei = val.imei;
    },
    onSelectReport(val){
      if(val.id) this.report = val.id;
    },
    onPrintPdf(){
      window.open(this.printUrl, '_blank').focus();
    }
  },
  computed: {
    printUrl: function(){
      return `/#/print-report?device_id=${this.device_id}&start_date=${this.dates[0]}&end_date=${this.dates[1]}`;
    }
  },
  mounted(){
    getDeviceUnit().then(({data: { result }}) => {
      this.optionDevices = result.map((item) => {
        return {
          id: item.device_id,
          imei: item.imei,
          name: item.description
        }
      });
    });
    setTimeout(() => {
      document.querySelectorAll('.dropdown-input').forEach((node) => {
        node.style.minWidth = '0';
      });
      document.querySelectorAll('.dropdown').forEach((node) => {
        node.querySelector('input').setAttribute('autocomplete', 'off');
      });
    }, 1000);
  }
};
</script>
