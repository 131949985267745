<template lang="pug">
div#EtalaseMakeOrder
  div(v-show="statusCart != true")
    not-found()
  card(title="Make Order" v-show="statusCart")
    loader(:loading="saving")
      .accordion(role='tablist')
        div
          b-button.matador-bg-03(block='' @click="showRentalPlanFunc()" style="text-align: left !important;border-radius: 15px;") 
              .d-flex
                div.w-100
                  .matador-h5.matador-text-03 Choose Rental Plan 
                span.matador-text-03(v-show="showRentalPlan!=false" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
                span.matador-text-03(v-show="showRentalPlan!=true" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-1(:visible='showRentalPlan' accordion='my-accordion' role='tabpanel' appear)
            b-card-body
              div.mb-1
                .matador-text-01 Choose plan 
              .d-flex 
                div
                  b-button.btn-modifikasi.btn-sm.btn-outline-primary(:class="(planTime=='Monthly') ? 'actived' : 'null'" @click="choosePlanFunc('Monthly')") 
                    .matador-text-03 Monthly 
                div.ml-3
                  b-button.btn-modifikasi.btn-sm.btn-outline-primary(:class="(planTime=='Daily')  ? 'actived' : 'null'" @click="choosePlanFunc('Daily')") 
                    .matador-text-03 Daily 
                div.ml-3
                  b-button.btn-modifikasi.btn-sm.btn-outline-primary(:class="(planTime=='Hourly')  ? 'actived' : 'null'" @click="choosePlanFunc('Hourly')")  
                    .matador-text-03 Hourly 
                div.ml-3
                  b-button.btn-modifikasi.btn-sm.btn-outline-primary(:class="(planTime=='Mileage') ? 'actived' : 'null'" @click="choosePlanFunc('Mileage')") 
                    .matador-text-03 By Mileage 
              div.mt-3.mb-1(v-show="planTime != 'Hourly'")
                .matador-text-01 {{planTime}}
              .d-flex()
                div(v-show="planTime != 'Hourly'" style="width:80px;border: 1px solid #D3D4D8 !important; border-radius: 5px;")
                  b-form-input( style="height:32px !important;" v-model="rentDuration" type="number"  min="0" ) 
                div.pt-1.ml-2(v-show="planTime != 'Hourly'")
                  .matador-text-02 {{sizeUnit}}
              .row.mt-3
                .col-2
                    .matador-text-01.mb-1 Start Date
                    datepicker(style="width:113px !important;" v-model="startDate" placeholder="Start Date"  input-class="form-control" calendar-class="scrolling")
                .col-2
                    .matador-text-01.mb-1 Start Time
                    .d-flex.justify-content-between.align-items-start
                      timepicker.flex-grow-1(input-class="form-control" v-model="startTime"  close-on-close-on-complete format="HH:mm" input-width="90px")
              div.mt-3.mb-1(v-show="planTime == 'Hourly'")
                .matador-text-01 {{planTime}}
              .d-flex()
                div(v-show="planTime == 'Hourly'" style="width:80px;border: 1px solid #D3D4D8 !important; border-radius: 5px;")
                  b-form-input( style="height:32px !important;" v-model="rentDuration" type="number"  min="0" ) 
                div.pt-1.ml-2(v-show="planTime == 'Hourly'")
                  .matador-text-02 {{sizeUnit}}
              .d-flex.mt-3
                div
                  .matador-text-01 Return by :
                  .matador-text-02 {{returnDate}}
                  
        div.mt-2
          b-button.matador-bg-03(block='' @click="showRentalSummaryFunc()" style="text-align: left !important;border-radius: 15px;") 
              .d-flex
                div.w-100
                  .matador-h5.matador-text-03 Rental Summary
                span.matador-text-03(v-show="showRentalSummary!=false" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
                span.matador-text-03(v-show="showRentalSummary!=true" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-2(:visible='showRentalSummary' accordion='my-accordion#2' role='tabpanel' appear)
            loader(:loading="saving")
              b-card-body
                div
                  .row.pl-4.mt-4
                    .col-1
                      .matador-h6 No.
                    .col-5
                      .d-flex
                        .matador-h6 Vehicle
                    .col-2
                      .matador-h6 Qty
                    .col-2
                      .matador-h6 Rates
                    .col-2
                      .matador-h6 Amounts
                div(v-for="(item,index) in cartList" :key="index")  
                  .row.pl-4.mt-4()
                    .col-1
                      .matador-h5.matador-text-02 {{index}}
                    .col-5
                      .d-flex
                        img.mb-2.mb-lg-0(width="100px" height="75px"  v-if="item.image" :src="item.image | assets")
                        img.mb-2.mb-lg-0(width="100px" height="75px"  v-else src="@/assets/img/car-placeholder.png")  
                        .ml-4.matador-h4 {{item.name}}
                    .col-2
                      .matador-h5.matador-text-02.pl-1 {{item.qty}}
                    .col-2
                      .matador-h5.matador-text-02(v-show="planTime=='Monthly'") Rp {{mataUang(item.price_per_month)}},-
                      .matador-h5.matador-text-02(v-show="planTime=='Daily'") Rp {{mataUang(item.price_per_day)}},-
                      .matador-h5.matador-text-02(v-show="planTime=='Hourly'") Rp {{mataUang(item.price_per_hour)}},-
                      .matador-h5.matador-text-02(v-show="planTime=='Mileage'") Rp {{mataUang(item.price_per_km)}},-
                    .col-2
                      .matador-h5.matador-text-02 Rp {{haveToPay[index]}},-
                  div( v-show="index != cartList.length" style="padding-bottom:20px;border-bottom:1px solid #f0f0f0;")
                  div( v-show="index == cartList.length" style="padding-bottom:20px;border-bottom:2px solid #A1A4B1;")
                  
                div.d-flex
                  div(style="width:70%")
                  div(style="width:28%")
                      .matador-h6(style="padding-top:10px") Total Amount 
                      .matador-h3.matador-text-02.ml-4(style="margin-top:-30px;text-align: right;") Rp {{this.mataUang(totalPayment)}},-
        div.mt-2
          b-button.matador-bg-03(block='' @click="showPaymentPlanFunc()" style="text-align: left !important;border-radius: 15px;") 
              .d-flex
                div.w-100
                  .matador-h5.matador-text-03 Choose Payment Plan 
                span.matador-text-03(v-show="showPaymentPlan!=false" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
                span.matador-text-03(v-show="showPaymentPlan!=true" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-3(:visible='showPaymentPlan' accordion='my-accordion#3' role='tabpanel' appear)
            b-card-body
              b-form-group( v-slot='{ ariaDescribedby }')
                      b-form-radio-group#radio-slots(v-model='selectedPayment', :aria-describedby='ariaDescribedby' , name='radio-options-slots')
                        b-card(style="width:50%" :class="(selectedPayment == 'pre-payment') ? 'card-rent-actived' : 'card-rent' ")
                          b-form-radio(value='pre-payment') 
                            .matador-h5 Pre-paid
                            span You will directed to payment service after you confirmed your order.
                        b-card(style="width:50%" :class="(selectedPayment == 'post-payment') ? 'card-rent-actived' : 'card-rent' ")
                          b-form-radio(value='post-payment') 
                            .matador-h5 Post-paid
                            span Your bill will be generated at the end of your rental plan..
        div.mt-2
          b-button.matador-bg-03(block='' @click="showBillingInformationFunc()" style="text-align: left !important;border-radius: 15px;") 
              .d-flex
                div.w-100
                  .matador-h5.matador-text-03 Billing Information 
                span.matador-text-03(v-show="showBillingInformation!=false" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
                span.matador-text-03(v-show="showBillingInformation!=true" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-4(:visible='showBillingInformation' accordion='my-accordion#4' role='tabpanel' appear)
            b-card-body(style="width:75%")
              .form
                .form-group
                  .matador-text-02 Bill to
                  input.form-control(type="text" v-model="billTo"  placeholder="Mardhani")
                .form-group
                  .row
                    .col
                      .matador-text-02 Email
                      input.form-control(type="email" v-model="email"  placeholder="widyaAnalityc@gmail.com")
                    .col
                      .matador-text-02 Phone
                      vue-phone-number-input(v-model="phone" )

                .form-group
                  .matador-text-02 Address
                  input.form-control(type="text" v-model="address" placeholder="Jln Palagan Km.10 Sleman Yogyakarta")
        div.mt-2.mt-4
          .row
            .col-5
            .col-3
              button.border-0.bg-white.pt-3.px-5(style="color:#992024 !important;font-weight:bold;text-transform: none;margin-left:30%;" @click="onCancel()") 
                span.matador-h4.ml-2 Cancel
            .col-4
              matador-button( size="sm" style="width:100%;background-color:#6DDF92 !important;border:0px solid #6DDF92 !important ;border-radius:5px;" @click="checkOut()" :disabled="!allowAllPost" icon="mdi mdi-24px mdi-check-circle-outline") 
                  span.matador-h4.ml-2 Make This Order
  confirmation-modal(
      v-if="showCancelModal"
      @close="showCancelModal = false" 
      @confirm="confirmationCancel" 
      title="Discard Changes"
      confirm-text="Yes"
    )
      p.m-0
        | Are you sure want to discard changes you made ?
</template>
<script>
import VueTimepicker from 'vue2-timepicker'
import Datepicker from "vuejs-datepicker";
import VuePhoneNumberInput from 'vue-phone-number-input';
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import dayjs from 'dayjs';
import NotFound from "@/pages/NotFoundPage.vue";
import {
  doubleDigitFormat,
} from "@/utils/datetime";
import {getListCart, postOrderVehicle} from '@/utils/api'
import {mapGetters} from "vuex"
import Vue from 'vue';
export default {
  data() {
    return {
      saving:false,
      showCancelModal:false,
      showRentalPlan:true,
      showRentalSummary:true,
      showPaymentPlan:true,
      showBillingInformation:true,
      planTime:"Monthly",
      sizeUnit:"/Month",
      isActive:false,
      endDate:null,
      returnDate:"dd/mm/yyyy hh:mm",
      cssDate:false,
      cartList:[],
      selectedPayment:"",
      selectedPaymentStatus:true,
      status:{
        statusBill:true,
        statusEmail:true,
        statusPhone:true,
        statusAddress:true,
      },
      saving:false,
      rentDuration:0,
      haveToPay:[],
      totalPayment:0,
      etalasePost:[],
      startDate: Vue.config.devtools ? dayjs().add(0, 'days').format('YYYY-MM-DD') : null,
      startTime:null,
      billTo:"",
      phone:"",
      address:"",
      email:"",
      priceQty:"",
      startHour:0,
      startMinute:0,
    }
  },
  computed:{
    ...mapGetters({
      auth:'gettersAuth',
      statusCart:'gettersCartStatus'
    }),
    allowPost(){
      if(this.address.length >0 && this.status.statusAddress !=true){
        return false;
      }else{
        return true;
      }
    },
    allowAllPost(){
      return this.returnDate != 'dd/mm/yyyy hh:mm' && this.billTo !=  "" && this.phone != "" && this.address != "" && this.status.statusEmail == false && this.selectedPayment != "";
    }
  },
  components:{
    Datepicker,
    VueTimepicker,
    VuePhoneNumberInput,
    ConfirmationModal,
    NotFound 
  },
  watch:{
  
    startDate:{
      handler:function(newVal){
      this.calculateStartDate();
      this.calculateStartTime();
      }
    },
    startTime:{
      handler:function(newVal){
        this.startHour=newVal.HH;
        this.startMinute=newVal.mm;
        this.calculateStartTime();
        // console.log(newVal.mm);
        if(this.endDate== "Invalid Date" && newVal.mm==""){
          this.selectedPaymentStatus=true
        }else{
          this.selectedPaymentStatus=false
        }
      }
    },
    selectedPayment:{
      handler:function(newVal){
        this.selectedPayment=newVal
        this.status.statusBill=false;
      }
    },
    billTo:{
      handler:function(newVal){
        // console.log(newVal)
        if(this.billTo.length==0){
          this.status.statusEmail=true
          this.status.statusPhone=true
          this.status.statusAddress=true
        }else{
          this.status.statusEmail=false
          
        }
      }
    },
    email:{
      handler:function (newVal) {
        var res=this.validateEmail(newVal)
        if(res){
          this.status.statusPhone=false
          this.status.statusAddress=false
          this.status.statusEmail=false
        }else{
          this.status.statusPhone=true
          this.status.statusAddress=true
          this.status.statusEmail=true
        }
      }
    },
    planTime:{
      handler:function (newVal) {
        this.calculateStartDate();
        this.calculateStartTime();
        this.calculatePayment();
      }
    },
    rentDuration:{
      handler:function(newVal){
        this.rentDuration=newVal;
        if(this.planTime =="Hourly"){
        this.calculateStartTime();
        this.calculateStartDate();
        }else{
          this.calculateStartDate();
          this.calculateStartTime();
        }
        this.calculatePayment();
      }
    }
  },
  methods: {
  confirmationCancel(){
    this.$emit('close');
    this.$store.dispatch('getUserCart',this.auth.user_id)
    setTimeout(() => {
      
      this.$router.push('/catalogue')
    }, 2000);
  },
  onCancel(){
    this.showCancelModal=true;
  },
  calculateStartTime(){
    var hour=dayjs(this.endDate).hour(this.startHour)
    var minute=dayjs(hour).minute(this.startMinute)
    this.endDate=minute;
    this.returnDate=dayjs(minute).format('DD/MM/YYYY HH:mm:ss')
  },
  calculateStartDate(){
     if(this.planTime=="Monthly"){
      //  console.log(this.rentDuration,"month")
          // this.priceQty=this.dataCheckout.dataVehicle.price_per_month
          var date=dayjs(this.startDate).add(this.rentDuration,'month')
          this.endDate=date
          this.returnDate=dayjs(this.endDate).format('DD/MM/YYYY HH:mm:ss')
        }else if(this.planTime=="Daily"){
          // console.log(this.rentDuration,"daily")
          // this.priceQty=this.dataCheckout.dataVehicle.price_per_day
          var date=dayjs(this.startDate).add(this.rentDuration,'day')
          this.endDate=date
          this.returnDate=dayjs(this.endDate).format('DD/MM/YYYY HH:mm:ss')
        }else if(this.planTime=="Hourly"){
          // console.log(this.rentDuration,"jam")
          var date;
          // this.priceQty=this.dataCheckout.dataVehicle.price_per_hour
          var hour=dayjs(this.startDate).hour(this.startHour)
          var minute=dayjs(hour).minute(this.startMinute)
          date=dayjs(minute).add(this.rentDuration,'hour')
          // console.log(date,"date jam")
          this.endDate=date
          this.returnDate=dayjs(this.endDate).format('DD/MM/YYYY HH:mm:ss')
        }else{
          // this.priceQty=this.dataCheckout.dataVehicle.price_per_km
          let day = Math.floor(this.rentDuration/500);
          if(this.rentDuration>= 500){
            var date=dayjs(this.startDate).add(day+1,'day')
          }else{
            var date=dayjs(this.startDate).add(1,'day')
          }
          this.endDate=date
          this.returnDate=dayjs(this.endDate).format('DD/MM/YYYY HH:mm:ss')
        }
  },
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  calculateAmountMonthly(planTime){
    this.haveToPay=[];
    this.etalasePost=[];
    var calculate;
    this.totalPayment=0;
    for (let index = 0; index < this.cartList.length; index++) {
      calculate=this.cartList[index].qty * this.rentDuration * this.cartList[index].price_per_month
      this.etalaseData(planTime,this.cartList[index].price_per_month,this.cartList[index].qty,this.cartList[index].etalase_id)
      this.haveToPay[index]=this.mataUang(calculate);
      this.totalPayment=this.totalPayment+calculate;
    }
    
  },
  calculateAmountDaily(planTime){
    this.haveToPay=[];
    this.etalasePost=[];
    var calculate;
    this.totalPayment=0;
    for (let index = 0; index < this.cartList.length; index++) {
      calculate=this.cartList[index].qty * this.rentDuration * this.cartList[index].price_per_day
      this.etalaseData(planTime,this.cartList[index].price_per_day,this.cartList[index].qty,this.cartList[index].etalase_id)
      this.haveToPay[index]=this.mataUang(calculate);
      this.totalPayment=this.totalPayment+calculate;
    }
  },
  calculateAmountHourly(planTime){
    this.totalPayment=0;
    this.haveToPay=[];
    this.etalasePost=[];
    var calculate;
    for (let index = 0; index < this.cartList.length; index++) {
      calculate=this.cartList[index].qty * this.rentDuration * this.cartList[index].price_per_hour;
      this.etalaseData(planTime,this.cartList[index].price_per_hour,this.cartList[index].qty,this.cartList[index].etalase_id)
      this.haveToPay[index]=this.mataUang(calculate);
      this.totalPayment=this.totalPayment+calculate;
    }
      
  },
  calculateAmountMileage(planTime){
    this.totalPayment=0;
    var  dayPay = Math.floor(this.rentDuration/500);
    let sisaPay =this.rentDuration%500
        if(sisaPay !=0){
          dayPay=dayPay+1;
        }else{
          dayPay=dayPay;
        }
    this.haveToPay=[];
    this.etalasePost=[];
    var calculate;
      for (let index = 0; index < this.cartList.length; index++) {
        calculate=this.cartList[index].qty * dayPay * this.cartList[index].price_per_km
        this.etalaseData(planTime,this.cartList[index].price_per_km,this.cartList[index].qty,this.cartList[index].etalase_id)
        this.haveToPay[index]=this.mataUang(calculate);
        this.totalPayment=this.totalPayment+calculate;
      }
  },
  etalaseData(planTime,price,qty,etalase_id){
    let obj={
      etalase_id,
      qty,
      price_per_qty:price
    }
    this.etalasePost.push(obj);
  },
  calculatePayment(){
      if(this.planTime=="Monthly"){
        this.calculateAmountMonthly(this.planTime);
      }else if(this.planTime=='Daily'){
        this.calculateAmountDaily(this.planTime);
      }else if(this.planTime=='Hourly'){
        this.calculateAmountHourly(this.planTime)
      }else if(this.planTime=='Mileage'){
        this.calculateAmountMileage(this.planTime)
      }
    },
    mataUang(req){
      var	number_string = req.toString();
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
      return rupiah;
    },
    async getListCart(){
      try {
        this.saving=true;
        let obj = {
          user_id:this.auth.user_id
        }
        let response = await getListCart(obj)
        if(response.data.code==200){
          this.cartList=response.data.result
          setTimeout(() => {
            this.saving=false
          }, 2000);
        }
        // console.log(obj)
      } catch (error) {
        
      }
    },
    clickDatePicker(){
      this.cssDate=true
    },
    showRentalPlanFunc(){
      if(this.showRentalPlan){
        this.showRentalPlan=false
      }else{
        this.showRentalPlan=true
      }
    },
    showRentalSummaryFunc(){
      if(this.showRentalSummary){
        this.showRentalSummary=false
      }else{
        this.showRentalSummary=true
      }
    },
    showBillingInformationFunc(){
      if(this.showBillingInformation){
        this.showBillingInformation=false
      }else{
        this.showBillingInformation=true
      }
    },
    showPaymentPlanFunc(){
      if(this.showPaymentPlan){
        
        this.showPaymentPlan=false
      }else{
        this.showPaymentPlan=true
      }
    },
    choosePlanFunc(params){
      this.planTime=params;
      if(params == "Monthly"){
        this.sizeUnit='/Month';
      }else if(params == 'Daily'){
        this.sizeUnit='/Day'
      }else if(params == 'Hourly'){
        this.sizeUnit='/Hour'
      }else{
        this.sizeUnit='/Km(s)'
      }
    },
    async checkOut(){
      try {
        this.saving=true;
        let postStartDate;
        let postReturnDate;
        let payDay;
        let paySisa;
        let time;
        if(this.planTime=="Monthly"){
          time="month"
        }else if(this.planTime=="Daily"){
          time="day"
        }else if(this.planTime=="Hourly"){
          time="hour"
        }else if(this.planTime == "Mileage"){
          time="day"
          payDay = Math.floor(this.rentDuration/500);
          paySisa =this.rentDuration%500
          if(paySisa !=0){
            payDay=payDay+1;
          }else{
            payDay=payDay;
          }
        }
          postStartDate=dayjs(this.endDate).subtract((this.planTime != "Mileage")? this.rentDuration : payDay,time)
          postStartDate=dayjs(postStartDate).format('YYYY-MM-DD HH:mm:ss')
          postReturnDate=dayjs(this.endDate).format('YYYY-MM-DD HH:mm:ss')
        let obj={
          user_id:this.auth.user_id,
          payment_method:this.planTime,
          start:postStartDate,
          finish:postReturnDate,
          duration: this.rentDuration,
          payment_plan:this.selectedPayment,
          etalase:this.etalasePost,
          name:this.billTo,
          email:this.email,
          phone:this.phone,
          address:this.address,
          order_from:"cart"
        }
        let response = await postOrderVehicle(obj);
        // console.log(response)
        if(response.data.code == 200){
          this.saving=false;
          this.$store.dispatch('getUserCart',this.auth.user_id)
          if(this.selectedPayment=="pre-payment"){
              this.$router.push('/invoice/'+response.data.result.order_id) 
          }else{
                if(this.auth.role =='superadmin' || this.auth.role =='admin' ){
                  this.$toast.open('Success to make this order');
                  setTimeout(() => {
                    this.$router.push('/etalase')
                  }, 2000);
                  // this.$emit('success');
                }else{
                  this.$toast.open('Success to make this order');
                  setTimeout(() => {
                    this.$router.push('/rentList')
                  }, 2000);
                  // this.$emit('success');
                }
              }
        }else{
          this.$toast.error('Failed to make this order');
          //toast gagal
          }
        // 
      } catch (error) {
        
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('getUserCart',this.auth.user_id)
    // console.log(this.auth.user_id)
    await this.getListCart();
  },
}
</script>
<style >
  .actived{
    background-color: #D9E7FF !important;
  }
  .btn-modifikasi .matador-text-03:hover{
    color:white;
  }
  .btn-modifikasi{
    width: 120px;
    height: 32px;
    left: 420px;
    top: 29px;
    border: 1px solid #D3D4D8 !important;
    box-sizing: border-box;
    border-radius: 10px !important;
    padding: 1px 1px !important;
  }
  .btn-modifikasi:hover{
    color: #005BFF !important;
  }
  .scrolling{
    height: 500px;
    overflow-y:hidden;
  }
  .char{
    display: none !important;
  }
  .customDate{
    margin-bottom: 150px;
  }
  .vdp-datepicker__calendar{
    height: 350px;
    z-index: 2000;
  }
</style>