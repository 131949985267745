<template lang="pug">
transition(name="modal")
  div
    .modal-mask(v-if="visible")
      .modal-wrapper
        .modal-dialog.modal-dialog-scrollable(role="document")
          .modal-content

            .modal-header
              .h5.font-weight-bold.card-title.px-3 {{ title }} Driver
              button.close(
                type="button",
                data-dismiss="modal",
                aria-label="Close",
                @click="onClose()"
              )
                span.pr-3(aria-hidden="true" @click="$emit('close')") &times;

            .modal-body
              loader(:loading="detailLoading")
                form.px-3.mt-3
                  .form-group

                    label Driver Name
                    multiselect(
                      v-model="selectedUser",
                      track-by="user_id",
                      label="name"
                      placeholder="Choose Driver Name",
                      :options="users",
                      :searchable="true",
                      :allow-empty="false",
                      :closeOnSelect="true"
                      ref="selectUser"
                    )
                      .d-flex.align-items-start(
                        slot="afterList"
                      )
                        a.p-2(
                          href="javascript:;",
                          role="button",
                          @click="onCreateNewUserClick()") 
                          .m-0.mr-1.primary-color
                            plus-circle.mr-1
                            | Create New User

                  .form-group.d-flex.flex-column.driver-new-user(v-if="user_id === 'new_user'")
                    form.px-3.mt-3

                      .form-group
                        label(for='username') Username
                        input#username.form-control(type='text' placeholder='Insert username', v-model="username")

                      .form-group
                        label(for='fullname') Full Name
                        input#fullname.form-control(type='text' placeholder='Insert your fullname', v-model="name")

                      .form-group
                        label(for='email') Email
                        input#email.form-control(type='text' placeholder='example@gmail.com', v-model="email")

                      .form-group(style="position: relative" )
                        label(for='inputPassword') Password
                        input#inputPassword.form-control(:type='passwordType' v-model="password" placeholder='Password' required='')
                        a.h3(@click="togglePassword" href="javascript:;" style="position: absolute;bottom: 1px;margin: 0;right: 10px;")
                          .text-muted(v-if="passwordType === 'password'")
                            eye-off-outline
                          .text-primary(v-if="passwordType === 'text'")
                            eye-outline
                  
                  .form-group
                    label(for='username') Phone
                    input#username.form-control(
                      type='text' 
                      placeholder='Insert Phone Number',
                      @change="changePhone"
                      @input="changePhone"
                      :value="inpNum"
                    )

                  .form-group
                    label Description
                    textarea.form-control(
                      type='text' 
                      placeholder='Insert description'
                      v-model="description"
                      rows="6"
                    )

                  .form-group
                    label Device Name
                    multiselect(
                      v-model="selectedDevices",
                      track-by="id",
                      label="name",
                      :placeholder="listDeviceLoading ? 'Loading...': 'Device name' ",
                      :disabled="listDeviceLoading"
                      :multiple="true",
                      :options="devices",
                      :searchable="true",
                      :allow-empty="false",
                      :closeOnSelect="false"
                    )
                  
                  .form-group(v-if="!user_id")
                    label Country
                    .row
                      .col
                        select.custom-select.form-control(
                          type='text' 
                          placeholder='Choose country' 
                          v-model="country"
                          :class="{ 'matador-placeholder':country === ''}" 
                          style="color:black !important" )
                            option(value="" selected disabled) Choose Country
                            option(v-for="item in countryList", :value="item.code") {{item.country_name}}    

                  .form-group
                    label 
                      .d-flex 
                        span Geofence
                        p.ml-3
                          toggle-button(
                            :sync="true"
                            :margin="4"
                            :width="40"
                            :height="22" 
                            :value="geofence" 
                            :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
                            :labels="{'checked': 'On', 'unchecked': 'Off'}"
                            @change="toggleGeofence"
                          )
                          
                    .d-flex
                      div.list-geo-device-wraper
                        span(v-for="dev in geofenceDevicesList" class="badge-geofence") {{ dev.name }}

                      img.ml-2(
                        style="width: 3rem; height: auto;" 
                        :src="require('@/assets/img/globe_icon.svg')"
                        @click="onOpenGeofence")
                    
                div.float-right.mt-3
                  button.btn.btn-link.mr-3(type="button" @click="onClose()") Cancel
                  matador-button(
                    :disabled="!isValid"
                    @click="doSave"
                  ) {{ loading ? 'Loading...' : buttonTitle }}

    confirmation-modal(
      v-if="showCancelModal"
      @close="showCancelModal = false" 
      @confirm="doClose()" 
      title="Discard Changes"
      confirm-text="Yes, Discard"
    )
      p.m-0
        | Are you sure want to discard changes you made?

    geofence-modal(
      v-if="isGeofenceOpen"
      :devices="selectedDevices"
      @result="collectResult"
      @close="isGeofenceOpen = false"
    )

</template>

<script>

import Plus from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import PlusCircle from "vue-material-design-icons/PlusCircleOutline.vue";

import Computed from './computed'
import Watch from './watch'
import Method from './methods'

import ConfirmationModal from "@/components/Matador/ConfirmationModal"
import GeofenceModal from '../Geofence/Geofence.vue'
import { COUNTRIES } from '../../../entity'

export default {
  props: [ 'close', 'currentDriver', 'onSave', 'onDelete', 'visible', 'table', 'isEdit' ], 

  components: {
    Plus,
    EyeOutline,
    EyeOffOutline,
    PlusCircle,
    GeofenceModal,
    ConfirmationModal
  },

  data(){
    return {
      selectedDevices: [],
      devices: [],
      users: [],
      isGeofenceOpen: false,
      geofenceDevices: [],
      geofence: false,

      listDeviceLoading: false,
      detailLoading: false,

      password: '',
      country: '',
      countryList: [],
      passwordType: 'password',
      
      driver_id: '',
      user_id: '',
      description: '',
      phone: '',
      username: '',
      name: '',
      email: '',
      selectedUser: '',
      showDeleteModal: false,
      showCancelModal: false,
      loading: false
    }
  },

  watch: Watch,
  computed: Computed,
  methods: Method,

  mounted() {

    this.fetchUserList();
    this.fetchDeviceUnitList();

    Object.keys(COUNTRIES).forEach(code => {
      this.countryList.push({
        code,
        country_name: COUNTRIES[code]
      })
    })

  }

}
</script>
<style lang="scss" scoped>
  .driver-new-user {
    border: 1px dashed #7998B9;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .badge-geofence {
    background: #f0f0f0 !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
    color: black !important;
    margin: 5px 0px 5px 5px!important;
    padding: 1px 5px;
    display: inline-block;
    border-radius: 5px;
  }

  .list-geo-device-wraper {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
</style>
