
export const TabOption = {
  DRI: { key: "driver", title: 'Drivers', path: '/drivers/list' },
  REP: { key: "history", title: 'Histories', path: '/drivers/histories' },
  SOS: { key: "emergency", title: 'SOS', path: '/drivers/emergencies' }
}

export const SOS_STATES = {
  "0": "Request",
  "1": "On the way",
  "2": "Solved",
  "3": "Cancel",
  "4": "Denied"
}

export const COUNTRIES = {
  ID: 'Indonesia',
  MM: 'Myanmar'
}

export const DriverListEntity = (data) => {
  const deviceEntities = (data) => {
    data = data || []
      
    return data.map(dev => ({
      device_id: dev.device_id,
      device_unit_id: dev.device_unit_id,
      device_name: dev.device_name
    }))
  }

  return {
    user_id: data.user_id,
    driver_id: data.driver_id,
    device: deviceEntities(data.devices),
    country: data.country,
    description: data.description,
    phone: data.phone,
    organization_id: data.organization_id
  }
}


export const HistoryListEntity = (data) => {
  data = data || {}

  return {
    history_id: data.driver_history_id,
    imei: data.imei,
    driver_id: data.driver_id,
    driver: data.driver_name,
    device_id: data.device_id,
    device: data.device_name,
    timestamp: data.connected_at,
    phone: data.phone,
    unit: data.unit_name,
    vin: data.vin
  }

}

export const EmergencyListEntity = (data) => {
  data = data || {}
  
  return {
    id: data.sos_id,
    imei: data.imei,
    latitude: data.latitude,
    longitude: data.longitude,
    location: data.location_name,
    driver_id: data.driver_id,
    driver: data.driver_name,
    device_id: data.device_id,
    device: data.device_name,
    timestamp: data.updated_at,
    status: data.status,
  }
}

export default {
  DriverListEntity,
  HistoryListEntity,
  EmergencyListEntity
}