<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-xl(role="document")
        .modal-content
          .modal-body
            loader(:loading="loading", :size="42")
              .d-flex.mb-4.pt-2.justify-content-between.align-items-start(
                v-if="vehicle"
              )
                div 
                  p <strong class="mr-2"> VIN : {{ vehicle.vin }} </strong>
                  p.m-0 <strong class="mr-2"> Device : {{ vehicle.imei }} </strong>

                a.p-1(
                  href="javascript:;",
                  role="button",
                  @click="$emit('followVehicle')"
                )
                img(
                  :height="20",
                  @click="onClose",
                  src="@/assets/img/close.png"
                )

              #mapMonitoring(style="height: 65vh")
                loader.map-loader.h-100.w-100.d-flex.align-items-center.justify-content-center(
                  :loading="loadingMap"
                )
                  google-map#map(
                    ref="trackMap",
                    :zoom="12",
                    :options="mapOptions",
                    :style="displayMap ? 'display: block' : 'display: none'"
                  )

                  .h-100.w-100.d-flex.align-items-center.justify-content-center(
                    v-if="!displayMap"
                  )
                    p.text-center
                      img(
                        width="224",
                        style="display: block; margin: -60px auto 30px auto",
                        src="@/assets/img/error.png"
                      )
                      strong(
                        style="font-size: 1rem; font-weight: bolder; color: #999"
                      ) No tracking data is found. <br>
                      span(style="line-height: 2.25rem") Might be the device doesn’t yet streaming data to our databases. <br>
                      a.text-danger(
                        style="text-decoration: underline",
                        href="javascript:;",
                        @click="$emit('showDetail')"
                      ) Show vehicle detail
</template>

<script>
import { google as GoogleConf } from "@/config";
import Api from "@/utils/api";

export default {
  props: ["vehicle", "loading"],

  data() {
    return {
      position: { lat: 0, lng: 0 },
      loadingMap: false,
      displayMap: true,
      mapOptions: {
        zoom: 15,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        center: { lat: GoogleConf.map.lat, lng: GoogleConf.map.lng },
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
      },
      markerInstance: null,
    };
  },

  methods: {
    onClose() {
      this.$emit("clearTracking");
      this.$emit("close");
    },

    initMaker(map) {
      let icon = {
        url: "",
        size: new window.google.maps.Size(200, 200),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(0, 32),
        scaledSize: new google.maps.Size(30, 30),
      };

      switch (this.vehicle.status) {
        case "on":
          icon.url = require("@/assets/img/truck-on.png");
          break;
        case "idle":
          icon.url = require("@/assets/img/truck-idle.png");
          break;
        default:
          icon.url = require("@/assets/img/truck-off.png");
      }

      return new window.google.maps.Marker({ map, icon });
    },

    reloadMarker() {
      let payload = {
        vehicle_id: this.vehicle.vehicle_id,
        imei: this.vehicle.imei,
      };

      return Api.vehicle.get_latest_condition(payload).then((response) => {
        if (response.status !== 200) return {};
        let data = response.data.result.data;
        return {
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        };
      });
    },

    onClickMarker() {
      window.google.maps.event.addListener(this.markerInstance, "click", (e) =>
        this.$emit("showDetail")
      );
    },

    responseHandler(position) {
      this.displayMap = true;
      this.loadingMap = false;

      const self = this;
      initCallback(1000, 5, (done) => {
        self.$refs.trackMap.$_getMap().then((map) => {
          self.markerInstance = self.initMaker(map);
          self.onClickMarker();

          self.$emit("clearTracking");
          if (!window.animateTracker) {
            window.animateTracker = setInterval(() => {
              self.reloadMarker().then((response) => {
                let position = new google.maps.LatLng(
                  response.lat,
                  response.lng
                );
                self.markerInstance.setPosition(position);
              });
            }, 3100);
          }

          map.setCenter(position);
          done();
        });
      });
    },
  },

  watch: {
    vehicle: {
      immediate: true,
      handler(vehicle) {
        this.position = {
          lat: vehicle.latitude,
          lng: vehicle.longitude,
        };

        this.loadingMap = true;
        this.reloadMarker()
          .then(this.responseHandler)
          .catch((e) => {})
          .finally(() => {
            this.loadingMap = false;
          });
      },
    },
  },
};
</script>

<style>
#mapMonitoring .map-loader > div {
  width: 100%;
  height: 100%;
}
</style>
