'use strict'

import day from 'dayjs'

export function TableOption(self) {

    const options = {
      ajax: null,
      search: false,
      searchSelector: null,
      showSearchButton: false,
      sortable: true,
      pagination: true,
      paginationHAlign: "center",
      paginationDetailHAlign: "right",
      // sidePagination: "server",
      paginationParts: ["pageInfo", "pageList", "pageNumbers"],
      classes: ["table", "table-hover"],
      loadingFontSize: 16,
    }
  

    let columns = [
      {
        title: "Date",
        field: "timestamp",
        searchable: true,
        sortable: true,
        formatter: (value, row) => {

          let date = day(value).format('DD MMM YYYY')
          let time = day(value).format('hh:mm A')

          return `
            <div class="d-flex justify-content-between align-items-center" style="line-height: 1rem;">
              <span style="font-size: 0.8rem;" >${date}</span>
              <span class="badge badge-light mr-4" style="font-size: 0.6rem;">${time}</span>
            </div>
          `
        },
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Driver",
        field: "driver",
        sortable: true,
        searchable: false,
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Unit",
        field: "unit",
        sortable: true,
        searchable: false,
        formatter: (value, row) => {
          return `
            <div class="d-flex justify-content-between flex-column">
              <span style="font-size: 0.9rem;">${row.unit}</span>
            </div>
          `
        },
        widthUnit: '%',
        width: '22'
      },
      {
        title: "Latest Condition",
        field: "device",
        sortable: true,
        searchable: false,
        class: 'text-center',
        formatter: (value, row) => {
          const template = {
            template: `
              <div>
                <div class="d-flex justify-content-center align-items-center">
                  <div>
                    <span id="button-1" class="badge badge-light p-2" style="cursor:pointer;" @click="onDetail">View</span>
                  </div>
                </div>
              </div>
            `,
            data: { row, value },

            methods: {
              onDetail() { 
                self.onDetail(this.row)
              }
            },
          }
          
          return self.vueFormatter(template)
        },
        widthUnit: '%',
        width: '22'
      }
    ]
  
  
    return { columns, options }
  }