<template lang="pug">
#geofence-form(:class="{'visible': visible}")
  div
    h4.h5.font-weight-bold.text-dark.mt-2.mb-4 Geofence Properties
    
    b-form-group(label="Generator Name")
      b-form-input(v-model="name" placeholder="New Generator Name")

    b-form-group(label="Device Name")
      multiselect(
        v-model="selectedDevice",
        track-by="id",
        label="name",
        placeholder="Device name",
        :multiple="false",
        :options="devices",
        :searchable="true",
        :allow-empty="false",
        :closeOnSelect="true"
      )

    b-form-group(label="Description")
      b-form-textarea(v-model="description" :rows="4" placeholder="About the generator")

    b-form-group
      .row
        .col-5
          label Color
          .row
            .col-4(v-for="item in colors")
              a.color-selector(href="javascript:;" @click="changeColor(item)")
                span.color(:style="'background-color: '+item")
                span.tick(v-if="color === item") ✔

        .col-auto.ml-auto
          label.d-block Auto-off (Engine)
          toggle-button(
            :sync="true"
            :margin="4"
            :width="40"
            :height="22" 
            :value="auto_off" 
            :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
            :labels="{'checked': 'On', 'unchecked': 'Off'}"
            @change="onSwitchAutoEngine"
          )

    .d-flex.justify-content-end
      button.btn.btn-light.py-1.px-4.border-0(@click="$emit('cancel')")
        span.d-block.px-3.text-danger Cancel

      button.btn.btn-danger.py-1.px-4(:disabled="!isCanSave" @click="onSave") 
        span.d-block.px-3 {{ loading ? 'Loading..' : 'Save' }}

</template>

<script>
import { getGeneratorDevices, postUpdateGeo, postCreateGeo } from '@/utils/api';
import Api from '@/utils/api'
import { GeofenceColors } from '../../entity'

export default {
  data(){
    return {
      geofence_id: null,
      selectedDevice: null,
      devices: [],
      filterLoading: false,
      name: null,
      description: null,
      auto_off: false,
      location: '',
      loading: false
    }
  },
  props: ['visible', 'colors', 'color', 'paths', 'geofence'],
  computed: {
    coordinates() {
      return this.paths.map(({ lat, lng }) => ([lat, lng]));
    },
    isCanSave() {
      return this.name 
        && this.description 
        && this.selectedDevice 
        && this.selectedDevice.id  
        && this.coordinates.length >= 3;
    }
  },
  watch: {
    geofence: {
      immediate: true,
      handler: function(value) {
        
        if (!value) return null

        this.name = value.name;
        this.description = value.description;
        this.auto_off = typeof value.action === 'boolean' ? value.action : (value.action === "on")

        this.location = value.location

        if (!this.devices.length) this.loadDevices()

      }
    }
  },
  methods: {
    onSelectDevice(device) {
      this.selectedDevice = device
    },
    onSwitchAutoEngine({ value: val }){
      this.auto_off = val;
    },
    changeColor(color){
      this.$emit('switchColor', color);
    },
    loadDevices() {
      if (this.filterLoading) return false

      this.filterLoading = true

      getGeneratorDevices({limit:100000, unused: true})
      .then(result => {
        let data = result.data.result.data
        
        this.devices = data.map(item => ({
          id: item.device_id,
          name: item.device_name,
          imei: item.imei
        }))
        
        if (this.geofence) {
          let ids = []
          
          this.geofence.devices.forEach(item => {
            ids.push(item.device_id)
            this.devices.push({
              id: item.device_id,
              name: item.device_name
            })
          })
          
          this.devices.forEach(unit => {
            if (ids.includes(unit.id)) this.selectedDevice = unit
          })
        }
      })
      .catch(e => {console.log(e)})
      .finally(() => {
        this.filterLoading = false
      })
    },
    onSave() {
      if (this.loading) return false
      this.loading = true
      let coordinates = this.coordinates.map(([lat, lng]) => `${lat}, ${lng}`)

      let params = {
        name: this.name,
        coordinates,
        color: this.color || GeofenceColors[1],
        action: this.auto_off ? 'off' : null, 
        description: this.description,
        devices: [this.selectedDevice.id],
        location: this.location
      }

      if(!this.geofence){
        params.organization_id = this.$store.state.auth.organization_id;
        params.created_by = this.$store.state.auth.user_id;
      } else {
        params.genset_id = this.geofence.genset_id;
      }

      const callback = params.genset_id ? Api.generator.update : Api.generator.create
      return callback(params)
        .then(result => {
          this.$emit('cancel')
          let message = params.genset_id ? `Successfully update geofence` : `Successfull add geofence`
          this.$toast.success(message)
        })
        .catch(e => {
          let message = params.genset_id ? `Failed to update geofence` : `Failed to add geofence`
          this.$toast.error(message)
        })
        .finally(()=> {
          this.loading = false
        })
    }
  },

  mounted() {
    this.loadDevices()
  }
  
}
</script>

<style lang="scss">
$form-width: 360px;
#geofence-form {
  position: absolute;
  overflow-y: auto;
  width: $form-width;
  height: 100%;
  background-color: #ffffff;
  top: 0;
  left: -$form-width - 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.15);
  padding: 20px 30px;
  transition: 500ms ease-in-out;
  &.visible {
    left: 0;
  }
  .color-selector {
    position: relative;
    span.color {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    span.tick {
      position: absolute;
      top: -8px;
      left: 4px;
      font-size: 2rem;
      color: #ffffff;
    }
  }
  .btn.border-0 {
    border-color: transparent !important;
  }
}
</style>