import * as yup from 'yup';

const driverSchema = (isNewUser) => yup.object().shape({
  user_id: yup.string().required(),
  device_unit: yup.string().required(),
  description: yup.string().required(),
  phone: yup.string().required(),
  username: isNewUser ? yup.string().required() : undefined,
  name: isNewUser ? yup.string().required() : undefined,
  email: isNewUser ? yup.string().email().required() : undefined,
});


export default {

  isDirty() {
    if(this.currentDriver && this.currentDriver.device_id) {
      return this.currentDriver.user_id != this.user_id 
        || this.currentDriver.phone != this.phone 
        || this.currentDriver.device_unit != this.device_unit 
        || this.currentDriver.description != this.description 
    }
    return this.user_id || this.phone || this.device_unit || this.description;
  },

  inpNum() {
    return this.phone
  },
  
  isValid() {
    // return driverSchema(this.user_id === 'new_user' ? true : false).isValidSync(this.inputData);
    return true
  },

  inputData() {
    let obj = {
      created_by: this.created_by,
      driver_id: this.driver_id,
      user_id: this.user_id,
      phone: this.phone,
      username: this.username,
      name: this.name,
      email: this.email,
      device_unit: this.device_unit,
      description: this.description,
      password: this.password,
      country: this.country
    };
    return obj;
  },

  title() {
    return !!this.driver_id ? 'Edit' : 'Add'
  },

  buttonTitle() {
    return !!this.driver_id ? 'Update' : 'Add'
  },
  
  geofenceDevicesList () {
    let items = []
    let devices = this.geofenceDevices && this.geofenceDevices.length ? this.geofenceDevices : this.selectedDevices

    if (devices)  {
      devices.forEach(item => {
        if (item.coordinate) {
          items.push(item)
        }
      })
    }

    return items
  }
}