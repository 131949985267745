<template lang="pug">
transition(name="modal")
  .modal-mask
    .modal-wrapper
      .modal-dialog.modal-dialog-scrollable.modal-lg(role="document")
        .modal-content
          .modal-body
            loader(:loading="loading")
              .row.float-right
                .col-12
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="18" :width="18" src="@/assets/img/close.png")

              .row.mt-3
                .col-6
                  img.matador-border-radius.w-100(v-if="vehicle.image" :src="vehicle.image")
                  img.matador-border-radius.w-100(v-if="!vehicle.image" src="@/assets/img/car-placeholder.png")
                .col-6

                  .row(v-for="[label, value] in vehicle_data")
                    .col-6.col-xl-5
                      label.font-weight-bolder {{ label }}

                    .col-6.col-xl-7
                      .d-flex
                        span.mr-2 :
                        span(v-html="value")

                  .row.mt-3
                    .col-12
                      .d-flex
                        matador-button.flex-grow-1(
                          @click="goToDetail") Go to Detail


                .col-12.mt-4
                  .matador-tab.d-flex

                    a.font-weight-bolder.w-100.text-center.py-3(
                      href="javascript:;",
                      :class="{ active: modalTabIndex == 0 }",
                      @click="modalTabIndex = 0"
                    ) Tracking Info

                    a.font-weight-bolder.w-100.text-center.py-3(
                      href="javascript:;",
                      :class="{ active: modalTabIndex == 1 }",
                      @click="modalTabIndex = 1"
                    ) Engine Info

                    a.font-weight-bolder.w-100.text-center.py-3(
                      href="javascript:;",
                      :class="{ active: modalTabIndex == 2 }",
                      @click="modalTabIndex = 2"
                    ) Device Info

                  .p-0.row
                    .border-bottom.py-2.col-6(v-for="column in tabs")
                      .border-bottom(v-for="item in Object.values(column)")
                        .d-flex.py-3.justify-content-between
                          p.font-weight-bolder.m-0 {{ item[0] }}
                          p.m-0.text-right {{ item[1] }}
                          //- strong.ml-auto No Data
</template>

<script>
import entity from "../entity";

export default {
  props: ["dataVehicle", "close", "loading"],

  data() {
    return {
      vehicle: entity.VehicleDetailModalEntity(),
      modalTabIndex: 0,
      cols: 2,
    };
  },

  methods: {
    goToDetail() {
      // hotfix to clear interval update position
      if (window.animateTracker) {
        clearInterval(window.animateTracker);
        window.animateTracker = null;
      }

      this.$router.push({
        path: `dashboard/detail/${this.vehicle.vehicle_id}`,
      });
    },
  },

  watch: {
    dataVehicle: {
      handler(value) {
        this.vehicle = entity.VehicleDetailModalEntity(value);
      },
    },
  },

  computed: {
    vehicle_data() {
      return [
        ["VIN", this.vehicle.vin],
        ["Device name", this.vehicle.device_name],
        ["Vehicle Type", this.vehicle.vehicle_type],
        ["Current Location", this.vehicle.location],
      ];
    },

    tabs() {
      let current_info = this.vehicle.tabs[this.modalTabIndex];

      let columns = [];
      let mid = Math.ceil(current_info.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(current_info.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
  },
};
</script>
