<template lang="pug">
transition(name="modal")
  .modal-mask(@click="onModalClick")
    #geo-form.modal-wrapper
      .modal-dialog(style="width: 1180px;max-width: 95%;" role="document")
        .modal-content
          .modal-body.p-0(style="position: relative;")
            div.list-devices(style="position: absolute; top: 10px; left: 10px; background: #fff; z-index: 1;")
              b-button-group
                b-button(
                  v-for="(dev, idx) in deviceList" v-if="dev.coordinate && !!dev.coordinate.length"
                  :key="dev.device_id"
                  :class="selectedDeviceBtn(dev)"
                  @click="onSelectDeviceGeofence($event, dev)"
                ) {{dev.name}}

            google-map#map(
              ref="geofenceMap"
              :zoom="12"
              :options="mapOptions"
            )
            
              google-map-polygon(
                v-if="selectedGeofence"
                :paths="selectedGeofence.path",
                :strokeColor="selectedGeofence.strokeColor"
                :fillColor="selectedGeofence.fillColor"
                :fillOpacity="0.7"
              )

            .buttons(v-if="!!selectedGeofence.path.length && !drawMode")
              button.btn.btn-light.btn-add(@click="drawMode = true")
                img(:height="16" :width="16" src="@/assets/img/pen.png")
                span Edit Geofence

            .buttons(v-if="!selectedGeofence.path.length && !drawMode")
              button.btn.btn-light.btn-add(@click="drawMode = true")
                img(:height="16" :width="16" src="@/assets/img/pen.png")
                span Add Geofence
              
            .buttons(v-if="drawMode && selectedGeofence.path.length >= 3 && !showForm")
              button.btn.btn-danger.btn-cancel(@click="selectedGeofence.path = []")
                span Clear Pointer

              button.btn.btn-success.btn-cancel(@click="showForm = true")
                h4.my-0 ✓
                span {{ selectedGeofence.path.length ? 'Confirm Geofence' : 'Add Geofence' }}

            geofence-form(
              :visible="showForm" 
              :colors="colors" 
              :color="selectedGeofence.fillColor" 
              :paths="selectedGeofence.path"
              :devices="devices"
              :loading="savingLoading"
              @result="collectResult"
              @switchColor="onSwitchColor"
              @cancel="close"
              style="z-index: 1;"
            )

            button.close-map(@click="$emit('close')")
              img(:height="20" :width="20" src="@/assets/img/close.png")

</template>

<script>
import GeofenceForm from './Form.vue';
import { GeofenceColors } from '@/utils/colors'
import { InitPolygon } from '@/utils/map'

const lines = [{
  path: [],
  geodesic: true,
  strokeColor: GeofenceColors[1],
  fillColor: GeofenceColors[1],
}]

export default {
  
  data() {
    return {
      selectedGeofence: {
        path: []
      },
      deviceList: [],
      lines,
      colors: GeofenceColors,
      drawMode: false,
      showForm: false,
      markersList: []
    }
  },

  components: {
    GeofenceForm
  },

  watch: {
    devices: {
      immediate: true,
      handler: function(devices) {
        if (Array.isArray(devices)) {
          this.deviceList = {}
          devices.forEach(dev => {
            this.deviceList[dev.id] = dev
          })
        }

        this.initGeofence()
      }
    },

  },

  computed: { 
    mapOptions() {
      return {
        draggable: true,
        fullscreenControl: false,
        mapTypeControl: false,
        draggableCursor: this.drawMode && !this.showForm ? "url(/icons/plus-thin.png) 12 12, auto" : null,
      }
    }
  },

  
  props: ['title', 'confirmText', 'geofence', 'devices', 'savingLoading'],
  methods: {

    initGeofence() {
      this.lines = {}
      Object.values(this.deviceList).forEach(item => {
        if (item.coordinate && !!item.coordinate.length) {
          this.lines[item.id] = {
            path: item.coordinate.map(([lat, lng]) => ({ lat, lng })),
            fillColor: item.color,
            strokeColor: item.color
          }
        }
      })

      let device = Object.values(this.deviceList)[0]
      if (device && device.coordinate) {
        this.selectedDevice = device
        this.selectedGeofence = this.lines[device.id]

        const self = this
        setTimeout(() => {
          initCallback(1000, 10, (done) => {
            InitPolygon(this.selectedGeofence, self.$refs.geofenceMap)
            if (self.$refs.geofenceMap) done()
          })
        }, 2000)
      }

    },

    collectResult(result) {
      this.$emit('result', result)
    },

    selectedDeviceBtn(dev) {
      return this.selectedDevice && dev.id === this.selectedDevice.id ? 'selected' : ''
    },

    onSelectDeviceGeofence(e, dev) {
      this.selectedDevice = dev
      this.selectedGeofence = this.lines[dev.id]

      InitPolygon(this.selectedGeofence, this.$refs.geofenceMap, 14)
    },

    initMap() {

      const self = this
      initCallback(1000, 10, (done) => {
        self.$refs.geofenceMap.$_getMap().then((map) => {
          window.google.maps.event.addListener(map, 'click', ({ latLng: { lat, lng } }) => {
            this.selectedGeofence = this.selectedGeofence || {}
            self.drawMode && !self.showForm && this.selectedGeofence && this.selectedGeofence.path.push({lat: lat(), lng: lng()});
          })
        });
        if (self.$refs.geofenceMap) done()
      })
      
    },

    onSwitchColor(color){
      this.selectedGeofence.strokeColor = color;
      this.selectedGeofence.fillColor = color;
    },
    
    onAddDevice(){
      this.showForm = true;
    },

    onModalClick(event){
      if(event.target.id === 'geo-form'){
        this.$emit('close');
      }
    },

    close() {
      this.showForm = false
      this.$emit('close')
    }

  },
  mounted(){
    this.initMap();

    if (this.selectedGeofence) {
      this.selectedGeofence = {
        path: [],
        fillColor: GeofenceColors[0],
        strokeColor: GeofenceColors[0]
      }
    }

    this.initGeofence()
      
  }
}
</script>

<style lang="scss">
  @import './geofence.scss';
</style>